import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy, Output, SecurityContext,
  SimpleChanges
} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {filter} from 'rxjs/operators';
import '../../../../utils/observable.extensions';
import '../../../../utils/subscription.extensions';
import {DomSanitizer} from '@angular/platform-browser';
import {Program} from '../../../../models/program/program';
import {Show} from '../../../../models/program/show';
import {Advertisement} from '../../../../models/resources/advertisement';
import {IvsVideoPlayerViewModel} from './ivs-video-player-view-model';
import { ProgramStatusType } from 'src/app/models/lookup/program-status-type';
import * as moment from 'moment';
import { DateUtils } from 'src/app/utils/date-utils';
import { Router } from '@angular/router';
import { Autoplay } from 'swiper';
declare let videojs: any; // Declare globally loaded Video.js

@Component({
  selector: 'app-ivs-video-player',
  templateUrl: './ivs-video-player.component.html',
  styleUrls: ['./ivs-video-player.component.scss'],
  providers: [IvsVideoPlayerViewModel]
})
export class IvsVideoPlayerComponent extends BaseComponent implements OnDestroy, OnChanges{
  @Input() program: Program|Show;
  @Input() advertisement: Advertisement;
  @Input() isLive$ = new BehaviorSubject<boolean>(null);
  @Input() playVideo: EventEmitter<boolean>;
  @Output() feedbackButtonClicked = new EventEmitter<void>();
  @Input() homeTeamScore$ = new BehaviorSubject<number>(0);
  @Input() visitingTeamScore$ = new BehaviorSubject<number>(0);
  @Input() isStartedScoring$ = new BehaviorSubject<boolean>(true);
  homeTeamName: string;
  visitingTeamName: string;
  private player: any;
  currentScreenWidth: number;
  private isPlayerReady = false;

  // Breakpoints for responsive design
  private readonly SMALL_SCREEN = 576;  // Mobile
  private readonly MEDIUM_SCREEN = 992; // Tablet

  constructor(
    private sanitizer: DomSanitizer,
    public viewModel: IvsVideoPlayerViewModel,
  ) {
    super();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.currentScreenWidth = window.innerWidth;
    // Only update responsiveness if player is ready
    if (this.isPlayerReady && this.player) {
      this.updatePlayerResponsiveness();
    }
  }

  updatePlayerResponsiveness() {
    if (!this.player || !this.player.el()) {return;}
  
    const playerContainer = this.player.el();
    const controlBar = this.player.controlBar?.el();
  
    if (!playerContainer || !controlBar) {return;}
  
    const playerWidth = playerContainer.offsetWidth;
    const playerHeight = playerContainer.offsetHeight;
  
    if (playerWidth < this.SMALL_SCREEN) {
      this.applySmallScreenStyles(controlBar, playerWidth);
    } else if (playerWidth < this.MEDIUM_SCREEN) {
      this.applyMediumScreenStyles(controlBar, playerWidth);
    } else {
      this.applyLargeScreenStyles(controlBar, playerWidth);
    }
  }
  

  applySmallScreenStyles(controlBar: any, playerWidth: any) {
    const scaleFactor = Math.max(0.7, Math.min(1, playerWidth / 400));

    // Get all buttons in control bar
    const buttons = controlBar.querySelectorAll('.vjs-button');

    // Adjust button sizes for small screens
    buttons.forEach(button => {
      // Apply scaled dimensions to each button
      button.style.width = `${30 * scaleFactor}px`;
      button.style.height = `${30 * scaleFactor}px`;
      button.style.padding = `${2 * scaleFactor}px`;
      button.style.margin = `0 ${1 * scaleFactor}px`;
    });

    // Make SVG icons smaller
    const svgs = controlBar.querySelectorAll('svg');
    svgs.forEach(svg => {
      svg.style.width = `${18 * scaleFactor}px`;
      svg.style.height = `${16 * scaleFactor}px`;
    });

    // For very small player widths, further optimize
    if (playerWidth < 320) {

      // Make time display smaller or hide if needed
      const timeDisplay = controlBar.querySelector('.vjs-time-control');
      if (timeDisplay) {
        timeDisplay.style.fontSize = '0.7em';
        timeDisplay.style.padding = '0 2px';
      }

      // Time divider can be hidden on very small screens
      const timeDivider = controlBar.querySelector('.vjs-time-divider');
      if (timeDivider) {
        timeDivider.style.display = 'none';
      }
    }

    // Ensure proper control bar layout
    controlBar.style.display = 'flex';
    controlBar.style.flexWrap = 'nowrap';
    controlBar.style.justifyContent = 'space-between';
    controlBar.style.padding = `${2 * scaleFactor}px`;

  }
  
  // Add a method to detect mobile devices
  isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  
  // Add medium and large screen methods for completeness
  applyMediumScreenStyles(controlBar: any, playerWidth: any) {
    // Medium screen specific styles
    const buttons = controlBar.querySelectorAll('.vjs-button, .feedback-button, .vjs-seek-forward, .vjs-seek-backward, .vjs-quality-button');
    buttons.forEach(button => {
      button.style.width = '35px';
      button.style.height = '35px';
      button.style.padding = '3px';
      button.style.margin = '0 2px';
    });
  }
  
  applyLargeScreenStyles(controlBar: any, playerWidth: any) {
    // Reset to default styles for large screens
    const buttons = controlBar.querySelectorAll('.vjs-button, .feedback-button, .vjs-seek-forward, .vjs-seek-backward, .vjs-quality-button');
    buttons.forEach(button => {
      button.style.width = '40px';
      button.style.height = '40px';
      button.style.padding = '4px';
      button.style.margin = '0 3px';
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.program && this.player) {

      try {
        this.player.src({
          src: this.program.playbackStreamUrl,
          type: 'application/x-mpegURL'
        });
        
        const adTagUrl =  this.addCustomParams(this.program); 
        if (this.player.ima) {
          // We can try resetting the ads loader
          if (typeof this.player.ima.adsLoader !== 'undefined' &&
              this.player.ima.adsLoader &&
              typeof this.player.ima.adsLoader.contentComplete === 'function') {
            this.player.ima.adsLoader.contentComplete();
          }

          // Update the ad tag URL
          if (this.player.ima.setContentWithAdTag) {
            this.player.ima.setContentWithAdTag(null, adTagUrl, false);
          }

          // Re-initialize the ad display container
          if (this.player.ima.initializeAdDisplayContainer) {
            this.player.ima.initializeAdDisplayContainer();
          }
        } else {
          // Initialize ima if it doesn't exist
          this.player.ima({
            adTagUrl,
            debug: true,
            locale: 'en',
            vpaidMode: 2
          });
          this.player.ima.initializeAdDisplayContainer();
        }

        // Request ads to trigger the new ad
        if (this.player.ima && this.player.ima.requestAds) {
          this.player.ima.requestAds();
        }

      } catch (e) {
        console.error('Error updating video player:', e);
      }
    }
  }

  setupViews() {
    this.initPlayer();
  }

  setupBindings() {
  }

  initPlayer(): void {
    videojs.log.level('error');

    this.player = videojs('content_video', {
      liveui: true,
      liveTracker: true,
      autoplay: true,
      responsive: true,
      fluid: true,
      playsinline: true,
      controls: true,
      preload: 'auto',
      html5: {
        nativeTextTracks: false,
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        hls: {
          overrideNative: true,
          enableLowInitialPlaylist: true,
          bandwidth: 5000000,
          smoothQualityChange: true,
          allowSeeksWithinUnsafeLiveWindow: true,
          handleManifestRedirects: true,
          blacklistDuration: 60,
          bandwidth_update_duration: 5,
          bufferWhilePaused: true,
          limitRenditionByPlayerDimensions: true
        }
      },
      // Disable error display
      errorDisplay: false,
      // Add logger configuration
      log: {
        level: 'error',
        debug: false
      }
    });

    // Remove default error display component
    if (this.player.errorDisplay) {
      this.player.errorDisplay.disable();
    }

    // Custom error handling without visual display
    this.player.on('error', (e) => {
      const error = this.player.error();
      if (error && error.code === 4) {
        this.recoverFromError();
      }
    });

    //this line will help us to debug the events triggered by videojs
    // const originalTrigger = this.player.trigger;

    // this.player.trigger = function(event) {
    //   console.log('Event triggered:', event);
    //   return originalTrigger.apply(this, arguments);
    // };

    this.player.on('adend',()=>{
      this.player.play();
      if(this.program instanceof Program) {
        const streamType = this.program.programStatusId === ProgramStatusType.InProgressId
          ? 'live' : 'past_program';
        this.viewModel.trackVideoEvent(this.program, 'play', streamType);
      } else {
        this.viewModel.trackVideoEvent(this.program, 'play', 'past_program');
      }
    })

    // Add iOS-specific settings
    this.player.tech().setAttribute('playsinline', '');
    this.player.tech().setAttribute('webkit-playsinline', '');
    this.player.tech().setAttribute('x5-playsinline', '');

    // Add quality level change handling
    const qualityLevels = this.player.qualityLevels();
    qualityLevels.on('change', () => {
      const selectedQuality = qualityLevels[qualityLevels.selectedIndex];
    });

    this.player.ready(() => {
      try {
        // Hide the error display element if it exists
        const errorDisplay = this.player.getChild('errorDisplay');
        if (errorDisplay) {
          errorDisplay.hide();
        }

        this.setProgramUrlAndGam(this.player, this.program);
        this.addFeedBackButton(this.player);
        this.addSeekButtons(this.player);
        this.addQualitySelector(this.player);
        this.enhanceCustomButtons();
        this.updateAdControlBar();
        this.setupEventListeners();
      } catch(e) {
        if (this.player) {
          this.player.dispose();
        }
        setTimeout(() => {
          this.initPlayer();
        }, 500);
      }
    });
  }

  updateAdControlBar() {
    if (!this.player || !this.player.ima) return;
    
    const adContainer = document.querySelector('.ima-ad-container');
    if (!adContainer) return;
    
    const adControlBar = adContainer.querySelector('.ima-controls-div');
    if(adControlBar){
      adControlBar.setAttribute('style', `
        width: 100% !important;
      `);
    }
  }

  // Add this method to your component
enhanceCustomButtons() {
  if (!this.player) return;
  
  // Get all custom buttons
  const customButtons = this.player.el().querySelectorAll('.feedback-button, .vjs-seek-forward, .vjs-seek-backward, .vjs-quality-button');
  
  // Apply touch-specific enhancements to each button
  customButtons.forEach(button => {
    // Add touch-specific classes
    button.classList.add('vjs-touch-enabled');
    
    // Increase touch target size for mobile
    button.style.minWidth = '44px';
    button.style.minHeight = '44px';
    
    // Ensure proper padding for touch
    button.style.padding = '10px';
    
    // Add active state styling for better mobile feedback
    button.addEventListener('touchstart', function() {
      this.classList.add('vjs-button-active');
    }, { passive: true });
    
    button.addEventListener('touchend', function() {
      this.classList.remove('vjs-button-active');
      // Small delay to allow visual feedback before action
      setTimeout(() => this.click(), 50);
    }, { passive: true });
  });
  
  // Fix for iOS touch handling
  document.addEventListener('touchmove', function(e) {
    if (e.target && (e.target as HTMLElement).closest('.vjs-control-bar')) {
      e.stopPropagation();
    }
  }, { passive: false });
}

  private recoverFromError(): void {
    const currentTime = this.player.currentTime();
    const isPlaying = !this.player.paused();

    // Reset the source with the same URL
    this.player.src({
      src: this.program.playbackStreamUrl,
      type: 'application/x-mpegURL'
    });

    // Restore playback position and state
    this.player.one('loadedmetadata', () => {
      this.player.currentTime(currentTime);
      if (isPlaying) {
        this.player.play();
      }
    });
  }

  private setupEventListeners(): void {
    if (!this.player) {return;}

    this.player.on('fullscreenchange', () => {
      const isFullscreen = this.player.isFullscreen();
      const overlay = document.querySelector('.score-bug-overlay') as HTMLElement;
      const videoContainer = this.player.el();
      if (overlay) {
        if (isFullscreen) {
          overlay.classList.add('fullscreen-overlay');
          if (videoContainer) {
            videoContainer.appendChild(overlay);
          }
        } else {
          overlay.classList.remove('fullscreen-overlay');
          const originalContainer = document.querySelector('.ivs-video-player-container');
          if (originalContainer) {
            originalContainer.appendChild(overlay);
          }
        }
      }
    });

    this.player.on('enterpictureinpicture', () => {
      const overlay = document.querySelector('.score-bug-overlay') as HTMLElement;
      const videoContainer = this.player.el();
      if (overlay && videoContainer) {
        overlay.classList.add('d-none');
        videoContainer.appendChild(overlay);
      }
    });

    this.player.on('adend', () => {
      this.player.play();
      if(this.program instanceof Program) {
        const streamType = this.program.programStatusId === ProgramStatusType.InProgressId
          ? 'live' : 'past_program';
        this.viewModel.trackVideoEvent(this.program, 'play', streamType);
      } else {
        this.viewModel.trackVideoEvent(this.program, 'play', 'past_program');
      }
    });

    this.player.on('leavepictureinpicture', () => {
      const overlay = document.querySelector('.score-bug-overlay') as HTMLElement;
      const originalContainer = document.querySelector('.ivs-video-player-container');
      if (overlay) {
        overlay.classList.remove('d-none');
        if (originalContainer) {
          originalContainer.appendChild(overlay);
        }
      }
    });
  }

  setupControlBarLayout(): void {
    const controlBar = this.player.controlBar.el();
    if (!controlBar) {return;}

    // Clear existing content
    while (controlBar.firstChild) {
      controlBar.removeChild(controlBar.firstChild);
    }

    // Create seek controls row
    const seekControlsRow = document.createElement('div');
    seekControlsRow.className = 'seek-controls-row';
    controlBar.appendChild(seekControlsRow);

    // Create main controls row
    const mainControlsRow = document.createElement('div');
    mainControlsRow.className = 'main-controls-row';

    // Create left, center, and right sections
    const leftControls = document.createElement('div');
    leftControls.className = 'left-controls';

    const centerControls = document.createElement('div');
    centerControls.className = 'center-controls';

    const rightControls = document.createElement('div');
    rightControls.className = 'right-controls';

    // Add sections to main controls row
    mainControlsRow.appendChild(leftControls);
    mainControlsRow.appendChild(centerControls);
    mainControlsRow.appendChild(rightControls);

    // Add main controls row to control bar
    controlBar.appendChild(mainControlsRow);
  }

  addQualitySelector(player: any) {
    const qualityLevels = player.qualityLevels();
    const button = player.controlBar.addChild('button', {});
    button.addClass('vjs-quality-button');
    button.el().innerHTML = '<span style="font-size: 20px; color: white;">&#9881;</span>';
  
    // Create menu
    const menuDiv = document.createElement('div');
    menuDiv.className = 'vjs-quality-menu vjs-menu';
    menuDiv.style.cssText = `position: absolute; bottom: 40px; left: 50%; transform: translateX(-50%);
      background: rgba(255, 255, 255, 0.7); border-radius: 2px; display: none;`;
    button.el().appendChild(menuDiv);
  
    // Create menu list
    const menuList = document.createElement('ul');
    menuList.className = 'vjs-menu-content';
    menuDiv.appendChild(menuList);
  
    // Add Auto option
    const autoItem = document.createElement('li');
    autoItem.className = 'vjs-menu-item';
    autoItem.innerHTML = '<span style="color: black;">Auto</span>';
    autoItem.onclick = () => {
      // Enable all quality levels (Auto mode)
      player.qualityLevels().autoSelect = true;
  
      // Update selected item
      const items = menuList.querySelectorAll('li');
      items.forEach(item => item.classList.remove('vjs-selected'));
      autoItem.classList.add('vjs-selected');
  
      // Hide menu
      menuDiv.style.display = 'none';
    };
    menuList.appendChild(autoItem);
  
    // Toggle menu display
    button.on('click', () => {
      menuDiv.style.display = menuDiv.style.display === 'none' ? 'block' : 'none';
    });
  
    // Add quality levels when available
    qualityLevels.on('addqualitylevel', (event) => {
      const level = event.qualityLevel;
      if (!level.height) return;
  
      // Check if resolution is already added
      const existingItems = menuList.querySelectorAll('li');
      for (const quality of Array.from(existingItems)) {
        if (quality.textContent === `${level.height}p`) return;
      }
  
      // Create menu item for this resolution
      const item = document.createElement('li');
      item.className = 'vjs-menu-item';
      item.innerHTML = `<span style="color: black;">${level.height}p</span>`;
      item.onclick = () => {
        // Disable all quality levels
        Array.from(qualityLevels).forEach((qualityLevel: any) => {
          qualityLevel.enabled = false;
        });
  
        // Enable only the selected quality level
        level.enabled = true;
  
        // Deselect Auto option
        autoItem.classList.remove('vjs-selected');
  
        // Update selected item
        const items = menuList.querySelectorAll('li');
        items.forEach(quality => quality.classList.remove('vjs-selected'));
        item.classList.add('vjs-selected');
  
        // Hide menu
        menuDiv.style.display = 'none';
      };
      menuList.appendChild(item);
  
      // **Set 720p as default when it appears**
      if (level.height === 720) {
        // Disable all other quality levels
        Array.from(qualityLevels).forEach((qualityLevel: any) => {
          qualityLevel.enabled = false;
        });
  
        // Enable 720p
        level.enabled = true;
  
        // Deselect Auto option
        autoItem.classList.remove('vjs-selected');
  
        // Highlight 720p in the menu
        item.classList.add('vjs-selected');
      }
    });
  
    // Hide menu when clicking outside
    document.addEventListener('click', (event) => {
      if (!button.el().contains(event.target as Node)) {
        menuDiv.style.display = 'none';
      }
    });
  }
  

  ngOnDestroy() {
    super.ngOnDestroy();
    this.viewModel.stopTracking();
  }

  addFeedBackButton(player: any) {
    const feedbackButton = player.controlBar.addChild('button');
    const feedbackButtonDom = feedbackButton.el();
    feedbackButtonDom.innerHTML = '';
    feedbackButton.addClass('feedback-button');
    (feedbackButtonDom as any).title = $localize`Feedback`;
    (feedbackButtonDom as any).onclick = () => {
      if (player.isFullscreen()) {
        player.exitFullscreen();
      }
      this.feedbackButtonClicked.emit();
    };
  }

  addSeekButtons(player: any) {
    const playToggle = player.controlBar.getChild('PlayToggle');
    const playToggleDom = playToggle.el();

    // 10 seconds Backward Button - Add Before Play/Pause
    const backButton = player.controlBar.addChild('button', {}, 0);
    const backButtonDom = backButton.el();
    backButtonDom.innerHTML = `
      <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 11C4 5.4772 8.47715 1 14 1C19.5228 1 24 5.4772 24 11C24 16.5229 19.5228 21 14 21C10.7284 21 7.82368 19.4289 5.99927 17" 
        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.809 14.408V8.333L10.009 10.385L10 8.783L10.621 8.108H12.979V14.408H11.809Z" fill="white"/>
        <path d="M14.1472 10.331C14.1472 8.918 15.0562 8 16.4152 8C17.7742 8 18.6832 8.918 
        18.6832 10.331V12.158C18.6832 13.571 17.7742 14.489 16.4152 14.489C15.0562 14.489 14.1472 13.571 14.1472 12.158V10.331ZM15.2992 
        12.158C15.2992 12.797 15.5512 13.364 16.4152 13.364C17.2792 13.364 17.5312 12.797 17.5312 12.158V10.331C17.5312
         9.692 17.2792 9.125 16.4152 
        9.125C15.5512 9.125 15.2992 9.692 15.2992 10.331V12.158Z" fill="white"/>
        <path d="M1.5 9L4 11L6.5 9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `;

    backButton.addClass('vjs-seek-backward');
    backButtonDom.onclick = () => {
      const currentTime = player.currentTime();
      player.currentTime(Math.max(0, currentTime - 10));
    };

    // Insert backward button before play/pause
    playToggleDom.parentNode?.insertBefore(backButtonDom, playToggleDom);

    // 10 seconds Forward Button - Add After Play/Pause
    const forwardButton = player.controlBar.addChild('button', {}, 0);
    const forwardButtonDom = forwardButton.el();
    forwardButtonDom.innerHTML = `<svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.12541 14.408V8.333L6.32541 10.385L6.31641 8.783L6.93741 8.108H9.29541V14.408H8.12541Z" fill="white"/>
      <path d="M10.4636 10.331C10.4636 8.918 11.3726 8 12.7316 8C14.0906 8 14.9996 8.918 14.9996 10.331V12.158C14.9996 
      13.571 14.0906 14.489 12.7316 14.489C11.3726 14.489 10.4636 13.571 10.4636 12.158V10.331ZM11.6156 12.158C11.6156
      12.797 11.8676 13.364 12.7316 13.364C13.5956 13.364 13.8476 12.797 13.8476 12.158V10.331C13.8476 9.692 13.5956
      9.125 12.7316 9.125C11.8676 9.125 11.6156 9.692 11.6156 10.331V12.158Z" fill="white"/>
      <path d="M21 11C21 5.4772 16.5229 1 11 1C5.4772 1 1 5.4772 1 11C1 16.5229 5.4772 21 11 21C14.2716 21 17.1763 19.4289
      19.0007 17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M23.5 9L21 11L18.5 9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;

    forwardButton.addClass('vjs-seek-forward');
    forwardButtonDom.onclick = () => {
      const currentTime = player.currentTime();
      player.currentTime(Math.min(player.duration(), currentTime + 10));
    };
    // Insert forward button after play/pause
    playToggleDom.parentNode?.insertBefore(forwardButtonDom, playToggleDom.nextSibling);
  }
  setProgramUrlAndGam(player: any, program: Program|Show) {
    const adTagUrl =  this.addCustomParams(program);
    // Set video source
    player.src({
      src: program.playbackStreamUrl,
      type: 'application/x-mpegURL'
    });

    // Initialize IMA
    player.ima({
      adTagUrl,
      debug: true,
      locale: 'en',
      vpaidMode: 2,
      showControlsForAds: true,
      adWillPlayMuted: false,
      adsRenderingSettings: {
        enablePreloading: true,
        loadVideoTimeout: 10000,
        playAdsAfterTime: 0,
        uiElements: ['adContainer', 'adProgress', 'adSkipButton']
      }
    });

    // Initialize ad display container
    player.ima.initializeAdDisplayContainer();
  }

  addCustomParams(program: Program | Show): string {
    let adTagUrl = `https://pubads.g.doubleclick.net/gampad/ads?iu=/23199477693/video_test&
    description_url=http%3A%2F%2Fapp.hometeamlive.com
    &tfcd=0&npa=0&sz=1x1%7C400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=`;

    let customParams: string[] = [];

    if ('leagueId' in program && program.leagueId) {
        customParams.push(`leagueIds=${encodeURIComponent(program.leagueId)}`);
    }

    if ('eventId' in program && program.eventId) {
        customParams.push(`eventId=${encodeURIComponent(program.eventId)}`);
    }

    if (customParams.length > 0) {
        adTagUrl += `&cust_params=${encodeURIComponent(customParams.join('&'))}`;
    }

    return adTagUrl;
}



   findProgramOrShow(program: Program | Show): boolean {
    const obj=program as Program;
    if(obj.programStatusId){
      return true;
    }
    else{
      return false;
    }
  }

}


