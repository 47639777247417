<app-detail-title [hrCustomClass]="'d-none'" i18n>
    QR Code Generator
  </app-detail-title>
  
  <app-content-container [narrow]="true">
    <div>
        <div *ngIf="!qrCodeId">
            <app-detail-back-button [backRoute]="'..'" class="d-block mt-4">
                <ng-container i18n>Qr code Details</ng-container>
            </app-detail-back-button>
        </div>
        <div *ngIf="qrCodeId">
          <app-detail-back-button [backRoute]="'../..'" class="d-block mt-4">
              <ng-container i18n>{{(title)}}</ng-container>
          </app-detail-back-button>
      </div>
    </div>
  
    <div class="qr-form">
        <div class="custom-switch-container d-flex align-items-center justify-content-end mb-4">
            <label class="custom-switch">
              <input [(ngModel)]="status"
                     type="checkbox"
                     class="form-control custom-switch-input"
                     name="priceOptionStatus"
                     value="{{status}}"
                     id="priceOptionStatus"
                     [disabled]="qrCodeId"
                    >
              <span class="custom-slider"></span>
            </label>
            <div class="switch-label-container ml-2">
              Active
            </div>
          </div>

        <div class="qr-inputs-container">
            <label for="">Title *</label>
            <input type="text" [(ngModel)]="title" class="form-control custom-textfield" placeholder="Enter title" [disabled]="qrCodeId">
        </div>

        <div class="qr-inputs-container mb-4">
            <div class="form-input-title">
                {{addInformationTitle}}
            </div>
        </div>

        <div class="qr-inputs-container">
            <label for="">Expiry date</label>
            <input type="date" [(ngModel)]="expiryDate" class="form-control custom-textfield" placeholder="Choose Date" [disabled]="qrCodeId">
        </div>
          
        <div *ngFor="let entry of entries; let i = index" class="qr-entries-container">
            <div class="w-100">
                <label for="">Information</label>
                <input type="text" [(ngModel)]="entry.information" class="form-control custom-textfield" placeholder="Enter info" [disabled]="qrCodeId">
            </div>
            <div class="w-100">
                <label for="">Value</label>
                <input type="text" [(ngModel)]="entry.value" class="form-control custom-textfield" placeholder="Enter value" [disabled]="qrCodeId">
            </div>
            <div class="checkbox-container">
                <app-checkbox
                    (clickEvent)="checkAddToUrl($event, i)"
                    [checked]="entry.addToUrl"
                    [label]="'Add to Url'"
                    [disabled]="qrCodeId"
                    >
                </app-checkbox>
            </div>
        
        </div>
        <div class="qr-add-button-container">
            <button class="custom-button neutral-button" (click)="addNewEntry()" [disabled]="qrCodeId">+</button>
        </div>
        <div class="d-flex  mt-4" *ngIf="!qrCodeId">
            <button class="custom-button preferred-button" [disabled]="submitButtonIsDisabled()" [ngClass]="submitButtonIsDisabled() ? 'preferred-button disabled' : ''" (click)="formSubmitted()">Generate QR code</button>
        </div>
        <div class="d-flex mt-4" *ngIf="qrCodeId">
            <button class="custom-button preferred-button" (click)="downloadQrCode()">Download QR code</button>
        </div>
    </div>
    <div class="qr-container">
        <div class="qr-frame" [ngClass]="url ? 'qr-black' : 'qr-gray'">
            <div class="qr-background">
                <ng-container *ngIf="url; else emptyQR">
                    <qrcode [qrdata]="url" [width]="160" [colorDark]="'#000000'" [colorLight]="'#FFFFFF'"></qrcode>
                </ng-container>
                <ng-template #emptyQR>
                    <img src="assets/img/qr.png" alt="Empty QR" class="qr-placeholder">
                </ng-template>
            </div>
            <div class="qr-logo">
                <ng-container *ngIf="url; else emptyLogo">
                    <img src="assets/HTN Sports/png/HTN_Full.png" alt="HTN Logo">
                </ng-container>
                <ng-template #emptyLogo>
                    <img src="assets/HTN Logos/png/HTN_White.png" alt="HTN Logo White">
                </ng-template>
            </div>
        </div>
    </div>
    <div class="qr-url-container">
        <div class="qr-url-value">
            <div class="w-100">
                <label for="">URL</label>                
                <input type="text" [value]="url" readonly class="form-control custom-textfield">
            </div>
            <button class="custom-button preferred-button" (click)="copyToClipboard()" [disabled]="!url" [ngClass]="!url ? 'preferred-button disabled' : ''">Copy URL</button>
        </div>
    </div>
    
    
  </app-content-container>
  