<div class="mt-3 position-relative">
    <div class="d-flex justify-content-end">
        <button class="custom-button table-cta-button ml-3" (click)="addNewQrCode()">
            <span>+</span>
            <span> Generate new QR code</span>
        </button>
    </div>
    <app-loading
        [hidden]="!viewModel.loadingOpts?.isLoading"
        [options]="viewModel.loadingOpts">
    </app-loading>
    <app-data-table
    [tableData]="dataProvider"
    [tableOptions]="datatableOptions"
    [tableFilter]="datatableFilter"
    [updateTableData]="updateTableData"
    [filterApplied]="tableFilterChanged"
    (rowClicked)="rowClicked($event)">
    </app-data-table>
</div>