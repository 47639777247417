import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {SessionContainer} from '../models/shared/session-container';
import {RefreshSessionRequest} from '../models/account/requests/refresh-session-request';
import {HydratedUser} from '../models/account/dto/hydrated-user';
import {CacheService} from './cache-service';
import {DefaultCacheKey} from '../models/enum/shared/default-cache-key.enum';
import {DateUtils} from '../utils/date-utils';
import {Role} from '../models/account/dto/role';
import {OpenAuthModalOptions} from '../models/account/open-auth-modal-options';
import {User} from '../models/account/dto/user';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  // Behaviour Subjects
  public refreshingSession: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public sessionContainer: BehaviorSubject<SessionContainer> = new BehaviorSubject<SessionContainer>(null);
  public destroySession: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userIsSubscriber: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private deadSession: boolean = true;
  public showAuthModal$: Subject<OpenAuthModalOptions> = new Subject<OpenAuthModalOptions>();
  public showEditPlansModalForPlanId$: Subject<string> = new Subject<string>();
  public editPlansModalLeagueId: number;
  public showUserAgreementModal$: Subject<void> = new Subject<void>();

  constructor(
    private cacheService: CacheService,
  ) {
    this.setupBindings();
  }

  public setupBindings() {
    this.sessionContainer.notNull().subscribe( (sess) => {
      if (sess && this.sessionContainer.getValue().rememberSession) {
        // save session to persistent cache
        this.cacheService.cacheObject(DefaultCacheKey.SessionContainer, sess, true);
      }
      // save session to session cache
      this.cacheService.cacheObject(DefaultCacheKey.SessionContainer, sess);
      this.isUserSubscriber();
    });

    this.destroySession.notNull().subscribe((shouldDestroy) => {
      if (shouldDestroy) {
        this.deadSession = true;
        this.cacheService.clearSessionCache();
        this.cacheService.clearPersistentCache();
        this.sessionContainer.next(null);
      }
    });
  }

  public getCachedSession(): SessionContainer {
    // Get user session from cache, checking session cache first
    let sess: SessionContainer;
    sess = this.cacheService.getCachedObject<SessionContainer>(SessionContainer, DefaultCacheKey.SessionContainer);
    if (!sess) {
      // Check the persistent cache for a session
      sess = this.cacheService.getCachedObject<SessionContainer>(SessionContainer, DefaultCacheKey.SessionContainer, true);
    }
    this.deadSession = false;
    if (!!sess) {
      this.sessionContainer.next(sess);
    }
    this.checkSubscriberAgreementStatus(sess?.user);
    return sess;
  }

  // Getters

  public liveSession(): boolean {
    return !this.deadSession;
  }

  public getUser(): HydratedUser {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user;
    }
    return null;
  }

  public isUserLoggedIn(): boolean{
    return !!this.sessionContainer.getValue();
  }


  public isUserSubscriber(): boolean {
    if (this.sessionContainer.getValue()) {
      this.userIsSubscriber.next(this.sessionContainer.getValue().userRole === null || undefined);
      return this.sessionContainer.getValue().userRole === null || undefined;
    } else if (this.getCachedSession()) {
      this.userIsSubscriber.next(this.sessionContainer.getValue().userRole === null || undefined);
      return this.getCachedSession().userRole === null || undefined;
    } else {
      return null;
    }
  }

  public getRole(): Role {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().userRole;
    }
    return null;
  }

  public getUserId(): number | string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.id;
    }
    return null;
  }

  public getUserEmail(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.email;
    }
    return '';
  }

  public getAuthToken(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.session?.accessToken;
    }
    return '';
  }

  public getRefreshToken(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.session?.refreshToken;
    }
    return '';
  }

  public getRefreshSessionReq(sess?: SessionContainer): RefreshSessionRequest {
    let req: RefreshSessionRequest;
    if (sess) {
      const refreshToken = sess.user?.session?.refreshToken;
      req =  new RefreshSessionRequest(refreshToken);
    } else if (this.sessionContainer.getValue()) {
      const refreshToken = this.getRefreshToken();
      req =  new RefreshSessionRequest(refreshToken);
    }
    if (!!req && !!req.refreshToken) {
      return req;
    } else {
      return null;
    }
  }

  // Setters
  public setUser(u: HydratedUser, role: Role, newSession: boolean, rememberSession: boolean = false) {
    const sessCopy = Object.assign(new SessionContainer(), this.sessionContainer.getValue());
    sessCopy.user = u;
    sessCopy.userRole = role;
    sessCopy.rememberSession = rememberSession;
    if (newSession) {
      sessCopy.sessionStartTime = DateUtils.currentTimestamp();
    }
    this.deadSession = false;
    this.sessionContainer.next(sessCopy);
    this.checkSubscriberAgreementStatus(u);
  }

  checkSubscriberAgreementStatus(u: User) {
    if (!!u && !u.roleId && !u.eulaConfirmation) {
      this.showUserAgreementModal$.next();
    }
  }
}
