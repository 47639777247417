import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './components/home/home.component';
import {SharedModule} from '../shared/shared.module';
import {WatchComponent} from './components/watch/watch.component';
import {ProgramRowComponent} from './components/program-row/program-row.component';
import {SignUpBannerComponent} from './components/sign-up-banner/sign-up-banner.component';
import {FeaturedLeaguesRowComponent} from './components/featured-leagues-row/featured-leagues-row.component';
import {HomeTabBarComponent} from './components/home-tab-bar/home-tab-bar.component';
import {NoContentComponent} from './components/no-content/no-content.component';
import {LeagueLandingComponent} from './components/league-landing/league-landing.component';
import {
  SubscriberLeagueDetailsComponent
} from './components/subscriber-league-details/subscriber-league-details.component';
import {LeagueSubscribeBannerComponent} from './components/league-subscribe-banner/league-subscribe-banner.component';
import {VenueLandingComponent} from './components/venue-landing/venue-landing.component';
import {SearchVenuesBannerComponent} from './components/search-venues-banner/search-venues-banner.component';
import {ProgramPlayerComponent} from './components/program-player/program-player.component';
import {WatchVenueStreamComponent} from './components/watch-venue-stream/watch-venue-stream.component';
import {
  BannerAdvertisementRowComponent
} from './components/banner-advertisement-row/banner-advertisement-row.component';
import {ImageSliderComponent} from './components/home/featured-program-slider/featured-program-slider.component';
// eslint-disable-next-line max-len
import { FeaturedProgramSlideComponent } from './components/home/featured-program-slider/featured-program-slide/featured-program-slide.component';
import {SwiperModule} from 'swiper/angular';
import {EventLandingComponent} from './components/event-landing/event-landing.component';
import {
  SubscriberEventDetailsComponent
} from './components/subscriber-event-details/subscriber-event-details.component';
import {EventSubscribeBannerComponent} from './components/event-subscribe-banner/event-subscribe-banner.component';
import {FeaturedEventsRowComponent} from './components/featured-events-row/featured-events-row.component';



@NgModule({
  declarations: [
    HomeComponent,
    WatchComponent,
    ProgramRowComponent,
    SignUpBannerComponent,
    FeaturedLeaguesRowComponent,
    FeaturedEventsRowComponent,
    HomeTabBarComponent,
    NoContentComponent,
    LeagueLandingComponent,
    EventLandingComponent,
    SubscriberLeagueDetailsComponent,
    SubscriberEventDetailsComponent,
    LeagueSubscribeBannerComponent,
    EventSubscribeBannerComponent,
    VenueLandingComponent,
    SearchVenuesBannerComponent,
    ProgramPlayerComponent,
    WatchVenueStreamComponent,
    BannerAdvertisementRowComponent,
    ImageSliderComponent,
    FeaturedProgramSlideComponent,
  ],
  exports: [
    ProgramPlayerComponent,
    ProgramRowComponent,
    NoContentComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    SwiperModule
  ]
})
export class HomeModule {
}
