import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {SessionService} from '../../../../services/session-service';
import {filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {AuthFlow} from '../../../../models/account/enum/auth-flow.enum';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import {environment} from '../../../../../environments/environment';
import {OpenAuthModalOptions} from '../../../../models/account/open-auth-modal-options';
import {Router} from '@angular/router';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';

@Injectable()
export class LeagueSubscribeBannerViewModel extends BaseViewModel {

  hydratedLeague$ = new BehaviorSubject<HydratedLeague>(null);
  isSignedIn$ = this.sessionService.sessionContainer.pipe(map(s => !!s));
  showSubscribeBanner$ = combineLatest([this.hydratedLeague$, this.accountDomainModel.subscriberSubscriptions$]).pipe(
    filter(params => params.every(p => !!p)),
    map(([league, subscriptions]) => {
      const leaguePlanId = league.subscriptionPlanId;
      return leaguePlanId !== environment.freePlanId && !subscriptions?.some(s => s.planId === leaguePlanId);
    })
  );

  leagueBannerAds$ = this.hydratedLeague$.pipe(
    map(l => l?.advertisementBanners),
    shareReplay({bufferSize: 1, refCount: true})
  );

  constructor(private sessionService: SessionService,
              private accountDomainModel: AccountDomainModel,
              private resourceDomainModel: ResourceDomainModel,
              private router: Router) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  subscribeClicked() {
    if (!this.accountDomainModel.sessionContainer$.getValue()) {
      const leaguePlanId = this.hydratedLeague$.getValue()?.subscriptionPlanId;
      const leagueId = this.hydratedLeague$.getValue()?.id;
      const options = new OpenAuthModalOptions(AuthFlow.SignUp,
        this.router.url, $localize`Sign in to continue`, $localize`Sign up to access premium league content`, leaguePlanId, null, leagueId);
      this.sessionService.showAuthModal$.next(options);
    } else {
      const leaguePlanId = this.hydratedLeague$.getValue()?.subscriptionPlanId;
      this.sessionService.editPlansModalLeagueId = this.hydratedLeague$.getValue()?.id;
      this.sessionService.showEditPlansModalForPlanId$.next(leaguePlanId);
    }
  }

}
