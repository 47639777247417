import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {ProgramDomainModel} from '../../../../domainModels/program-domain-model';
import {BehaviorSubject, Observable} from 'rxjs';
import {GoogleAnalyticsService} from '../../../../services/google-analytics-service';
import {Show} from '../../../../models/program/show';
import {Program} from '../../../../models/program/program';
import {Advertisement} from '../../../../models/resources/advertisement';
import Timeout = NodeJS.Timeout;
import {filter, map, take} from 'rxjs/operators';
import { programEndtime } from 'src/app/models/program-end-time';

@Injectable()
export class IvsVideoPlayerViewModel extends BaseViewModel {

  playerReady = new BehaviorSubject(false);
  playerSrcUrl = new BehaviorSubject<string>(null);
  preRollAdSrcUrl = new BehaviorSubject<string>(null);
  preRollAdCompleted = new BehaviorSubject<boolean>(false);
  playerPosterSrc = new BehaviorSubject<string>(null);
  playEventTracked = false;
  playingPreRollAd = false;
  advertisementId: string = null;
  public programIncrementPlayCount: Timeout;
  listenForAdCompleted = this.preRollAdCompleted.pipe(
    filter(completed => !!completed),
    take(1),
  ).subscribe(() => {
    this.trackAdPlayed();
  }).addTo(this.subscriptions);


  constructor(
    private programDomainModel: ProgramDomainModel,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    super();
    this.init();
  }

  listenForProgramIncrementPlayCount(program: Program | Show) {
    this.stopTracking();
    this.programIncrementPlayCount = setTimeout(() => {
      this.trackProgramPlayed(program);
    }, 15000);
  }

  stopTracking() {
    if(!!this.programIncrementPlayCount){
      clearTimeout(this.programIncrementPlayCount);
    }
  }

  init() {
    super.init();
    this.setupBindings();
  }

  setupBindings() {

  }

  trackVideoEvent(program: Program | Show, action: 'play' | 'pause',
  streamType: string) {
    this.googleAnalyticsService.trackVideoEvent(program, action,streamType);
    this.playEventTracked = true;
    this.listenForProgramIncrementPlayCount(program);
  }

  trackAdPlayed() {
    this.programDomainModel.trackAdvertisementPlayed(this.advertisementId).subscribe(() => {});
  }

  trackProgramPlayed(program: Program | Show) {
    if(program instanceof Show){
      this.programDomainModel.trackShowsIncrementPlayCount(program.id);
    } else {
      this.programDomainModel.trackProgramsIncrementPlayCount(program.id);
    }
  }
  getProgramEndTime(programid: string): Observable<programEndtime>{
    return this.programDomainModel.getProgramEndTime(programid);
  }
}
