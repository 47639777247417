import { BaseLookupType } from '../../base/base-lookup-type';
import { Selectable } from '../../protocols/selectable';

export class Ngb extends BaseLookupType implements Selectable{
    public id: number;
    public name: string;

    getSelectionTitle(): string {
       return this.name;
    }
    getSelectionValue() {
        return this.name + this.id;
    }
    getSelectionUniqueIdentifier() {
        return this.id;
    }

}
