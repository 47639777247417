import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { BaseComponent } from 'src/app/models/base/base-component';
import { BaseDatatableDataProvider } from 'src/app/models/base/base-datatable-data-provider';
import { Tabbable } from 'src/app/models/protocols/tabbable';
import { DatatableFilter } from 'src/app/models/shared/datatable-filter';
import { DatatableOptions } from 'src/app/models/shared/stylesheet/datatable-options';
import { LeagueDetailsViewModel } from '../league-details-view-model';
import { DatatableColumn, DatatableColumnType } from 'src/app/models/shared/stylesheet/datatable-column';
import { qrCode } from 'src/app/models/resources/qrCode';
import { DatatableAction } from 'src/app/models/shared/stylesheet/datatable-action';
import { DatatableData } from 'src/app/models/protocols/datatable-data';

@Component({
  selector: 'app-league-qr-generator',
  templateUrl: './league-qr-generator.component.html',
  styleUrls: ['./league-qr-generator.component.scss']
})
export class LeagueQrGeneratorComponent extends BaseComponent implements Tabbable {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public dataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();
  private performSilentUpdate = false;

  constructor(
    public viewModel: LeagueDetailsViewModel,
    public router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }
  setupViews() {
    this.setUpDatatable();
  }
  setupBindings() {
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationStart) {
        this.viewModel.refreshLeagueQrCode$.next();
      }
    });
    this.viewModel.leagueQrCode$.notNull().subscribe(s => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = s;
        this.updateTableData.next(dataProvider);
      });
    }).addTo(this.subscriptions);
  }


  setUpDatatable(){
    this.datatableOptions.columns = this.getDataTablesColumns();
    this.setDatatableFilter();
  }

  getDataTablesColumns() {
    const columns: DatatableColumn[] = [];

    const title = new DatatableColumn(
      'title',
            $localize`Title`,
            DatatableColumnType.Text,
            (c: any): string=>{
              return c.title;
            }
    );
    columns.push(title);

    const expiryDate = new DatatableColumn(
      'expiryDate',
      $localize`Expiry Date`,
      DatatableColumnType.Text,
      (c: any): string => {
        if (!c.expiryDate) {
          return '-';
        }
    
        const expiryUtc = new Date(Date.UTC(
          new Date(c.expiryDate).getUTCFullYear(),
          new Date(c.expiryDate).getUTCMonth(),
          new Date(c.expiryDate).getUTCDate()
        ));
    
        const now = new Date();
        const nowUtcTimestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
        const nowUtc = new Date(nowUtcTimestamp);
    
        if (expiryUtc <= nowUtc) {
          return 'Expired';
        }
    
        const month = (expiryUtc.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = expiryUtc.getUTCDate().toString().padStart(2, '0');
        const year = expiryUtc.getUTCFullYear().toString();
    
        return `${month}/${day}/${year}`;
      }
    );
    columns.push(expiryDate);

    const createdOn = new DatatableColumn(
      'createdOn',
            $localize`Created On`,
            DatatableColumnType.Text,
            (c: any): string=>{
              const date = new Date(c.createdDate);
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              const month = (date.getMonth() + 1).toString().padStart(2, '0');
              const day = date.getDate().toString().padStart(2, '0');
              const year = date.getFullYear().toString();
              return `${month}/${day}/${year}`;
            }
    );
    columns.push(createdOn);

    const status = new DatatableColumn(
      'status',
            $localize`Status`,
            DatatableColumnType.Text,
            (c: any): string=>{
              return c.isActive ? 'Active' : 'Inactive';
            }
    );
    columns.push(status);

    const downloadAction = new DatatableColumn(
      'download',
            $localize`Operation`,
            DatatableColumnType.Actions,
            (c: any): string=>{
              return c.id;
            }
    );

    downloadAction.addAction((rowData: qrCode) => {
      return new DatatableAction(
        1,
        '/assets/icons/dark/solid/download.svg',
        'Download',
        () => {
          this.viewModel.downloadQrCode(rowData.id).subscribe((res) => {
            const blobUrl = window.URL.createObjectURL(res);
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = `${rowData.title}.png`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(blobUrl);
          });

        }
      ,
      () =>{
        return '/assets/icons/dark/solid/download.svg';
      }, () => {
        return 'Download';
      });});
    columns.push(downloadAction);
    return columns;
  }

  private setDatatableFilter() {
    this.datatableFilter.enableSearch = false;
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.customFilters = [];
  }

  addNewQrCode() {
    this.router.navigate([`./addNewQrCode`], {relativeTo: this.activatedRoute}).then();
  }

  rowClicked(data: DatatableData) {
    const cont=data as qrCode;
    this.router.navigate([`./qr-generator/${cont.id}`], {relativeTo: this.activatedRoute}).then();
  }

}
