import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UsersComponent} from './users/users.component';
import {SportsComponent} from './sports/sports.component';
import {LeaguesComponent} from './leagues/leagues.component';
import {VenuesComponent} from './venues/venues.component';
import {ProfileComponent} from './profile/profile.component';
import {IsAdminGuard} from '../../services/guards/is-admin.guard';
import {VenueDetailsComponent} from './venues/venue-details/venue-details.component';
import {AdminChangePasswordComponent} from './profile/admin-change-password/admin-change-password.component';
import {LeagueDetailsComponent} from './leagues/league-details/league-details.component';
import {EventDetailsComponent} from './events/events-details/event-details.component';
import {ProgramDetailsComponent} from './shared/program-details/program-details.component';
import {ShowDetailsComponent} from './shared/show-details/show-details.component';
import {AdvertisingPartnersComponent} from './advertising-partners/advertising-partners.component';
import {
  AdvertisingPartnerDetailsComponent
} from './advertising-partners/advertising-partner-details/advertising-partner-details.component';
import {
  AdvertisementDetailsComponent
} from './advertising-partners/advertisement-details/advertisement-details.component';
import {AdminTeamsComponent} from './admin-teams/admin-teams.component';
import {ProgramUploaderComponent} from './shared/program-uploader/program-uploader.component';
import {EventsComponent} from './events/events.component';
import { AdminAuthGuard } from 'src/app/services/guards/admin-auth.guard';
import {PlansComponent} from './plans/plans.components';
import {PlansDetailsComponent} from './plans/plans-details/plans-details.component';
import {CameraSystemsComponent} from './camera-systems/camera-systems.component';
import {
  EditCameraSystemsComponent
} from './camera-systems/camera-systems-details/edit-camera-systems/edit-camera-systems.component';
import {EditAdminUsersComponent} from './users/edit-admin-users/edit-admin-users.component';
import { CameraSystemLandingComponent } from './camera-systems/camera-system-landing/camera-system-landing.component';
import { LeagueContributorDetailsComponentComponent }
 from './leagues/league-details/league-contributor-details-component/league-contributor-details-component.component';
import { EventsContributorsDetailsComponent } from
'./events/events-details/events-contributors-details/events-contributors-details.component';
import { LeagueCreateQrGeneratorComponent } from './leagues/league-details/league-create-qr-generator/league-create-qr-generator.component';
import { EventQrGeneratorComponent } from './events/events-details/event-qr-generator/event-qr-generator.component';
import { EventCreateQrGeneratorComponent } from './events/events-details/event-create-qr-generator/event-create-qr-generator.component';

const routes: Routes = [
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'users/add',
    component: EditAdminUsersComponent,
    canActivate: [IsAdminGuard],
    data: { addUser: true }
  },
  {
    path: 'users/:id',
    component: EditAdminUsersComponent,
    canActivate: [IsAdminGuard],
    data: {cacheRoute: true}
  },
  {
    path: 'sports',
    component: SportsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'teams',
    component: AdminTeamsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'leagues',
    component: LeaguesComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'leagues/add',
    component: LeagueDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard],
    data: {addLeague: true}
  },
  {
    path: 'leagues/:leagueId',
    component: LeagueDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard],
    data: {cacheRoute: true}
  },
  {
    path: 'leagues/:leagueId/programs/add',
    component: ProgramDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'leagues/:leagueId/shows/add',
    component: ShowDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'leagues/:leagueId/programs/:programId',
    component: ProgramDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'leagues/:leagueId/program-upload',
    component: ProgramUploaderComponent,
    canActivate: [IsAdminGuard]
  },
  {
    path: 'leagues/:leagueId/shows/:showId',
    component: ShowDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'leagues/:leagueId/addContributor',
    component: LeagueContributorDetailsComponentComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'leagues/:leagueId/contributors/:contributorId',
    component: LeagueContributorDetailsComponentComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'leagues/:leagueId/contributors',
    component: LeagueDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'leagues/:leagueId/addNewQrCode',
    component: LeagueCreateQrGeneratorComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'leagues/:leagueId/qr-generator/:qrCodeId',
    component: LeagueCreateQrGeneratorComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'venues',
    component: VenuesComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'venues/add',
    component: VenueDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard],
  },
  {
    path: 'venues/:venueId',
    component: VenueDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard],
    data: {cacheRoute: true}
  },
  {
    path: 'venues/:venueId/programs/add',
    component: ProgramDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'venues/:venueId/program-upload',
    component: ProgramUploaderComponent,
    canActivate: [IsAdminGuard]
  },
  {
    path: 'venues/:venueId/programs/:programId',
    component: ProgramDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [IsAdminGuard]
  },
  {
    path: 'events/add',
    component: EventDetailsComponent,
    canActivate: [IsAdminGuard],
    data: {addEvent: true}
  },
  {
    path: 'events/:eventId',
    component: EventDetailsComponent,
    canActivate: [IsAdminGuard],
    data: {cacheRoute: true}
  },
  {
    path: 'events/:eventId/programs/add',
    component: ProgramDetailsComponent,
    canActivate: [IsAdminGuard]
  },
  {
    path: 'events/:eventId/shows/add',
    component: ShowDetailsComponent,
    canActivate: [IsAdminGuard]
  },
  {
    path: 'events/:eventId/programs/:programId',
    component: ProgramDetailsComponent,
    canActivate: [IsAdminGuard]
  },
  {
    path: 'events/:eventId/program-upload',
    component: ProgramUploaderComponent,
    canActivate: [IsAdminGuard]
  },
  {
    path: 'events/:eventId/shows/:showId',
    component: ShowDetailsComponent,
    canActivate: [IsAdminGuard]
  },
  {
    path: 'events/:eventId/addEventContributor',
    component: EventsContributorsDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'events/:eventId/contributors/:contributorId',
    component: EventsContributorsDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'events/:eventId/addNewQrCode',
    component: EventCreateQrGeneratorComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'events/:eventId/qr-generator/:qrCodeId',
    component: EventCreateQrGeneratorComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'profile/change-password',
    component: AdminChangePasswordComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'advertisements',
    component: AdvertisingPartnersComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'advertisements/:partnerId',
    component: AdvertisingPartnerDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'advertisements/:partnerId/add',
    component: AdvertisementDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'advertisements/:partnerId/:advertisementId',
    component: AdvertisementDetailsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
  path: 'plans',
  component: PlansComponent,
  canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'plans/add',
    component: PlansDetailsComponent,
    canActivate: [IsAdminGuard],
    data: { addPlan: true }
  },
  {
    path: 'plans/:planId',
    component: PlansDetailsComponent,
    canActivate: [IsAdminGuard],
    data: {cacheRoute: true}
  },
  {
    path: 'camera-systems',
    component: CameraSystemsComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'camera-systems-landing',
    component: CameraSystemLandingComponent,
    canActivate: [IsAdminGuard, AdminAuthGuard]
  },
  {
    path: 'camera-systems-landing/add',
    component: EditCameraSystemsComponent,
    canActivate: [IsAdminGuard],
    data: { addSystem: true }
  },
  {
    path: 'camera-systems-landing/:cameraSystemId',
    component: EditCameraSystemsComponent,
    canActivate: [IsAdminGuard],
    data: {cacheRoute: true}
  },
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
