import {Component, EventEmitter, Output} from '@angular/core';
import {ProgramDetailsViewModel} from './program-details-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {ProgramFormObject} from '../../../../models/program/program-form-object';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, filter, map, skip} from 'rxjs/operators';
import {CustomFile} from '../../../../models/shared/custom-file';
import {ProductionType} from '../../../../models/lookup/production-type';
import {HydratedTeam} from '../../../../models/resources/hydrated-team';
import {ConfirmationModalComponent} from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import {ModalUtils} from '../../../../utils/modal-utils';
import {ConfirmationOptions} from '../../../../models/shared/stylesheet/confirmation-options';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProgramStatusType} from '../../../../models/lookup/program-status-type';
import {Selectable} from '../../../../models/protocols/selectable';

@Component({
  selector: 'app-program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['./program-details.component.scss'],
  providers: [ProgramDetailsViewModel],
})
export class ProgramDetailsComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: ProgramFormObject;
  public updatedFormObject = new EventEmitter<void>();
  public hydrateInputObject = new EventEmitter<void>();
  public programStatusType = ProgramStatusType;


  constructor(
    public viewModel: ProgramDetailsViewModel,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    super();
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
  }

  setupBindings() {
    this.viewModel.program$.notNull().pipe(debounceTime(100)).subscribe((program) => {
      this.formObject = ProgramFormObject.initWithProgram(program);
      if (this.formItems.length === 0) {
        this.setupFormItems();
      }
    }).addTo(this.subscriptions);

    this.viewModel.formErrorMessage.pipe(debounceTime(100)).subscribe(error => {
      const banner = this.formItems.find(f => f.alertBannerId === 'banner');
      if (banner) {
        banner.alertBannerMessage = error;
      }
    }).addTo(this.subscriptions);
  }

  setupFormBindings() {
    this.viewModel.productionTypes$.notNull().pipe(debounceTime(100)).subscribe(pt => {
      this.setProductionTypeOptions(pt);
    }).addTo(this.subscriptions);

    this.viewModel.filteredTeams$.notNull().pipe(debounceTime(100)).subscribe(teams => {
      this.setTeamOptions(teams);
    }).addTo(this.subscriptions);
    this.viewModel.venueStreams$.notNull().pipe(debounceTime(100)).subscribe(venues => {
      this.setVenueStreamOptions(venues);
    }).addTo(this.subscriptions);
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const programDescription = new FormInputItem();
    programDescription.itemType = FormItemType.Title;
    programDescription.label = $localize`Description`;
    programDescription.overrideFullWidth = true;
    programDescription.itemType = FormItemType.Textarea;
    programDescription.inputName = 'description';
    programDescription.label = $localize`Program Description`;
    programDescription.placeholder = $localize`Write a custom program description`;
    programDescription.bindingProperty = 'program.description';
    programDescription.required = false;
    programDescription.autoGrowTextArea = true;
    programDescription.autoGrowMinRows = 6;
    programDescription.autoGrowMaxRows = 6;
    programDescription.customClass = 'mb-3';
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      programDescription.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      programDescription.setFormEnabledStatus(true);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      programDescription.setFormEnabledStatus(true);
    }
    items.push(programDescription);

    const shortDescription = new FormInputItem();
    shortDescription.inputType = FormInputType.Text;
    shortDescription.inputName = 'shortDescription';
    shortDescription.label = $localize`Short Description`;
    shortDescription.placeholder = $localize`Write a custom short description`;
    shortDescription.bindingProperty = 'program.shortDescription';
    shortDescription.required = false;
    shortDescription.overrideFullWidth = true;
    shortDescription.customClass = 'mb-3';
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      shortDescription.setFormEnabledStatus(true);
    } else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      shortDescription.setFormEnabledStatus(true);
    } else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      shortDescription.setFormEnabledStatus(true);
    }
    items.push(shortDescription);
    items.push(FormInputItem.generateDivider());

    const dateTimeTitle = new FormInputItem();
    dateTimeTitle.itemType = FormItemType.Title;
    dateTimeTitle.label = $localize`Date & Time`;
    dateTimeTitle.overrideFullWidth = true;
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      dateTimeTitle.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      dateTimeTitle.setFormEnabledStatus(false);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      dateTimeTitle.setFormEnabledStatus(false);
    }
    items.push(dateTimeTitle);

    const programDate = new FormInputItem();
    programDate.itemType = FormItemType.Input;
    programDate.inputType = FormInputType.Date;
    programDate.inputName = 'programDate';
    programDate.label = $localize`Date`;
    programDate.placeholder = $localize`Choose Date`;
    programDate.bindingProperty = 'programDate';
    programDate.required = true;
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      programDate.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      programDate.setFormEnabledStatus(false);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      programDate.setFormEnabledStatus(false);
    }
    items.push(programDate);

    const programTime = new FormInputItem();
    programTime.itemType = FormItemType.Input;
    programTime.inputType = FormInputType.Time;
    programTime.inputName = 'programTime';
    programTime.label = $localize`Time`;
    programTime.inputStep = '60';
    programTime.placeholder = $localize`Choose Time`;
    programTime.bindingProperty = 'programTime';
    programTime.required = true;
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      programTime.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      programTime.setFormEnabledStatus(false);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      programTime.setFormEnabledStatus(false);
    }
    items.push(programTime);

    const programStartTimeOffset = new FormInputItem();
    programStartTimeOffset.itemType = FormItemType.Dropdown;
    programStartTimeOffset.inputName = 'startTimeOffset';
    programStartTimeOffset.label = $localize`Start time offset`;
    programStartTimeOffset.placeholder = $localize`Choose Start time offset`;
    programStartTimeOffset.bindingProperty = 'program.pixellotEventStartTimeOffsetInMinutes';
    programStartTimeOffset.dropdownIsObject = true;
    programStartTimeOffset.required = false;
    programStartTimeOffset.dropdownOptions = this.viewModel.getTimeOffset();
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      programStartTimeOffset.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      programStartTimeOffset.setFormEnabledStatus(false);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      programStartTimeOffset.setFormEnabledStatus(false);
    }
    items.push(programStartTimeOffset);

    const programStartDateTimeZone = new FormInputItem();
    programStartDateTimeZone.itemType = FormItemType.Dropdown;
    programStartDateTimeZone.inputName = 'timeZone';
    programStartDateTimeZone.label = $localize`Time Zone`;
    programStartDateTimeZone.placeholder = $localize`Choose Time Zone`;
    programStartDateTimeZone.bindingProperty = 'programTimeZone';
    programStartDateTimeZone.dropdownIsObject = true;
    programStartDateTimeZone.required = true;
    programStartDateTimeZone.dropdownOptions = this.viewModel.getTimeZoneOptions();
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      programStartDateTimeZone.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      programStartDateTimeZone.setFormEnabledStatus(false);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      programStartDateTimeZone.setFormEnabledStatus(false);
    }
    items.push(programStartDateTimeZone);

    const programDuration = new FormInputItem();
    programDuration.itemType = FormItemType.Dropdown;
    programDuration.inputName = 'durationInMinutes';
    programDuration.label = $localize`Stream Duration`;
    programDuration.placeholder = $localize`Choose Duration`;
    programDuration.bindingProperty = 'program.durationInMinutes';
    programDuration.dropdownIsObject = true;
    programDuration.required = true;
    programDuration.dropdownOptions = this.viewModel.getDurationOptions();
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      programDuration.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      programDuration.setFormEnabledStatus(true);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      programDuration.setFormEnabledStatus(false);
    }
    items.push(programDuration);

    items.push(FormInputItem.generateDivider('dateTimeDivider'));

    const contentTitle = new FormInputItem();
    contentTitle.itemType = FormItemType.Title;
    contentTitle.label = $localize`Content`;
    contentTitle.overrideFullWidth = true;
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      contentTitle.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      contentTitle.setFormEnabledStatus(false);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      contentTitle.setFormEnabledStatus(false);
    }
    items.push(contentTitle);

    const homeTeam = new FormInputItem();
    homeTeam.itemType = FormItemType.Dropdown;
    homeTeam.inputName = 'homeTeam';
    homeTeam.label = $localize`Home Team`;
    homeTeam.placeholder = $localize`Choose a Team`;
    homeTeam.bindingProperty = 'program.homeTeamId';
    homeTeam.dropdownIsObject = true;
    homeTeam.required = false;
    homeTeam.dropdownOptions = [];
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      homeTeam.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      homeTeam.setFormEnabledStatus(true);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      homeTeam.setFormEnabledStatus(true);
    }
    items.push(homeTeam);

    const awayTeam = new FormInputItem();
    awayTeam.itemType = FormItemType.Dropdown;
    awayTeam.inputName = 'visitingTeam';
    awayTeam.label = $localize`Away Team`;
    awayTeam.placeholder = $localize`Choose a Team`;
    awayTeam.bindingProperty = 'program.visitingTeamId';
    awayTeam.dropdownIsObject = true;
    awayTeam.required = false;
    awayTeam.dropdownOptions = [];
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      awayTeam.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      awayTeam.setFormEnabledStatus(true);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      awayTeam.setFormEnabledStatus(true);
    }
    items.push(awayTeam);

    const venueStream = new FormInputItem();
    venueStream.itemType = FormItemType.Dropdown;
    venueStream.inputName = 'venueStream';
    venueStream.label = $localize`Venue Stream`;
    venueStream.placeholder = $localize`Choose a Venue Stream`;
    venueStream.bindingProperty = 'program.venueStreamId';
    venueStream.dropdownIsObject = true;
    venueStream.required = true;
    venueStream.dropdownOptions = [];
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      venueStream.setFormEnabledStatus(true);
    }
    else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      venueStream.setFormEnabledStatus(false);
    }
    else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      venueStream.setFormEnabledStatus(false);
    }
    items.push(venueStream);

    const productionType = new FormInputItem();
    productionType.itemType = FormItemType.Dropdown;
    productionType.inputName = 'productionTypeId';
    productionType.label = $localize`Production Type`;
    productionType.placeholder = $localize`Choose a Program Type`;
    productionType.bindingProperty = 'program.productionTypeId';
    productionType.dropdownIsObject = true;
    productionType.required = true;
    productionType.enabled = true;
    productionType.dropdownOptions = [];
    productionType.valueChanged
      .pipe(map(([value, ]) => value), filter(v => !!v), skip(1))
      .subscribe((pt) => {
      this.showProductionTypeWarning(pt);
      this.updateStreamDetailsVisibility(pt);
    }).addTo(this.subscriptions);
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      productionType.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      productionType.setFormEnabledStatus(false);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      productionType.setFormEnabledStatus(false);
    }
    items.push(productionType);

    const freeToWatch = new FormInputItem();
    freeToWatch.itemType = FormItemType.CheckboxItem;
    freeToWatch.inputName = 'freeToWatch';
    freeToWatch.label = $localize`Free to Watch`;
    freeToWatch.bindingProperty = 'program.freeToWatch';
    freeToWatch.required = false;
    freeToWatch.valueChanged.subscribe((value) => {
      this.formObject.freeToWatch = value[0];
    });
    items.push(freeToWatch);

    items.push(FormInputItem.generateDivider());


    const projectedContent = new FormInputItem();
    projectedContent.itemType = FormItemType.ProjectedContent;
    if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.ScheduledId) {
      projectedContent.setFormEnabledStatus(true);
    }else if(this.viewModel.program$.getValue().programStatusId === ProgramStatusType.InProgressId) {
      projectedContent.setFormEnabledStatus(false);
    }else if((this.viewModel.program$.getValue().programStatusId === ProgramStatusType.PastId
|| this.viewModel.program$.getValue().programStatusId === ProgramStatusType.CompletedId)) {
      projectedContent.setFormEnabledStatus(false);
    }
    items.push(projectedContent);

    items.push(FormInputItem.generateDivider());

    this.formItems = items;
    this.setupFormBindings();
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    this.formStyling.includePadding = false;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'd-flex flex-row-reverse justify-content-end';
    this.formStyling.submitButtonText = $localize`Save Program`;
    this.formStyling.resetButtonText = '';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.completeProgramButtonText = $localize`Complete Program`;
    this.formStyling.tertiaryButtonText = $localize`Delete Program`;
    this.formStyling.tertiaryButtonClass =
      this.PermissionManagerService
        .isGranted
        (this.Types.AdminSections.Leagues_Edit_League_Programs_Remove ||
          this.Types.AdminSections.Events_Edit_Event_Programs_Remove ||
          this.Types.AdminSections.Venues_Edit_Venue_Programs_Remove)
        ? 'preferred-button' : 'preferred-button disabled';
    this.formStyling.tertiaryButtonFloat = 'right';
    this.formStyling.secondaryButtonClass = 'mr-0';
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = true;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  completeProgramWarningButton() {
     this.showCompleteProgramWarning(ProgramStatusType.InProgressId);
  }

  deleteLeagueProgramWarningButton() {
    this.showDeleteLeagueProgramWarning(ProgramStatusType.InProgressId);
  }

  deleteEventProgramWarningButton() {
    this.showDeleteEventProgramWarning(ProgramStatusType.InProgressId);
  }

  isInProgress() {
    return (this.viewModel.program$?.getValue()?.programStatusId === ProgramStatusType.InProgressId);
  }

  showTertiaryButtonCondition() {
    return this.viewModel.program$?.getValue()?.programStatusId === ProgramStatusType.InProgressId ||
      this.viewModel.program$?.getValue()?.programStatusId === ProgramStatusType.ScheduledId ||
      this.viewModel.program$?.getValue()?.programStatusId === ProgramStatusType.PastId ||
      this.viewModel.program$?.getValue()?.programStatusId === ProgramStatusType.CompletedId;
  }

  handleTertiaryButtonCondition() {
      if (this.isLeagueProgram()) {
        this.deleteLeagueProgramWarningButton();
      } else {
        this.deleteEventProgramWarningButton();
      }
  }


  isLeagueProgram() {
    return this.viewModel.program$?.getValue()?.leagueId != null;
  }


  formSubmitted(result: ProgramFormObject) {
    const homeTeamInput = this.formItems.find(f => f.inputName === 'homeTeam');
    const visitingTeamInput = this.formItems.find(f => f.inputName === 'visitingTeam');
    const homeTeamID = homeTeamInput.getValue();
    const visitingTeamID = visitingTeamInput.getValue();
    if (homeTeamID != null){
      this.viewModel.filteredTeams$.notNull().forEach(teams => teams.forEach(team => {
        if (team.id === homeTeamID){
          result.homeTeam = team.name;
          result.homeTeamId = homeTeamID;
        }
      }));
    }
    else {
      result.homeTeam = null;
      result.homeTeamId = null;
    }
    if (visitingTeamID != null){
      this.viewModel.filteredTeams$.notNull().forEach(teams => teams.forEach(team => {
        if (team.id === visitingTeamID){
          result.visitingTeam = team.name;
          result.visitingTeamId = visitingTeamID;
        }
      }));
    }
    else {
      result.visitingTeam = null;
      result.visitingTeamId = null;
    }
    this.viewModel.saveProgram(result);
  }

  fileList(f: CustomFile[], id: number) {
    if (f.length > 0) {
      this.formObject.imageToUpload = f[0].url;
      this.formObject.imageToUploadFormat = f[0].type;
    } else {
      this.formObject.imageToUpload = undefined;
      this.formObject.imageToUploadFormat = undefined;
    }
  }

  setProductionTypeOptions(productionTypes: ProductionType[]) {
    const productionTypeInput = this.formItems.find(f => f.inputName === 'productionTypeId');
    productionTypeInput.dropdownOptions = productionTypes;
  }

  setTeamOptions(teams: HydratedTeam[]) {
    const homeTeamInput = this.formItems.find(f => f.inputName === 'homeTeam');
    const visitingTeamInput = this.formItems.find(f => f.inputName === 'visitingTeam');
    const noTeamOption = {
      getSelectionTitle:(): string => {
        return $localize`N/A`;
      }, getSelectionUniqueIdentifier: () =>{
        return null;
      }, getSelectionValue: () => {
        return null;
      }
    };
    const teamOptions = [noTeamOption, ...teams];
    homeTeamInput.dropdownOptions =  teamOptions;
    visitingTeamInput.dropdownOptions =  teamOptions;
  }

  setVenueStreamOptions(venues: Selectable[]) {
    const venueStreamInput = this.formItems.find(f => f.inputName === 'venueStream');
    venueStreamInput.dropdownOptions = venues;
  }

  removeImageClicked() {
    if (this.formObject.existingImageId) {
      this.formObject.deleteImageId = this.formObject.existingImageId;
      this.formObject.existingImageId = null;
    }
    this.formObject.imageToUpload = null;
  }

  cancel() {
    this.router.navigate(['../..'], {relativeTo: this.activatedRoute, fragment: 'programs'}).then();
  }

  showProductionTypeWarning(productionTypeIdSelected: number): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Changing Production Type`;
    if (productionTypeIdSelected === ProductionType.StandardId) {
      opts.bodyText =
        $localize`Caution - Changing the production type to Standard will result in the stream feed bypassing the editing studio`;
    } else {
      opts.bodyText =
        $localize`Caution - Changing the production type to Premium will require the stream feed to flow through the editing studio`;
    }
    opts.cancelText = '';
    opts.continueText = $localize`Okay`;
    opts.continueButtonClass = 'preferred-button';
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((cancel) => {
    }, () => {});
  }

  showCompleteProgramWarning(InProgressId: number): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Complete Program`;
    opts.bodyText = $localize`Are you sure you want to complete the program? This will end the live streaming event.`;
    opts.cancelText = $localize `Cancel`;
    opts.continueText = $localize`Complete Program`;
    opts.continueButtonClass = 'preferred-button';
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((complete) => {
      if(complete) {
        this.viewModel.completeProgram(this.formObject);
        this.router.navigate(['../..'], {relativeTo: this.activatedRoute, fragment: 'programs'}).then();
      }
    }, () => {
    });
    }

    showDeleteLeagueProgramWarning(ScheduledId: number): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Delete Program`;
    opts.bodyText = $localize`Are you sure you want to delete this program? This will permanently delete this stream.`;
    opts.cancelText = $localize`Cancel`;
    opts.continueText = $localize`Delete Program`;
    opts.continueButtonClass = 'preferred-button';
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((deleted) => {
      if(deleted) {
        this.viewModel.deleteLeagueProgram(this.formObject);
        this.router.navigate(['../..'], {relativeTo: this.activatedRoute, fragment: 'programs'}).then();
      }
      }, () => {
    });
    }

  showDeleteEventProgramWarning(ScheduledId: number): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Delete Program`;
    opts.bodyText = $localize`Are you sure you want to delete this program? This will permanently delete this stream.`;
    opts.cancelText = $localize`Cancel`;
    opts.continueText = $localize`Delete Program`;
    opts.continueButtonClass = 'preferred-button';
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((deleted) => {
      if(deleted) {
        this.viewModel.deleteEventProgram(this.formObject);
        this.router.navigate(['../..'], {relativeTo: this.activatedRoute, fragment: 'programs'}).then();
      }
    }, () => {
    });
  }
  private updateStreamDetailsVisibility(productionTypeIdSelected: number) {
    this.viewModel.showStreamDetails$.next(productionTypeIdSelected === ProductionType.PremiumId);
  }
}

