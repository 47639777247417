import {Image} from '../image/dto/image';
import {DeserializeHelper} from '../protocols/deserializable';
import {Event} from './event';
import {HydratedTeam} from './hydrated-team';
import {HydratedVenue} from './hydrated-venue';
import {BannerAdvertisement} from './banner-advertisement';

export class HydratedEvent extends Event {
  eventLogos: Image[];
  bannerImageResource: Image[];
  associatedVenues: HydratedVenue[];
  associatedTeams: HydratedTeam[];
  advertisementBanners: BannerAdvertisement[];
  vodLinkRequired: boolean = false;
  clientEmail1: string;
  clientEmail2: string;
  clientEmail3: string;

  onDeserialize() {
    super.onDeserialize();
    this.associatedTeams = DeserializeHelper.deserializeArray(HydratedTeam, this.associatedTeams ?? []);
    this.associatedVenues = DeserializeHelper.deserializeArray(HydratedVenue, this.associatedVenues ?? []);
    this.eventLogos = DeserializeHelper.deserializeArray(Image, this.eventLogos);
    this.bannerImageResource = DeserializeHelper.deserializeArray(Image, this.bannerImageResource);
  }
}


