import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket$: WebSocketSubject<any> | null = null;
  private isConnected$ = new BehaviorSubject<boolean>(false);
  private messages$ = new Subject<any>();
  private socketUrl: string | null = null;
  private reconnectTimeout: any;
  private manualDisconnect = false;
  constructor() {}

  connect(socketUrl: string): void {
    if (!socketUrl || (this.socket$ && !this.socket$.closed)) {
      return;
    }
    this.manualDisconnect = false;
    this.socketUrl = socketUrl;
    this.socket$ = webSocket(socketUrl);
    this.socket$.subscribe({
      next: (message) => this.messages$.next(message),
      error: () => this.reconnect(),
      complete: () => this.reconnect(),
    });
    this.isConnected$.next(true);
  }

  private reconnect(): void {
    if (this.manualDisconnect) {return;}
    this.isConnected$.next(false);
    if (this.socketUrl) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = setTimeout(() => {
        this.connect(this.socketUrl!);
      }, 5000);
    }
  }

  disconnect(): void {
    if (this.socket$) {
      this.manualDisconnect = true;
      clearTimeout(this.reconnectTimeout);
      this.socket$.unsubscribe();
      this.socket$.complete();
      this.socket$ = null;
      this.isConnected$.next(false);
      this.resetMessagesSubject();
    }
  }

  sendMessage(message: any): void {
    if (this.socket$) {
      this.socket$.next(message);
    }
  }

  isWebSocketConnected(): Observable<boolean> {
    return this.isConnected$.asObservable();
  }

  getMessages(): Observable<any> {
    if (this.messages$.closed) {
      this.messages$ = new Subject<any>();
    }
    return this.messages$.asObservable();
  }


  private resetMessagesSubject(): void {
    this.messages$.complete();
    this.messages$ = new Subject<any>();
  }
}
