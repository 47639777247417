/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import {SessionService} from '../services/session-service';
import {BaseDomainModel} from '../models/base/base-domain-model';
import '../utils/observable.extensions';
import {ProgramApi} from '../api/program-api';
import {EMPTY, Observable, of} from 'rxjs';
import {ProgramStatusType} from '../models/lookup/program-status-type';
import {map, switchMap, tap} from 'rxjs/operators';
import {ImageApi} from '../api/image-api';
import {ImageSize} from '../models/enum/dto/image-size.enum';
import {ProgramComment} from '../models/program/program-comment';
import {HydratedProgram} from '../models/program/hydrated-program';
import {AccountDomainModel} from './account-domain-model';
import {HydratedShow} from '../models/program/hydrated-show';
import {ContentQuery} from '../models/program/content-query';
import {Advertisement} from '../models/resources/advertisement';
import {DateUtils} from '../utils/date-utils';
import {ResourceDomainModel} from './resource-domain-model';
import { SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ProgramDomainModel extends BaseDomainModel {

  private lastAdCompletedTimeStamp: number = 0;
  private programIdsViewedList: string[] = [];
  private readonly adTimeoutSeconds = 60;

  constructor(
    private sessionService: SessionService,
    private accountDomainModel: AccountDomainModel,
    private resourceDomainModal: ResourceDomainModel,
    private programApi: ProgramApi,
    private imageApi: ImageApi,
  ) {
    super();
    this.init();
  }

  public init() {
  }

  getProgram(programId: string): Observable<HydratedProgram> {
    return this.programApi.getHydratedProgram(programId)
      .pipe(tap(p => this.loadImagesForProgram(p, ImageSize.Medium)));
  }

  getShow(showId: string): Observable<HydratedShow> {
    return this.programApi.getHydratedShow(showId)
      .pipe(tap(p => p.imgSrc$ = this.imageApi.getHydratedShowImage(p, ImageSize.Medium)));
  }

  getShowsPage(contentQuery: ContentQuery): Observable<HydratedShow[]> {
    return this.programApi.getHydratedShowsPage(contentQuery).pipe(
      tap(programs => programs.forEach(p => p.imgSrc$ = this.imageApi.getHydratedShowImage(p, ImageSize.Medium)))
    );
  }


  getLiveProgramsPage(contentQuery: ContentQuery): Observable<HydratedProgram[]> {
    const isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));
    return isSignedIn$.pipe(
      switchMap(isSignedIn => {
        if (isSignedIn) {
          return this.accountDomainModel.subscriberSubscriptions$.pipe(
            switchMap(subscriptionPlans => {
              const privatePlansExist = subscriptionPlans.some(plan =>
                plan.planId.startsWith('Private-'));

              if (privatePlansExist) {
                return this.programApi.getHydratedProgramsPage(ProgramStatusType.InProgressId, contentQuery).pipe(
                  tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p)))
                );
              } else {
                return this.programApi.getHydratedProgramsPage(ProgramStatusType.InProgressId, contentQuery).pipe(
                  tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p))),
                  map(programs => programs.filter(p => !p.isPrivateAccess))
                );
              }
            })
          );
        } else {
          return this.programApi.getHydratedProgramsPage(ProgramStatusType.InProgressId, contentQuery).pipe(
            tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p))),
            map(programs => programs.filter(p => !p.isPrivateAccess))
          );
        }
      })
    );

  }



  getPastProgramsPage(contentQuery: ContentQuery): Observable<HydratedProgram[]> {
    const isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));

    return isSignedIn$.pipe(
      switchMap(isSignedIn => {
        if (isSignedIn) {
          return this.accountDomainModel.subscriberSubscriptions$.pipe(
            switchMap(subscriptionPlans => {
              const privatePlansExist = subscriptionPlans.some(plan =>
                plan.planId.startsWith('Private-'));

              if (privatePlansExist) {
                return this.programApi.getHydratedProgramsPage(ProgramStatusType.PastId, contentQuery).pipe(
                  tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p)))
                );
              } else {
                return this.programApi.getHydratedProgramsPage(ProgramStatusType.PastId, contentQuery).pipe(
                  tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p))),
                  map(programs => programs.filter(p => !p.isPrivateAccess))
                );
              }
            })
          );
        } else {
          return this.programApi.getHydratedProgramsPage(ProgramStatusType.PastId, contentQuery).pipe(
            tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p))),
            map(programs => programs.filter(p => !p.isPrivateAccess))
          );
        }
      })
    );
  }



  getUpcomingProgramsPage(contentQuery: ContentQuery): Observable<HydratedProgram[]> {
    const isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));
    return isSignedIn$.pipe(
      switchMap(isSignedIn => {
        if (isSignedIn) {
          return this.accountDomainModel.subscriberSubscriptions$.pipe(
            switchMap(subscriptionPlans => {
              const privatePlansExist = subscriptionPlans.some(plan =>
                plan.planId.startsWith('Private-'));

              if (privatePlansExist) {
                return this.programApi.getHydratedProgramsPage(ProgramStatusType.ScheduledId, contentQuery).pipe(
                  tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p)))
                );
              } else {
                return this.programApi.getHydratedProgramsPage(ProgramStatusType.ScheduledId, contentQuery).pipe(
                  tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p))),
                  map(programs => programs.filter(p => !p.isPrivateAccess))
                );
              }
            })
          );
        } else {
          return this.programApi.getHydratedProgramsPage(ProgramStatusType.ScheduledId, contentQuery).pipe(
            tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p))),
            map(programs => programs.filter(p => !p.isPrivateAccess))
          );
        }
      })
    );
  }

  getPastPrograms(contentQuery: ContentQuery): Observable<HydratedProgram[]> {
    const isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));

    return isSignedIn$.pipe(
      switchMap(isSignedIn => {
        if (isSignedIn) {
          return this.accountDomainModel.subscriberSubscriptions$.pipe(
            switchMap(subscriptionPlans => {
              const privatePlansExist = subscriptionPlans.some(plan =>
                plan.planId.startsWith('Private-'));

              if (privatePlansExist) {
                return this.programApi.getHydratedProgramsRecursively(ProgramStatusType.PastId, contentQuery).pipe(
                  tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p)))
                );
              } else {
                return this.programApi.getHydratedProgramsRecursively(ProgramStatusType.PastId, contentQuery).pipe(
                  tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p))),
                  map(programs => programs.filter(p => !p.isPrivateAccess))
                );
              }
            })
          );
        } else {
          return this.programApi.getHydratedProgramsRecursively(ProgramStatusType.PastId, contentQuery).pipe(
            tap(programs => programs.forEach(p => this.loadImagesForProgramCards(p))),
            map(programs => programs.filter(p => !p.isPrivateAccess))
          );
        }
      })
    );


  }


  loadImagesForProgram(p: HydratedProgram, maxSize: ImageSize = ImageSize.Medium) {
    if (p.images?.length > 0) {
      p.imgSrc$ = this.imageApi.getHydratedProgramImage(p, maxSize);
    } else if (!!p?.homeTeamId && p.teamCardImages?.length > 0) {
      p.imgSrc$ = this.resourceDomainModal.activeHydratedTeams$.notNull().pipe(switchMap(teams => {
        const hydrateHomeTeam = teams.find(t => t.id === p.homeTeamId);
        const randomCardImageId = hydrateHomeTeam.cardImages.random()?.id;
        return !!randomCardImageId ? hydrateHomeTeam.cardImgSrcMap.get(randomCardImageId) : EMPTY;
      }));
    }
  }

  loadImagesForProgramCards(p: HydratedProgram, maxSize: ImageSize = ImageSize.Medium) {
    if (p.images?.length > 0) {
      p.imgSrc$ = this.imageApi.getHydratedProgramImage(p, maxSize);
    }
    else if(p.teamCardImages?.length >0 ){
      p.imgSrc$ = this.imageApi.getHydratedProgramCardImage(p, maxSize);
    }
    else if(p?.venueImages?.length>0){
      p.imgSrc$=this.imageApi.getHydratedProgramVenueImage(p,maxSize);
    }
}

loadImagesForCarousel(p: HydratedProgram, maxSize: ImageSize = ImageSize.Large):
Observable<string | SafeResourceUrl> {
  return this.imageApi.getHydratedProgramCardImage(p,maxSize);
}

  submitProgramComment(programId: string, programComment: ProgramComment): Observable<ProgramComment> {
    return this.programApi.submitProgramComment(programId, programComment);
  }

  submitShowComment(showId: string, comment: ProgramComment): Observable<ProgramComment> {
    return this.programApi.submitShowComment(showId, comment);
  }

  getSingleHydratedProgramOrShow(contentQuery: ContentQuery): Observable<HydratedProgram|HydratedShow> {
    const live$ = this.programApi.getSingleHydratedProgram(ProgramStatusType.InProgressId, contentQuery);
    const upcoming$ = this.programApi.getSingleHydratedProgram(ProgramStatusType.ScheduledId, contentQuery);
    const past$ = this.programApi.getSingleHydratedProgram(ProgramStatusType.PastId, contentQuery);
    const shows$ = this.programApi.getSingleHydratedShow(contentQuery);
    return live$.pipe(
      switchMap(p => p?.length > 0 ? of(p) : upcoming$),
      switchMap(p => p?.length > 0 ? of(p) : past$),
      switchMap(p => p?.length > 0 ? of(p) : shows$),
      map(programs => programs?.find(p => !!p)),
      tap(p => {
        if (!!p) {
          if (p instanceof HydratedProgram) {
            p.imgSrc$ = this.imageApi.getHydratedProgramImage(p, ImageSize.Medium);
          } else if (p instanceof HydratedShow) {
            p.imgSrc$ = this.imageApi.getHydratedShowImage(p, ImageSize.Medium);
          }
        }
      })
    );
  }

  getFeaturedHydratedPrograms(contentQuery: ContentQuery): Observable<HydratedProgram[]> {
    const featuredProgramsArray: HydratedProgram[] = [];
    contentQuery.take = 10;

    const live$ = this.programApi.getHydratedProgramsPage(ProgramStatusType.InProgressId, contentQuery);
    const upcoming$ = this.programApi.getHydratedProgramsPage(ProgramStatusType.ScheduledId, contentQuery);
    const past$ = this.programApi.getHydratedProgramsPage(ProgramStatusType.PastId, contentQuery);
    return live$.pipe(
      switchMap(programs => {
          featuredProgramsArray.push(...programs);
          if (featuredProgramsArray?.length >= 10) {
            return of(featuredProgramsArray);
          } else {
            return upcoming$;
          }
        }
      ),
      switchMap(programs => {
          if (featuredProgramsArray.length < 10) {
            featuredProgramsArray.push(...programs);
          }
          if (featuredProgramsArray?.length >= 10) {
            return of(featuredProgramsArray);
          } else {
            return past$;
          }
        }
      ),
      map(programs => {
        if (featuredProgramsArray.length < 10) {
          featuredProgramsArray.push(...programs);
        }
        return featuredProgramsArray.slice(0, 10);
      }),
      tap(programs => {
        programs.forEach(p => p.imgSrc$ = this.imageApi.getHydratedProgramImage(p, ImageSize.Medium));
      })
    );
  }


  private shouldFetchAd(): boolean {
    const currentTimeStamp = DateUtils.currentTimestamp();
    return (currentTimeStamp - this.adTimeoutSeconds) > this.lastAdCompletedTimeStamp;
  }

  getAdvertisementForProgramOrShowForPreRoll(programId: string, isShow: boolean): Observable<Advertisement> {
    if (!this.shouldFetchAd()) {
      return of(null);
    }
    console.log(programId);
    const fetchAd$ = isShow
      ? this.programApi.getPreRollAdvertisementsForShow(programId)
      : this.programApi.getPreRollAdvertisementsForProgram(programId);
    return fetchAd$.pipe(
      map(ads => ads?.find(a => !!a.amazonPlaybackStreamUrl && !!a.active))
    );
  }

  trackAdvertisementPlayed(adId: string): Observable<any> {
    this.lastAdCompletedTimeStamp = DateUtils.currentTimestamp();
    return this.programApi.trackAdvertisementPlayed(adId);
  }

  trackProgramsIncrementPlayCount(programId: string): void {
    if (!this.programIdsViewedList.includes(programId)) {
      this.programIdsViewedList.push(programId);
      this.programApi.trackProgramsIncrementPlayCount(programId).subscribe(() => {});
    }
  }

  trackShowsIncrementPlayCount(showId: string): void {
    if (!this.programIdsViewedList.includes(showId)) {
      this.programIdsViewedList.push(showId);
      this.programApi.trackShowIncrementPlayCount(showId).subscribe(() => {});
    }
  }
}
