import { EventStream } from './../../../../models/resources/event-stream';
import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {catchError, map, shareReplay, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject} from 'rxjs';
import {HydratedEvent} from '../../../../models/resources/hydrated-event';
import {indicate, indicateOnNext} from '../../../../utils/observable.extensions';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ToastService} from '../../../../services/toast-service';
import {ImageApi} from '../../../../api/image-api';
import {EventFormObject} from '../../../../models/resources/event-form-object';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ImageSize} from '../../../../models/enum/dto/image-size.enum';
import {HydratedProgram} from '../../../../models/program/hydrated-program';
import {AdminProgramDomainModel} from '../../../../domainModels/admin-program-domain-model';
import {HydratedShow} from '../../../../models/program/hydrated-show';
import {TeamFormObject} from '../../../../models/resources/team-form-object';
import {Team} from '../../../../models/resources/team';
import {VenueId} from '../../../../models/resources/venue-id';
import {TeamId} from '../../../../models/resources/teamId';
import {Venue} from '../../../../models/resources/venue';
import {BannerAdvertisementFormObject} from '../../../../models/resources/banner-ad-form-object';
import {BannerAdvertisement} from '../../../../models/resources/banner-advertisement';
import { Program } from 'src/app/models/program/program';
import { contributor } from 'src/app/models/resources/contributor';
import { CreateContributorRequest } from 'src/app/models/account/requests/create-contributor-request';

@Injectable()
export class EventDetailsViewModel extends BaseViewModel {


  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  programLoadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  eventId$ = this.activatedRoute.params.pipe(map(p => p.eventId));
  addNewEvent$ = this.activatedRoute.data.pipe(map(d => d.addEvent as boolean));
  refreshEventSubject$ = new BehaviorSubject<void>(null);
  refresheventStream$ = new BehaviorSubject<void>(null);

  eventStream$: Observable<EventStream> = combineLatest([this.eventId$, this.refresheventStream$]).pipe(
    switchMap(([eventId, _]) => {
      return this.domainModel.getEventStream(eventId).pipe(
        indicate(this.loadingOpts, $localize`Loading Streams`)
      );
    }),
    catchError(() => {
      return EMPTY;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  event$ = combineLatest([this.eventId$, this.addNewEvent$, this.refreshEventSubject$]).pipe(
    switchMap(([t, addNew, ]) => {
      if (!!addNew) {
        const newEvent = new HydratedEvent();
        newEvent.clientEmail1 = '';
        newEvent.clientEmail2 = '';
        newEvent.clientEmail3 = '';
        return of(newEvent);
      } else {
        //this.eventStream$ = this.domainModel.getEventStream(t);
        return this.domainModel.getHydratedEvent(t).pipe(
          map(event => {
            event.clientEmail1 = event.clientEmail1 || '';
            event.clientEmail2 = event.clientEmail2 || '';
            event.clientEmail3 = event.clientEmail3 || '';
            return event;
          }),
          indicate(this.loadingOpts, $localize`Loading Event Details`)
        );
      }
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  subscriptionPlans$ = this.accountDomainModel.getSubscriptionPlans().pipe(
    indicateOnNext(this.loadingOpts, $localize`Loading Subscription Plans`),
    shareReplay({bufferSize: 1, refCount: true})
  );

  refreshEventProgramsAndShows$ = new BehaviorSubject<void>(null);
  eventPrograms$: Observable<HydratedProgram[]> = this.refreshEventProgramsAndShows$.pipe(
    switchMapTo(this.eventId$),
    switchMap(eventId => this.adminProgramDomainModel.getEventHydratedPrograms(eventId)
      .pipe(indicate(this.programLoadingOpts, $localize`Loading Programs`))),
    catchError(e => this.toastService.publishAndThrowError(e, $localize`Load Programs Failed`)),
  );

  eventShows$: Observable<HydratedShow[]> = this.refreshEventProgramsAndShows$.pipe(
    switchMapTo(this.eventId$),
    switchMap(eventId => this.adminProgramDomainModel.getEventHydratedShows(eventId)
      .pipe(indicate(this.programLoadingOpts, $localize`Loading Shows`))),
    catchError(e => this.toastService.publishAndThrowError(e, $localize`Load Shows Failed`)),
  );

  refreshEventContributor$ = new BehaviorSubject<void>(null);
  eventContributor$: Observable<contributor[]>=this.refreshEventContributor$.pipe(
    switchMapTo(this.eventId$),
    switchMap(eventId => this.domainModel.getEventContributors(eventId)
      .pipe(indicate(this.loadingOpts, $localize`Loading Contributors`))),
    catchError(e => this.toastService.publishAndThrowError(e, $localize`Load Contributors Failed`)),
  );


  updateFormItemStatesSubject$ = new Subject<void>();
  selectedFeedbackProgram: HydratedProgram;
  selectedFeedbackShow: HydratedShow;

  constructor(private domainModel: ResourceDomainModel,
              private lookupDomainModel: LookupDomainModel,
              private adminProgramDomainModel: AdminProgramDomainModel,
              private accountDomainModel: AccountDomainModel,
              private toastService: ToastService,
              private imageApi: ImageApi,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  saveEvent(formObject: EventFormObject) {
    formObject.event.eventStartDate = formObject.eventDate ? new Date(formObject.eventDate) : null;
    this.domainModel.saveEvent(formObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Event`))
      .subscribe(() => {
        if (!!formObject.event.id) {
          this.toastService.publishSuccessMessage($localize`Event Updated`, null);
          this.refreshEventSubject$.next();
        } else {
          this.toastService.publishSuccessMessage($localize`Event Created`, null);
          this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
        }
      }, error => {
        this.toastService.publishError(error);
      });
  }

  saveEventStream(eventStream: EventStream): Observable<EventStream> {
    return this.domainModel.saveEventStream(eventStream).pipe(
      tap(() => {
        this.toastService.publishSuccessMessage($localize`Stream Updated`, null);
        this.refresheventStream$.next();
      }),
      catchError((error) => {
        this.toastService.publishError(error);
        throw error;  // Re-throw the error so the caller can handle it
      })
    );
  }

  getLogo(eventFormObject: EventFormObject): Observable<string | SafeResourceUrl> {
    if (eventFormObject.imageToUpload) {
      return of(eventFormObject.imageToUpload);
    } else if (eventFormObject.existingImageId) {
      return this.imageApi.getHydratedEventImage(eventFormObject.event, ImageSize.Original, true);
    } else {
      return of(null);
    }
  }

  saveEventTeam(team: TeamFormObject): Observable<Team> {
    return this.event$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveEventTeam(team, l.id)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Event Team`));
  }

  saveEventTeamAssociation(team: Team): Observable<TeamId> {
    return this.event$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveEventTeamAssociation(team, l.id)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Event Team`));
  }

  saveEventVenueAssociation(venue: Venue): Observable<VenueId> {
    return this.event$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveEventVenueAssociation(venue, l.id)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Venue Stream`));
  }

  saveEventBannerAdvertisement(bannerAdFormObject: BannerAdvertisementFormObject): Observable<BannerAdvertisement> {
    return this.event$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveEventBannerAdvertisement(l.id, bannerAdFormObject)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Event Banner Advertisement`));
  }

  updateProgramMp4(program: Program) {
    return this.adminProgramDomainModel.updateLeagueProrgam(program,program.eventId)
            .subscribe(()=>{
              this.refreshEventProgramsAndShows$.next();
    },error => {
      this.toastService.publishError(error);
    });
  }

  getContributorById(contributorId: number): Observable<CreateContributorRequest> {
    return this.eventId$.pipe(
      switchMap(id => {
        return this.domainModel.getEventContributorsById(id, contributorId);
      })
    );
  }

  saveContributor(req: CreateContributorRequest): Observable<any>{
    return this.eventId$.pipe(
  switchMap(id => {
    return this.domainModel.saveEventContributor(id, req).pipe(
      map(c => {
        c.eventId = id;
        return c;
      })
    );
  })
);

  }

  updateContributor(req: CreateContributorRequest): Observable<any>{
    return this.eventId$.pipe(
      switchMap(id => {
        return this.domainModel.updateEventContributor(id, req).pipe(
          map(c => {
            c.eventId = id;
            return c;
          })
        );
      })
    );

  }
}
