<app-content-container class="watch-container" [hidden]="viewModel.noProgramFound$|async">
  <app-loading
    class="row-loading"
    [options]="viewModel.loadingOpts"
    [hidden]="!(viewModel.loadingOpts?.isLoading)">
  </app-loading>

  <div *ngIf="(isEvent)">
    <div id="leaderboard_events_direct"></div>
  </div>

  <div *ngIf="(isLeague)">
    <div id="leaderboard_leagues_direct"></div>
  </div>

  <div class="row m-0">
    <div class="col-12 col-lg-8 video-player-container mb-3"
         id="video-player">
      <ng-container *ngIf="!(viewModel.leagueOrEventIsFree$|async) && !(viewModel.isUserSubscribedAndAddAdditonalParams$|async); else videoUnavailable">
        <div class="subscription-expired-curtain">
          <div class="subscription-expired-content">
            <div class="title-md mb-3" style="font-size: 2.75rem;" i18n>Subscribe to Watch</div>
            <div class="paragraph-md" i18n>
              Access premium broadcasts with league subscriptions.
              Choose how you want to use HTN HomeTeam Network with our variety of subscription options.
            </div>
            <div class="custom-button secondary-button mr-auto mt-3" (click)="subscribeClicked()" i18n>Subscribe Now
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #videoUnavailable>
        <ng-container *ngIf="(viewModel.isVideoUnavailable$|async); else videoCountDown">
          <div class="subscription-expired-curtain">
            <div class="subscription-expired-content">
              <div class="title-md mb-3" i18n>Video processing</div>
              <div class="paragraph-md" i18n>
                Video on demand files are being processed. Please check back later.
              </div>
              <div class="custom-button secondary-button mr-auto mt-3" (click)="viewModel.returnHome()" i18n>Return
                Home
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #videoCountDown>
        <ng-container *ngIf="(viewModel.showCountDown$|async); else videoPlayer">
          <div class="subscription-expired-curtain">
            <div class="video-countdown-curtain">
              <img [src]="'assets/img/watch-upcoming.png'" alt="">
              <div class="curtain-overlay"></div>
              <div class="countdown-container">
                <div class="countdown-unit">
                  <div class="countdown-value">{{viewModel.countDownDays$|async}}</div>
                  <div class="countdown-label" i18n>Days</div>
                </div>
                <div class="countdown-unit">
                  <div class="countdown-value">{{viewModel.countDownHours$|async}}</div>
                  <div class="countdown-label" i18n>Hours</div>
                </div>
                <div class="countdown-unit">
                  <div class="countdown-value">{{viewModel.countDownMinutes$|async}}</div>
                  <div class="countdown-label" i18n>Minutes</div>
                </div>
                <div class="countdown-unit">
                  <div class="countdown-value">{{viewModel.countDownSeconds$|async}}</div>
                  <div class="countdown-label" i18n>Seconds</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #videoPlayer>
        <ng-container>
          <app-ivs-video-player
            (feedbackButtonClicked)="showFeedbackModal()"
            [isLive$]="viewModel.isStreamLive$"
            [playVideo]="playVideoEventEmitter"
            [program]="viewModel.programOrShow$|async"
            [advertisement]="viewModel.preRollAd$|async">
          </app-ivs-video-player>
        </ng-container>
      </ng-template>
    </div>
    <div class="col-12 col-lg-4 d-flex align-items-center justify-content-center mb-3">
      <div id="MREC_Video">
      </div>
    </div>
    <div class="col-12 marquee-container p-sm-0 p-lg-4">
      <app-feature-marquee
        [isLive]="(viewModel.showLiveBadge$|async)"
        [showMarqueeActions]="false"
        [program$]="viewModel.programOrShow$"
        (watchNowClicked)="watchNowClicked()"
        (startFromBeginningClicked)="startFromBeginningClicked()"
        [customClass]="'light-background'">
      </app-feature-marquee>
    </div>
  </div>
  <hr *ngIf="showBottomDivider">
</app-content-container>
