<app-content-container
  *ngIf="(viewModel.isSignedIn$|async)"
  [unsetMinHeight]="true">
  <mat-tab-group class="mt-4"
                 dynamicHeight animationDuration="0ms"
                 (selectedIndexChange)="tabSelected($event)">
    <mat-tab *ngFor="let tab of viewModel.eventTabs$|async">
      <ng-template mat-tab-label>
        <ng-container>{{tab}}</ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</app-content-container>
<div *ngIf="(viewModel.isSignedIn$|async)
&& (viewModel.isSubscribedToEvent$|async)
&& ((viewModel.programContentQuery$|async)
    || (viewModel.programId$|async))">
      <app-program-player
      [programQuery]="viewModel.programContentQuery$|async"
      [programId]="viewModel.programId$|async"
      [isShow]="viewModel.isShow$|async"
      [showBottomDivider]="true"
      [smallVideoPlayer]="true"
      [isEvent]="true">
    </app-program-player>
  </div>
  <div  *ngIf="(viewModel.isSignedIn$|async)" >
    <div id="leaderboard_atf_events_members"></div>
  </div>
<app-featured-events-row
  [hideBottomDivider]="true"
  *ngIf="!(viewModel.isSignedIn$|async)">
</app-featured-events-row>
<app-sign-up-banner [eventLandingScreen]="true">
</app-sign-up-banner>
<app-no-content *ngIf="viewModel.showNoResults$|async" [type]="noContentType.Event"></app-no-content>
<app-event-subscribe-banner
  [hydratedEvent]="viewModel.selectedHydratedEvent$|async">
</app-event-subscribe-banner>
<app-program-row
  [navigateOnClick]="!(viewModel.isSignedIn$|async)"
  (handleCardClicked)="programCardClicked($event)"
  [programRowType]="programRowType.Live"
  [programContentQuery]="viewModel.contentQuery$|async"
  (noResults)="viewModel.liveProgramsNoResults.next($event)">
</app-program-row>
<app-program-row
  [navigateOnClick]="!(viewModel.isSignedIn$|async)"
  (handleCardClicked)="programCardClicked($event)"
  [programRowType]="programRowType.Upcoming"
  [programContentQuery]="viewModel.contentQuery$|async"
  (noResults)="viewModel.upcomingProgramsNoResults.next($event)">
</app-program-row>
<app-program-row
  [navigateOnClick]="!(viewModel.isSignedIn$|async)"
  (handleCardClicked)="programCardClicked($event)"
  [programRowType]="programRowType.Past"
  [seeAllGamesClicked]="seeAllPastGames"
  [programContentQuery]="viewModel.contentQuery$|async"
  (noResults)="viewModel.pastProgramsNoResults.next($event)">
</app-program-row>
<app-program-row
  [navigateOnClick]="!(viewModel.isSignedIn$|async)"
  (handleCardClicked)="programCardClicked($event)"
  [programRowType]="programRowType.Shows"
  [programContentQuery]="viewModel.contentQuery$|async"
  (noResults)="viewModel.showsNoResults.next($event)">
</app-program-row>
