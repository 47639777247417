import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {catchError, map, shareReplay, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {BehaviorSubject, combineLatest, Observable, of, Subject, throwError} from 'rxjs';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import {indicate, indicateOnNext} from '../../../../utils/observable.extensions';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ToastService} from '../../../../services/toast-service';
import {ImageApi} from '../../../../api/image-api';
import {LeagueFormObject} from '../../../../models/resources/league-form-object';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ImageSize} from '../../../../models/enum/dto/image-size.enum';
import {HydratedProgram} from '../../../../models/program/hydrated-program';
import {AdminProgramDomainModel} from '../../../../domainModels/admin-program-domain-model';
import {HydratedShow} from '../../../../models/program/hydrated-show';
import {TeamFormObject} from '../../../../models/resources/team-form-object';
import {Team} from '../../../../models/resources/team';
import {VenueId} from '../../../../models/resources/venue-id';
import {TeamId} from '../../../../models/resources/teamId';
import {Venue} from '../../../../models/resources/venue';
import {BannerAdvertisementFormObject} from '../../../../models/resources/banner-ad-form-object';
import {BannerAdvertisement} from '../../../../models/resources/banner-advertisement';
import {Season} from '../../../../models/resources/season';
import {SeasonFormObject} from '../../../../models/resources/season-form-object';
import {SeasonId} from '../../../../models/resources/seasonId';
import { LeagueStream } from 'src/app/models/resources/league-stream';
import { Program } from 'src/app/models/program/program';
import * as moment from 'moment-timezone';
import { ProgramFormObject } from 'src/app/models/program/program-form-object';
import { contributor } from 'src/app/models/resources/contributor';
import { CreateContributorRequest } from 'src/app/models/account/requests/create-contributor-request';
import { Ngb } from 'src/app/models/account/dto/Ngb';
import { CreateQrCodeRequest } from 'src/app/models/account/requests/create-qrCode-request';
import { qrCode } from 'src/app/models/resources/qrCode';

@Injectable()
export class LeagueDetailsViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  programLoadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  leagueId$ = this.activatedRoute.params.pipe(map(p => p.leagueId));
  addNewLeague$ = this.activatedRoute.data.pipe(map(d => d.addLeague as boolean));
  refreshLeagueSubject$ = new BehaviorSubject<void>(null);
  NgbValues$=this.lookupDomainModel.ngbValues;
  leagueStream$: Observable<LeagueStream>;
  league$ = combineLatest([this.leagueId$, this.addNewLeague$, this.refreshLeagueSubject$]).pipe(
    switchMap(([t, addNew, ]) => {
      if (!!addNew) {
        const newLeague = new HydratedLeague();
        newLeague.clientEmail1 = '';
        newLeague.clientEmail2 = '';
        newLeague.clientEmail3 = '';
        return of(newLeague);
      } else {
        this.leagueStream$ = this.domainModel.getLeagueStream(t);
        return this.domainModel.getHydratedLeague(t).pipe(
          map(league => {
            league.clientEmail1 = league.clientEmail1 || '';
            league.clientEmail2 = league.clientEmail2 || '';
            league.clientEmail3 = league.clientEmail3 || '';
            return league;
          }),
          indicate(this.loadingOpts, $localize`Loading League Details`)
        );
      }
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  subscriptionPlans$ = this.accountDomainModel.getSubscriptionPlans().pipe(
    indicateOnNext(this.loadingOpts, $localize`Loading Subscription Plans`),
    shareReplay({bufferSize: 1, refCount: true})
  );

  refreshLeagueProgramsAndShows$ = new BehaviorSubject<void>(null);

  refreshNgbValues$= new BehaviorSubject<void>(null);

  leaguePrograms$: Observable<HydratedProgram[]> = this.refreshLeagueProgramsAndShows$.pipe(
    switchMapTo(this.leagueId$),
    switchMap(leagueId => this.adminProgramDomainModel.getLeagueHydratedPrograms(leagueId)
      .pipe(indicate(this.programLoadingOpts, $localize`Loading Programs`))),
    catchError(e => this.toastService.publishAndThrowError(e, $localize`Load Programs Failed`)),
  );

  leagueShows$: Observable<HydratedShow[]> = this.refreshLeagueProgramsAndShows$.pipe(
    switchMapTo(this.leagueId$),
    switchMap(leagueId => this.adminProgramDomainModel.getLeagueHydratedShows(leagueId)
      .pipe(indicate(this.programLoadingOpts, $localize`Loading Shows`))),
    catchError(e => this.toastService.publishAndThrowError(e, $localize`Load Shows Failed`)),
  );


  refreshLeagueContributor$ = new BehaviorSubject<void>(null);
  refreshLeagueContributor1$ = new BehaviorSubject<void>(null);

  leagueContributor$: Observable<contributor[]> = combineLatest([this.leagueId$, this.refreshLeagueContributor$]).pipe(
    switchMap(([leagueId, _]) =>
      this.domainModel.getLeagueContributors(leagueId).pipe(
        indicate(this.loadingOpts, $localize`Loading Contributors`)
      )
    ),
    catchError(e => this.toastService.publishAndThrowError(e, $localize`Load Contributors Failed`)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  refreshLeagueQrCode$ = new BehaviorSubject<void>(null);

  leagueQrCode$: Observable<qrCode[]> = combineLatest([this.leagueId$, this.refreshLeagueQrCode$]).pipe(
    switchMap(([leagueId, _]) =>
      this.domainModel.getLeaguesQrCodes(leagueId).pipe(
        indicate(this.loadingOpts, $localize`Loading Qr Codes`)
      )
    ),
    catchError(e => this.toastService.publishAndThrowError(e, $localize`Load Qr Codes Failed`)),
    shareReplay({ bufferSize: 1, refCount: true })
  );




  updateFormItemStatesSubject$ = new Subject<void>();
  selectedFeedbackProgram: HydratedProgram;
  selectedFeedbackShow: HydratedShow;

  constructor(private domainModel: ResourceDomainModel,
              private lookupDomainModel: LookupDomainModel,
              private adminProgramDomainModel: AdminProgramDomainModel,
              private accountDomainModel: AccountDomainModel,
              private toastService: ToastService,
              private imageApi: ImageApi,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  saveLeague(formObject: LeagueFormObject) {
    this.domainModel.saveLeague(formObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving League`))
      .subscribe(() => {
        if (!!formObject.league.id) {
          this.toastService.publishSuccessMessage($localize`League Updated`, null);
          this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
        } else {
          this.toastService.publishSuccessMessage($localize`League Created`, null);
          this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
        }
      }, error => {
        this.toastService.publishError(error);
      });
  }



  saveLeagueStream(formObject: LeagueStream): Observable<LeagueStream> {
    return this.domainModel.saveLeagueStream(formObject).pipe(
      tap(() => {
        this.toastService.publishSuccessMessage($localize`Stream Updated`, null);
      }),
      catchError((error) => {
        this.toastService.publishError(error);
        throw error;
      })
    );
  }



  getLogo(leagueFormObject: LeagueFormObject): Observable<string | SafeResourceUrl> {
    if (leagueFormObject.imageToUpload) {
      return of(leagueFormObject.imageToUpload);
    } else if (leagueFormObject.existingImageId) {
      return this.imageApi.getHydratedLeagueImage(leagueFormObject.league, ImageSize.Original, true);
    } else {
      return of(null);
    }
  }

  saveLeagueTeam(team: TeamFormObject): Observable<Team> {
    return this.league$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveLeagueTeam(team, l.id)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving League Team`));
  }

  saveLeagueTeamAssociation(team: Team): Observable<TeamId> {
    return this.league$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveLeagueTeamAssociation(team, l.id)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving League Team`));
  }

  saveLeagueVenueAssociation(venue: Venue): Observable<VenueId> {
    return this.league$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveLeagueVenueAssociation(venue, l.id)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Venue Stream`));
  }

  saveLeagueBannerAdvertisement(bannerAdFormObject: BannerAdvertisementFormObject): Observable<BannerAdvertisement> {
    return this.league$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveLeagueBannerAdvertisement(l.id, bannerAdFormObject)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving League Banner Advertisement`));
  }

  saveLeagueSeason(formObject: SeasonFormObject): Observable<Season> {
    if (moment(formObject.seasonStartDate).year() < moment().year()) {
      return throwError('The season start date cannot be in the past.');
    }
    if (!/^[a-zA-Z0-9\s]+$/.test(formObject.seasonName)) {
      return throwError('The season name cannot contain special characters.');
    }
    if (formObject.seasonName.length > 128) {
      return throwError('The season name cannot be longer than 128 characters.');
    }
    if (formObject.seasonStartDate && formObject.seasonEndDate) {
      if (moment(formObject.seasonEndDate).toDate() < moment(formObject.seasonStartDate).toDate()) {
        return throwError('The season end date cannot be before the season start date.');
      }
    }
    return this.domainModel.saveLeagueSeason(formObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Season`));
  }

  deleteLeagueSeason(formObject: SeasonFormObject, leagueId: number) {
    return this.domainModel.deleteLeagueSeason(formObject, leagueId).subscribe(() => {
      this.refreshLeagueSubject$.next();
      }
    );
  }

  updateProgramMp4(program: Program){
    return this.adminProgramDomainModel.updateLeagueProrgam(program,program.leagueId)
            .subscribe(()=>{
              this.refreshLeagueProgramsAndShows$.next();
            },error => {
              this.toastService.publishError(error);
            });
  }

  getContributorById(contributorId: number): Observable<CreateContributorRequest> {
    return this.leagueId$.pipe(
      switchMap(id => {
        return this.domainModel.getLeagueContributorsById(id, contributorId);
      })
    );
  }

  saveContributor(req: CreateContributorRequest): Observable<contributor>{
      return this.leagueId$.pipe(
      switchMap(id => {
        return this.domainModel.saveContributor(id, req).pipe(
          map(c => {
            c.leagueId = id;
            return c;
          })
        );
      })
    );
  }

  updateContributor(req: CreateContributorRequest): Observable<any>{
    return this.leagueId$.pipe(
      switchMap(id => {
        return this.domainModel.updateContributor(id, req).pipe(
          map(c => {
            c.leagueId = id;
            return c;
          })
        );
      })
    );
  }

  saveQrCode(req: CreateQrCodeRequest): Observable<any>{
    return this.leagueId$.pipe(
      switchMap(id => {
        return this.domainModel.saveLeagueQrCode(id, req).pipe(
          map(c => {
            c.leagueId = id;
            return c;
          })
        );
      })
    );
  }

  getQrCodeById(qrCodeId: string): Observable<CreateQrCodeRequest> {
    return this.leagueId$.pipe(
      switchMap(id => {
        return this.domainModel.getQrCodeById(qrCodeId);
      })
    );
  }

  downloadQrCode(qrCodeId: string){
    return this.leagueId$.pipe(
      switchMap(id => {
        return this.domainModel.downloadQrCode(qrCodeId);
      })
    );
  }

}
