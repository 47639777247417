import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {EventFormObject} from '../../../../../models/resources/event-form-object';
import {ToastService} from '../../../../../services/toast-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {SubscriptionPlan} from '../../../../../models/account/dto/subscription-plan';
import {map, switchMap, take} from 'rxjs/operators';
import {UploadImageModalComponent} from '../../../../shared/components/upload-image-modal/upload-image-modal.component';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {ConfirmationModalComponent} from '../../../../shared/components/confirmation-modal/confirmation-modal.component';
import {ConfirmationOptions} from '../../../../../models/shared/stylesheet/confirmation-options';
import {EventDetailsViewModel} from '../event-details-view-model';
import {AlphanumericValidatorDirective} from 'src/app/views/shared/components/form-group/validators/alphanumeric-validator.directive';
import {PastDateValidatorDirective} from '../../../../shared/components/form-group/validators/past-date-validator.directive';
import {
  AlphanumericWithSpaceValidatorDirective
} from '../../../../shared/components/form-group/validators/alphanumeric-with-space-validator.directive';
import { UploadImageInterface } from 'src/app/views/shared/components/upload-asset/upload-image-interface';
import { CustomFile } from 'src/app/models/shared/custom-file';
import { Ngb } from 'src/app/models/account/dto/Ngb';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss'],
})
export class EditEventComponent extends BaseComponent implements UploadImageInterface{

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: EventFormObject;
  public updatedFormObject = new EventEmitter<void>();
  public hydrateInputObject = new EventEmitter<void>();
  ngb: Ngb[];

  constructor(
    public viewModel: EventDetailsViewModel,
    private toastService: ToastService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }


  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupBindings() {
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationStart) {
        this.viewModel.refreshEventSubject$.next();
      }
    });
    this.viewModel.event$.notNull().pipe(
      switchMap(event => {
        return this.viewModel.NgbValues$.notNull().pipe(
          map(ngb => ({ event, ngb }))
        );
      })
    ).subscribe(({ event, ngb }) => {
      setTimeout(() => {
        this.ngb = ngb;
        this.setupNgbToForm(this.ngb);
        this.formObject = EventFormObject.initWithEvent(event, this.ngb);
        this.viewModel.updateFormItemStatesSubject$.next();
      });
    }, error => {
      this.toastService.publishError(error);
    }).addTo(this.subscriptions);
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'd-flex flex-row-reverse justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.submitButtonText = $localize`Save Event`;
    this.formStyling.submitButtonClass =
      this.PermissionManagerService.isGranted(this.Types.AdminSections.Events_Edit_Event_Details_Edit) ?
        '' : 'preferred-button disabled';
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: EventFormObject) {
    result.event.subscriptionPlanId = this.formItems.find(f => f.inputName === 'subscriptionPlanId').getValue();
    const ngb=this.formItems.find(f => f.inputName === 'ngbSearch').getValue();
    if(ngb!=null){
      result.event.ngbId=ngb;
    }
    this.viewModel.saveEvent(result);
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const infoTitle = new FormInputItem();
    infoTitle.itemType = FormItemType.Title;
    infoTitle.label = $localize`Info`;
    items.push(infoTitle);

    const eventName = new FormInputItem();
    eventName.inputName = 'eventName';
    eventName.inputType = FormInputType.Text;
    eventName.label = $localize`Event Name`;
    eventName.placeholder = $localize`Event Name`;
    eventName.bindingProperty = 'event.name';
    eventName.customValidator = new AlphanumericWithSpaceValidatorDirective();
    eventName.required = true;
    items.push(eventName);

    const eventAbbreviation = new FormInputItem();
    eventAbbreviation.inputName = 'eventAbbreviation';
    eventAbbreviation.inputType = FormInputType.Text;
    eventAbbreviation.label = $localize`Event Abbreviation`;
    eventAbbreviation.placeholder = $localize`Event Abbreviation`;
    eventAbbreviation.bindingProperty = 'event.abbreviation';
    eventAbbreviation.required = true;
    eventAbbreviation.customValidator = new AlphanumericValidatorDirective();
    items.push(eventAbbreviation);

    if (this.activatedRoute.snapshot.params.eventId) {
      const eventStartDate = new FormInputItem();
      eventStartDate.inputName = 'eventStartDate';
      eventStartDate.inputType = FormInputType.Date;
      eventStartDate.label = $localize`Event Start Date`;
      eventStartDate.placeholder = $localize`Event Start Date`;
      eventStartDate.bindingProperty = 'eventDate';
      eventStartDate.required = true;
      items.push(eventStartDate);
    } else {
      const eventStartDate = new FormInputItem();
      eventStartDate.inputName = 'eventStartDate';
      eventStartDate.inputType = FormInputType.Date;
      eventStartDate.label = $localize`Event Start Date`;
      eventStartDate.placeholder = $localize`Event Start Date`;
      eventStartDate.bindingProperty = 'eventDate';
      eventStartDate.required = true;
      eventStartDate.customValidator = new PastDateValidatorDirective();
      items.push(eventStartDate);
    }

    const subscriptionPlan = new FormInputItem();
    subscriptionPlan.itemType = FormItemType.Dropdown;
    subscriptionPlan.inputName = 'subscriptionPlanId';
    subscriptionPlan.label = $localize`Subscription Plan`;
    subscriptionPlan.placeholder = $localize`Choose a Subscription Plan`;
    subscriptionPlan.bindingProperty = 'event.subscriptionPlanId';
    subscriptionPlan.dropdownIsObject = true;
    subscriptionPlan.required = true;
    subscriptionPlan.dropdownOptions = [];
    items.push(subscriptionPlan);

    const ngbSearch = new FormInputItem();
    ngbSearch.itemType = FormItemType.Dropdown;
    ngbSearch.inputName = 'ngbSearch';
    ngbSearch.label = $localize`NGB`;
    ngbSearch.placeholder = $localize`Choose a NGB`;
    ngbSearch.bindingProperty = 'event.ngbId';
    ngbSearch.dropdownIsObject = true;
    ngbSearch.required = false;
    ngbSearch.inlineLabel = true;
    ngbSearch.dropdownOptions = [];
    ngbSearch.hasInformation=true;
    ngbSearch.informationText=$localize`To Add NGB values, please contact DEV team`;
    items.push(ngbSearch);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'event.active';
    active.customClass = 'mb-4 mt-0';
    active.valueChanged.subscribe(v => {
      if (!v[0]) {
        this.openDeactivateEventModal();
      }
    }).addTo(this.subscriptions);
    items.push(active);


    const projectedContent = new FormInputItem();
    projectedContent.itemType = FormItemType.ProjectedContent;
    items.push(projectedContent);

    items.push(FormInputItem.generateDivider());

    this.formItems = items;
    this.setupFormBindings();
  }

  fileList(f: CustomFile[], id?: number) {
    if (f.length > 0) {
      this.formObject.bannerImageToUpload = f[0].url;
    } else {
      this.formObject.bannerImageToUpload = undefined;
    }
  }

  removeBannerImageClicked() {
    if (this.formObject.existingBannerImageId) {
      this.formObject.deleteBannerImageId = this.formObject.existingBannerImageId;
      this.formObject.existingBannerImageId = null;
    }
    this.formObject.bannerImageToUpload = null;
    }

  setupFormBindings() {
    this.viewModel.updateFormItemStatesSubject$.subscribe(() => {
      setTimeout(() => {
        this.updateFormItemStates();
      });
    }).addTo(this.subscriptions);

    this.viewModel.subscriptionPlans$.notNull().subscribe(s => {
      setTimeout(() => {
        this.setSubscriptionPlanOptions(s);
      });
    }).addTo(this.subscriptions);
  }

  cancel() {
    this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
  }

  setSubscriptionPlanOptions(subscriptionPlans: SubscriptionPlan[]) {
    setTimeout(() => {
      const subscriptionPlanInput = this.formItems.find(f => f.inputName === 'subscriptionPlanId');
      subscriptionPlanInput.dropdownOptions = subscriptionPlans.map(plan => {
        return {
          getSelectionTitle: () => plan.internalName,
          getSelectionValue: () => plan.id,
          getSelectionUniqueIdentifier: () => plan.id
        };
      });
    });
  }

setupNgbToForm(ngb: Ngb[]) {
      const ngbFormItem = this.formItems.find(f => f.inputName === 'ngbSearch');
      ngbFormItem.dropdownOptions = ngb.map(n => {
        return {
          getSelectionTitle: () => n.name,
          getSelectionValue: () => n.id,
          getSelectionUniqueIdentifier: () => n.id
        };
      });
    }

  updateFormItemStates() {
    if (this.formItems.length === 0) {
      return;
    }
  }

  showEditPhotoModal() {
    this.viewModel.getLogo(this.formObject).pipe(take(1)).subscribe((logo) => {
      const modalRef = this.modalService.open(UploadImageModalComponent, ModalUtils.defaultMedium());
      const compInstance = modalRef.componentInstance as UploadImageModalComponent;
      compInstance.initWith(
        $localize`Add Event Logo`,
        $localize`Crop Event Logo`,
        logo
      );
      modalRef.result.then((result) => {
        if (result) {
          this.formObject.imageToUpload = result;
        } else {
          this.removeLogo();
        }
      }, () => {
      });
    }).addTo(this.subscriptions);
  }

  removeLogo() {
    if (this.formObject.existingImageId) {
      this.formObject.deleteImageId = this.formObject.existingImageId;
      this.formObject.existingImageId = null;
    }
    this.formObject.imageToUpload = null;
  }

  openDeactivateEventModal(): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Deactivate Event`;
    // eslint-disable-next-line max-len
    opts.bodyText = $localize`Deactivating a event will limit actions that admins can take with that event. Are you sure you want to deactivate this event?\n\nThis action can be reversed from the Events tab. `;
    opts.cancelText = $localize`Cancel`;
    opts.continueText = $localize`Deactivate Event`;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((deactivate) => {
      if (!deactivate) {
        const activeInput = this.formItems.find(i => i.inputName === 'active');
        activeInput?.setInputFormControlValue(true);
      }
    });
  }
}
