import {HydratedEvent} from './hydrated-event';
import {SafeResourceUrl} from '@angular/platform-browser';
import {TeamFormObject} from './team-form-object';
import {BannerAdvertisementFormObject} from './banner-ad-form-object';
import * as moment from 'moment-timezone';
import {DateUtils} from '../../utils/date-utils';
import { EventStream } from './event-stream';
import { Ngb } from '../account/dto/Ngb';

export class EventFormObject {
  public event: HydratedEvent;
  public eventStream: EventStream;
  public imageToUpload: string | SafeResourceUrl;
  public bannerImageToUpload: string | SafeResourceUrl;
  public existingImageId: string;
  public deleteImageId: string;
  public teamFormObjects: TeamFormObject[];
  public bannerAdFormObjects: BannerAdvertisementFormObject[];
  public eventDate: string = null;

  public existingBannerImageId: string;
  public deleteBannerImageId: string;

  static initWithEvent(event: HydratedEvent,ngb: Ngb[]=[] ): EventFormObject {
    const formObject = new EventFormObject();
    formObject.event = event;
    if (event.eventStartDate) {
    formObject.eventDate = DateUtils.formatDateInputString(event.eventStartDate);
    }
    if (event.eventLogos?.length > 0) {
      formObject.existingImageId = event.eventLogos[0]?.id;
    }
    if(event.bannerImageResource?.length > 0) {
      formObject.existingBannerImageId = event.bannerImageResource[0]?.id;
    }
    formObject.teamFormObjects = event.associatedTeams
      ?.map(t => TeamFormObject.initWithTeam(t)) ?? [];
    formObject.bannerAdFormObjects = event.advertisementBanners
      ?.map(b => BannerAdvertisementFormObject.initWithBannerAdvertisement(b)) ?? [];
    if(ngb.length>0 && event.ngbId!=null){
        const associatedNgb=ngb.filter(n=>n.id===event.ngbId);
        event.ngb=associatedNgb[0].name;
        }
    return formObject;
  }

  static initWithStream(eventStream: EventStream): EventFormObject {
    const formObject = new EventFormObject();
    formObject.eventStream = eventStream;
    return formObject;
  }
}
