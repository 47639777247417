import {BaseModel} from '../base/base-model';
import {Deserializable} from '../protocols/deserializable';
import {Cardable} from '../protocols/cardable';
import {SafeResourceUrl} from '@angular/platform-browser';
import {EMPTY, Observable} from 'rxjs';
import {DateUtils} from '../../utils/date-utils';

export class Show extends BaseModel implements Deserializable, Cardable {

  public id: string;
  public name: string = null;
  public description: string = null;
  public subscriptionPlanId: string = null;
  public playbackStreamUrl: string = null;
  public leagueId: number = null;
  public league: string;
  public eventId: number = null;
  public event: string;
  public nextProgramId: string;
  public endDateTimeUtc: Date;


  public imgSrc$: Observable<SafeResourceUrl | string>;

  onDeserialize() {
    super.onDeserialize();
    this.description = this.description ?? null;
  }

  getCardCategory(): string {
    return this.league ?? this.event ?? '';
  }

  getCardShortDescription(): string {
    return '';
  }

  getCardImageSrc(): Observable<SafeResourceUrl | string> {
    return this.imgSrc$ ?? EMPTY;
  }

  getCardMetadata(): string {
    return this.description;
  }

  getCardDescription(): string {
    return null;
  }

  getCardTitle(): string {
    return this.name;
  }

  getCardAwayTeamId(): number {
    return null;
  }

  getCardHomeTeamId(): number {
    return null;
  }

  getGoogleAnalyticsEventTitle(): string {
    return `${this.getCardTitle()}`;
  }
}




