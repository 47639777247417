<div class="d-flex mt-3 h-100 min-height-15">
  <app-loading
  [hidden]="!viewModel.loadingOpts?.isLoading"
  [options]="viewModel.loadingOpts">
  </app-loading>
  <app-data-table
    [tableData]="dataProvider"
    [tableFilter]="datatableFilter"
    [resetTable]="resetTable"
    [updateTableData]="updateTableData"
    [filterApplied]="tableFilterChanged"
    [tableOptions]="datatableOptions">
  </app-data-table>
</div>
