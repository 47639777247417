<app-content-container
  [unsetMinHeight]="true">
  <div class="row my-5">
    <div class="col-8 col-lg-9">
      <div class="title-container justify-content-start align-center">
      <img class="league-logo-main" *ngIf="viewModel.hydratedLeagueLogo$|async" [src]="viewModel.hydratedLeagueLogo$|async" alt="">
      <div class="league-name-title  pt-0 ml-3">
        {{(viewModel.hydratedLeague$|async)?.name}}
      </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 d-flex justify-content-start justify-content-lg-end align-items-end pt-4 pt-lg-0">
      <button
        *ngIf="!(viewModel.isFreeLeague$|async) && !(viewModel.isSubscribedToLeague$|async)"
        class="custom-button preferred-button"
        (click)="subscribeBanner.subscribeClicked()">
        <img alt="" [src]="'assets/icons/light/outline/plus.svg'">
        <ng-container i18n>Subscribe</ng-container>
      </button>
      <button
        *ngIf="!(viewModel.isFreeLeague$|async) && (viewModel.isSubscribedToLeague$|async)"
        [disabled]="true"
        class="custom-button neutral-button disabled">
        <img alt="" [src]="'assets/icons/dark/outline/check-circle.svg'">
        <ng-container i18n>Subscribed</ng-container>
      </button>
    </div>
  </div>
</app-content-container>
<div
  *ngIf="((viewModel.isFreeLeague$|async) || (viewModel.isSubscribedToLeague$|async)) && (viewModel.programContentQuery$|async)">
  <app-content-container [unsetMinHeight]="true">
    <hr>
  </app-content-container>
  <app-program-player
    class="d-block"
    [programQuery]="viewModel.programContentQuery$|async"
    [programId]="viewModel.programId$|async"
    [isShow]="viewModel.isShow$|async"
    [showBottomDivider]="true"
    [smallVideoPlayer]="true"
    [isLeague]="true"
    [isEvent]="false">
  </app-program-player>
</div>
<app-program-row
  [navigateOnClick]="!(viewModel.isFreeLeague$|async) && (!(viewModel.isSignedIn$|async) || !(viewModel.isSubscribedToLeague$|async))"
  [programRowType]="programRowType.Live"
  [programContentQuery]="viewModel.contentQuery$|async"
  (handleCardClicked)="programCardClicked($event)"
  (noResults)="viewModel.liveProgramsNoResults.next($event)">
</app-program-row>
<app-sign-up-banner [leagueLandingScreen]="true">
</app-sign-up-banner>
<app-no-content *ngIf="viewModel.showNoResults$|async" [type]="noContentType.League"></app-no-content>
<app-league-subscribe-banner
  #subscribeBanner
  [hydratedLeague]="viewModel.hydratedLeague$|async">
</app-league-subscribe-banner>
<app-program-row
  [navigateOnClick]="!(viewModel.isFreeLeague$|async) && (!(viewModel.isSignedIn$|async) || !(viewModel.isSubscribedToLeague$|async))"
  [programRowType]="programRowType.Upcoming"
  [programContentQuery]="viewModel.contentQuery$|async"
  (handleCardClicked)="programCardClicked($event)"
  (noResults)="viewModel.upcomingProgramsNoResults.next($event)">
</app-program-row>
<app-program-row
  [navigateOnClick]="!(viewModel.isFreeLeague$|async) && (!(viewModel.isSignedIn$|async) || !(viewModel.isSubscribedToLeague$|async))"
  [programRowType]="programRowType.Past"
  [programContentQuery]="viewModel.contentQuery$|async"
  (handleCardClicked)="programCardClicked($event)"
  [seeAllGamesClicked]="seeAllPastGames"
  (noResults)="viewModel.pastProgramsNoResults.next($event)">
</app-program-row>
<app-program-row
  [navigateOnClick]="!(viewModel.isFreeLeague$|async) && (!(viewModel.isSignedIn$|async) || !(viewModel.isSubscribedToLeague$|async))"
  [programRowType]="programRowType.Shows"
  [programContentQuery]="viewModel.contentQuery$|async"
  (handleCardClicked)="programCardClicked($event)"
  (noResults)="viewModel.showsNoResults.next($event)">
</app-program-row>
