import {Component} from '@angular/core';
import {EventDetailsViewModel} from './event-details-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {TabBarItem} from '../../../../models/shared/stylesheet/tab-bar-item';
import {EditEventComponent} from './edit-event/edit-event.component';
import {EventProgramsComponent} from './event-programs/event-programs.component';
import {EventShowsComponent} from './event-shows/event-shows.component';
import {EventTeamsComponent} from './event-teams/event-teams.component';
import {EventVenuesComponent} from './event-venues/event-venues.component';
import {ActivationEnd, Router} from '@angular/router';
import {EventBannerAdsComponent} from './event-banner-ads/event-banner-ads.component';
import { EventStreamsComponent } from './event-streams/event-streams.component';
import { EventsContributorsComponent } from './events-contributors/events-contributors.component';

@Component({
  selector: 'app-events-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
  providers: [EventDetailsViewModel],
})
export class EventDetailsComponent extends BaseComponent {

  tabs: TabBarItem[] = [];

  constructor(
    public viewModel: EventDetailsViewModel,
    private router: Router,
  ) {
    super();
  }

  setupBindings() {
    this.router.events.subscribe(ev => {
      if (ev instanceof ActivationEnd && Object.is(ev?.snapshot?.component, EventDetailsComponent)) {
        this.viewModel.refreshEventProgramsAndShows$.next();
      }
    });
  }

  setupViews() {
    this.setupTabBar();
  }

  setupTabBar() {
    this.tabs = [];
    let id = 1;
    const details = new TabBarItem(EditEventComponent, id++, $localize`Details`, '');
    details.active = true;
    const teams = new TabBarItem(EventTeamsComponent, id++, $localize`Teams`, '');
    const venues = new TabBarItem(EventVenuesComponent, id++, $localize`Venues`, '');
    const programs = new TabBarItem(EventProgramsComponent, id++, $localize`Programs`, '');
    const shows = new TabBarItem(EventShowsComponent, id++, $localize`Shows`, '');
    const ads = new TabBarItem(EventBannerAdsComponent, id++, $localize`Banner Ads`, '');
    const streams = new TabBarItem(EventStreamsComponent, id++, $localize`Streams`, '');
    const contributor=new TabBarItem(EventsContributorsComponent, id++, $localize`Contributor`, '');
    this.tabs.push(...[details, teams, venues, programs, shows, ads, streams,contributor]);
  }
}
