import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { BaseModel } from '../base/base-model';
import { DatatableData } from '../protocols/datatable-data';
import { SearchListable } from '../protocols/search-listable';
import { Selectable } from '../protocols/selectable';
import { FormListable } from '../protocols/form-listable';

export class qrCode extends BaseModel implements Selectable, DatatableData, SearchListable, FormListable
{
    id: string = null;
    title: string = null;
    expiryDate: string = null;
    publicUrl: string = null;
    s3ImageUrl: string = null;
    leagueId: number = null;
    eventId: number = null;
    isActive: boolean = true;
    qrCodeMetadata: [{information: string; value: string; addToUrl: boolean}] = [{information: '', value: '', addToUrl: false}];

    itemChanged: boolean;
    itemDeleted: boolean;
    itemCreated: boolean;
    onDeserialize() {
        super.onDeserialize();
}
    getSearchListableTitle(): string {
        return this.title;
    }
    getSearchListableSubTitle(): string {
        return this.title;
    }
    getSearchListablePrimaryKey(): string {
        return this.title;
    }
    getSearchListableSecondaryKey(): string {
        return this.title;
    }
    getSearchListableImgSrc$(): Observable<string | SafeResourceUrl> {
        return null;
    }
    getChildrenUniqueIds(): string[] {
        return [];
    }
    getColor(): string {
        return '';
    }
    getTextColor(): string {
        return '';
    }
    getSelectionTitle(): string {
        return this.title;
    }
    getSelectionValue() {
        return this.id;
    }
    getSelectionUniqueIdentifier() {
        return this.id;
    }

    getListItemTitle(): string {
        return this.title;
    }
    getListItemSubtitle(): string {
        return this.title;
    }
    getEnabledStatus(): boolean {
        return this.isActive;
    }

}
