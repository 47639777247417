import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/models/base/base-component';
import { StringUtils } from 'src/app/utils/string-utils';

@Component({
  selector: 'app-score-bug',
  templateUrl: './score-bug.component.html',
  styleUrls: ['./score-bug.component.scss']
})
export class ScoreBugComponent extends BaseComponent {
  @Input() homeTeamName: string;
  @Input() homeTeamScore$ = new BehaviorSubject<number>(0);
  @Input() visitingTeamScore$ = new BehaviorSubject<number>(0);
  @Input() visitingTeamName: string;

  readonly teamNameCharacterLimit = 12;

  get truncatedHomeTeamName(): string {
    return StringUtils.truncate(this.homeTeamName, this.teamNameCharacterLimit);
  }

  get truncatedVisitingTeamName(): string {
    return StringUtils.truncate(this.visitingTeamName, this.teamNameCharacterLimit);
  }

  constructor() {
    super();
  }

  setupViews() {
  }

  setupBindings() {
    throw new Error('Method not implemented.');
  }
}
