/* eslint-disable max-len */
import {ApiClient} from './api-client';
import {combineLatest, Observable, of, ReplaySubject, throwError} from 'rxjs';
import {Image} from '../models/image/dto/image';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {LoggingService} from '../services/logging-service';
import {catchError, debounceTime, filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {MediaUtils} from '../utils/media-utils';
import {CustomError} from '../models/shared/custom-error';
import * as buffer from 'buffer';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ImageSize} from '../models/enum/dto/image-size.enum';
import {ProgramApi} from './program-api';
import {UserApi} from './user-api';
import {HydratedProgram} from '../models/program/hydrated-program';
import {GenerateUploadUrlRequest} from '../models/image/requests/generate-upload-url-request';
import {Endpoints} from './endpoints';
import {HydratedLeague} from '../models/resources/hydrated-league';
import {HydratedEvent} from '../models/resources/hydrated-event';
import {HydratedTeam} from '../models/resources/hydrated-team';
import {HydratedShow} from '../models/program/hydrated-show';
import {HydratedVenue} from '../models/resources/hydrated-venue';
import {BannerAdvertisement} from '../models/resources/banner-advertisement';

export type ImageServiceCacheType = 'program' | 'show' | 'league' | 'event' | 'league-banner-ad' | 'event-banner-ad' | 'venue' | 'team' | 'team-card-image' | 'team-banner' | 'subscriber'
| 'league-banner-image' | 'event-banner-image';

@Injectable({
  providedIn: 'root'
})
export class ImageApi implements LoggableAPI {
  private backendHttpClient: HttpClient;
  cachedMedia = new Map<string, ReplaySubject<string | SafeResourceUrl>>();
  sizes = ['thumb', 'small', 'medium', 'large', 'original'];
  sizePriorityMap = new Map<string, number>([['thumb', 1], ['small', 2], ['medium', 3], ['large', 4], ['original', 5]]);
  CacheErrorTimeOut = 5000;
  // Variables

  public serviceName = 'Image';

  constructor(
    private apiClient: ApiClient,
    private httpBackend: HttpBackend,
    private loggingService: LoggingService,
    private programApi: ProgramApi,
    private userApi: UserApi,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
  ) {
    this.backendHttpClient = new HttpClient(httpBackend);
  }


  // Asset

  public putImageUploadUrl(url: string, file: string, fileName: string): Observable<any> {
    const adjustedFileName = fileName.replace(' ', '').toLowerCase();
    const type = MediaUtils.getMediaType(adjustedFileName.split('.').pop());
    let newFileContents = file.replace(/^data:image\/\w+;base64,/, '');
    newFileContents = newFileContents.replace(/^data:video\/\w+;base64,/, '');

    const buff = buffer.Buffer.from(newFileContents, 'base64');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', type);
    headers = headers.append('Content-Encoding', 'base64');

    const blob = new Blob([new Uint8Array(buff)]);
    return this.backendHttpClient.put<any>(url, blob, {headers}).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'PutImageUploadUrl', err));
        return throwError(err);
      })
    );
  }

  public getBlobFromUrl(url: string): Observable<Blob> {
    return this.apiClient.getBlob<Blob>(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetBlobFromUrl', err));
        return throwError(err);
      })
    );
  }

  public createSubscriberImage(subscriberId: string, req: GenerateUploadUrlRequest): Observable<Image> {
    const url = Endpoints.createSubscriberImage(subscriberId);
    return this.apiClient.postObj(Image, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createSubscriberImage', err));
        return throwError(err);
      })
    );
  }

  public createProgramImage(programId: string, req: GenerateUploadUrlRequest): Observable<Image> {
    const url = Endpoints.createProgramImage(programId);
    return this.apiClient.postObj(Image, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createProgramImage', err));
        return throwError(err);
      })
    );
  }

  public updateZypethumnail(programId: string,req: any)
  {
    const url = Endpoints.updateZypeThumbnail(programId);
    return this.apiClient.postObj(Image, url,req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createProgramImage', err));
        return throwError(err);
      })
    );
  }

  public createShowImage(showId: string, req: GenerateUploadUrlRequest): Observable<Image> {
    const url = Endpoints.createShowImage(showId);
    return this.apiClient.postObj(Image, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createShowImage', err));
        return throwError(err);
      })
    );
  }

  public createTeamImage(teamId: number, req: GenerateUploadUrlRequest): Observable<Image> {
    const url = Endpoints.createTeamImage(teamId);
    return this.apiClient.postObj(Image, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createTeamImage', err));
        return throwError(err);
      })
    );
  }

  public createTeamBannerImage(teamId: number, req: GenerateUploadUrlRequest): Observable<Image> {
    const url = Endpoints.createTeamBannerImage(teamId);
    return this.apiClient.postObj(Image, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createTeamBannerImage', err));
        return throwError(err);
      })
    );
  }

  public createTeamCardImage(teamId: number, req: GenerateUploadUrlRequest): Observable<Image> {
    const url = Endpoints.createTeamCardImage(teamId);
    return this.apiClient.postObj(Image, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createTeamCardImage', err));
        return throwError(err);
      })
    );
  }

  public createLeagueImage(teamId: number, req: GenerateUploadUrlRequest,imageType: number): Observable<Image> {
    const url = Endpoints.createLeagueImage(teamId,imageType);
    return this.apiClient.postObj(Image, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createLeagueImage', err));
        return throwError(err);
      })
    );
  }

  public createEventImage(teamId: number, req: GenerateUploadUrlRequest,imageTypeId: number): Observable<Image> {
    const url = Endpoints.createEventImage(teamId,imageTypeId);
    return this.apiClient.postObj(Image, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createEventImage', err));
        return throwError(err);
      })
    );
  }

  public createLeagueBannerImage(leagueId: number, req: GenerateUploadUrlRequest): Observable<BannerAdvertisement> {
    const url = Endpoints.createLeagueBannerAdvertisement(leagueId);
    return this.apiClient.postObj(BannerAdvertisement, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createLeagueBannerImage', err));
        return throwError(err);
      })
    );
  }

  public createEventBannerImage(eventId: number, req: GenerateUploadUrlRequest): Observable<BannerAdvertisement> {
    const url = Endpoints.createEventBannerAdvertisement(eventId);
    return this.apiClient.postObj(BannerAdvertisement, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createEventBannerImage', err));
        return throwError(err);
      })
    );
  }

  public createVenueImage(venueId: number, req: GenerateUploadUrlRequest): Observable<Image> {
    const url = Endpoints.createVenueImage(venueId);
    return this.apiClient.postObj(Image, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createVenueImage', err));
        return throwError(err);
      })
    );
  }

  getAllSizeKeysForAsset(type: ImageServiceCacheType, id: string): string[] {
    return this.sizes.map(s => this.getCacheKey(type, id, s));
  }

  clearCachedImages(type: ImageServiceCacheType, id: string) {
    this.getAllSizeKeysForAsset(type, id).forEach(key => {
      this.cachedMedia.get(key)?.next(null);
    });
  }

  imageUploadSuccessful(cacheType: ImageServiceCacheType, id: string, fileUrl: string | SafeResourceUrl) {
    this.clearCachedImages(cacheType, id);
    this.sizes.forEach(s => {
      const cacheKey = this.getCacheKey(cacheType, id, s);
      const r = this.cachedMedia.get(cacheKey) ?? new ReplaySubject<string | SafeResourceUrl>(1);
      this.cachedMedia.set(cacheKey, r);
      r.next(fileUrl);
    });
  }

  private getCacheKey(type: ImageServiceCacheType, id: string, size: string): string {
    return type + id + size;
  }

  public getProgramImage(programId: string, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('program', programId, this.programApi.getProgramImages(programId), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedProgramImage(hydratedProgram: HydratedProgram, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('program', hydratedProgram.id, of(hydratedProgram.images), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedProgramVenueImage(hydratedProgram: HydratedProgram, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('program', hydratedProgram.id, of(hydratedProgram.venueImages), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedProgramCardImage(hydratedProgram: HydratedProgram, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('program', hydratedProgram.id, of(hydratedProgram.teamCardImages), maxSize, fetchOnlyMaxSize);
  }


  public getHydratedShowImage(hydratedShow: HydratedShow, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('show', hydratedShow.id, of(hydratedShow.images), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedLeagueImage(hydratedLeague: HydratedLeague, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('league', hydratedLeague.id.toString(), of(hydratedLeague.leagueLogos), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedLeagueBannerImage(hydratedLeague: HydratedLeague, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('league-banner-image', hydratedLeague.id.toString(), of(hydratedLeague.bannerImageResource), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedEventBannerImage(hydratedEvent: HydratedEvent, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('event-banner-image', hydratedEvent.id.toString(), of(hydratedEvent.bannerImageResource), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedEventImage(hydratedEvent: HydratedEvent, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('event', hydratedEvent.id.toString(), of(hydratedEvent.eventLogos), maxSize, fetchOnlyMaxSize);
  }


  public getHydratedVenueImage(hydratedVenue: HydratedVenue, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('venue', hydratedVenue.id.toString(), of(hydratedVenue.images), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedTeamImage(hydratedTeam: HydratedTeam, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('team', hydratedTeam.id.toString(), of(hydratedTeam.logos), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedTeamBannerImage(hydratedTeam: HydratedTeam, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('team-banner', hydratedTeam.id.toString(), of(hydratedTeam.bannerImages), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedTeamCardImage(cardImage: Image, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('team-card-image', cardImage.id, of([cardImage]), maxSize, fetchOnlyMaxSize);
  }

  public getSubscriberImage(subscriberId: string, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('subscriber', subscriberId, this.userApi.getSubscriberImages(subscriberId), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedLeagueBannerAdvertisementImage(bannerAdvertisement: BannerAdvertisement, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('league-banner-ad', bannerAdvertisement.id, of([bannerAdvertisement]), maxSize, fetchOnlyMaxSize);
  }

  public getHydratedEventBannerAdvertisementImage(bannerAdvertisement: BannerAdvertisement, maxSize: ImageSize, fetchOnlyMaxSize: boolean = true): Observable<string | SafeResourceUrl> {
    return this.getSizePriorityCachedMedia('event-banner-ad', bannerAdvertisement.id, of([bannerAdvertisement]), maxSize, fetchOnlyMaxSize);
  }

  public getPresignedUrl(asset: Image, size: string): string {
    return asset?.links.find(l => l.size === size)?.presignedUrl;
  }

  getSizePriorityCachedMedia(type: ImageServiceCacheType, id: string, imageResourceObservable: Observable<Image[]>, maxSize: ImageSize, fetchOnlyMaxSize: boolean) {
    const obs: Observable<[string, string | SafeResourceUrl]>[] = [];
    const maxSizeIndex = this.sizes.findIndex(s => s === maxSize);
    this.sizes
      .filter((_, index) => {
        if (fetchOnlyMaxSize) {
          return index === maxSizeIndex;
        }
        return index <= maxSizeIndex;
      })
      .forEach(size => {
      const key = this.getCacheKey(type, id, size);
      obs.push(this.getCachedMedia(key, size, imageResourceObservable).pipe(
        startWith('' as string | SafeResourceUrl),
        map(imageSrc => [size, imageSrc] as [string, string | SafeResourceUrl])));
    });

    return combineLatest(obs).pipe(
      map(sizesAndSrcs => {
        const loadedSrcs = sizesAndSrcs?.filter(([_, src]) => !!src);
        const sortedSrcs = loadedSrcs?.sort(([sizeA], [sizeB]) => this.sizePriorityMap.get(sizeB) - this.sizePriorityMap.get(sizeA));
        return sortedSrcs?.find(s => !!s);
      }),
      filter(x => !!x),
      map(([, src]) => src),
    );
  }

  private getCachedMedia(key: string, size: string, imageResourceObservable: Observable<Image[]>): Observable<string | SafeResourceUrl> {
    if (this.cachedMedia.has(key)) {
      return this.cachedMedia.get(key);
    }

    const errorHandler = (error: Error) => {
      setTimeout(() => {
        this.cachedMedia.delete(key);
      }, this.CacheErrorTimeOut);
      r.next(null);
    };

    const r = new ReplaySubject<string | SafeResourceUrl>(1);
    imageResourceObservable.subscribe(photos => {
      const primaryPhoto = photos[0];
      const url = this.getPresignedUrl(primaryPhoto, size);
      if (url == null) {
        r.next(null);
        return;
      }
      this.backendHttpClient.get(url, {responseType: 'blob'}).subscribe(blob => {
        r.next(this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
      }, errorHandler);
    }, errorHandler);
    this.cachedMedia.set(key, r);
    return r;
  }
}
