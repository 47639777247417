import { Component, OnInit } from '@angular/core';
import { EventDetailsViewModel } from '../event-details-view-model';
import { BaseComponent } from 'src/app/models/base/base-component';
import { FormInputItem } from 'src/app/models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from 'src/app/models/shared/stylesheet/form-group-styling';
import { FormOptions } from 'src/app/models/shared/stylesheet/form-options';
import { ToastService } from 'src/app/services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateQrCodeRequest } from 'src/app/models/account/requests/create-qrCode-request';
import { indicateOnNext } from 'src/app/utils/observable.extensions';
import { map } from 'rxjs/operators';
import { qrCode } from 'src/app/models/resources/qrCode';

@Component({
  selector: 'app-event-create-qr-generator',
  templateUrl: './event-create-qr-generator.component.html',
  styleUrls: ['./event-create-qr-generator.component.scss'],
  providers: [EventDetailsViewModel]
})
export class EventCreateQrGeneratorComponent extends BaseComponent {

 public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: any = {};
  public url: string = null;
  public entries: { information: string; value: string; addToUrl: boolean }[] = [{information: '', value: '', addToUrl: false}];
  public status: boolean = true;
  public title: string = '';
  public expiryDate: string = '';
  public qrCodeId: string;
  public addInformationTitle: string = 'Embed additional information (optional)';

  constructor(
    public viewModel: EventDetailsViewModel,
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute

  ) {
    super();
  }

  setupViews() {

  }
  setupBindings() {
     this.activatedRoute.params.pipe(map(params => params.qrCodeId))
              .subscribe(id=>{
                if(id){
                  this.viewModel.getQrCodeById(id).notNull()
                    .subscribe((cont: qrCode)=>{
                      setTimeout(() => {
                        this.qrCodeId = cont.id;
                        this.title = cont.title;
                        this.expiryDate = cont.expiryDate ? cont.expiryDate.split('T')[0] : null;
                        this.entries = cont.qrCodeMetadata;
                        this.status = cont.isActive;
                        this.url = cont.publicUrl;
                      });
                    });
                }
              });
  }

  formSubmitted() {
    const formObject = new CreateQrCodeRequest();
    formObject.title = this.title;
    formObject.expiryDate = this.expiryDate.length > 0 ? this.expiryDate : null;
    formObject.qrCodeMetadata = this.entries;
    formObject.isActive = this.status;
    this.viewModel.saveQrCode(formObject)
    .pipe(indicateOnNext(this.viewModel.loadingOpts, $localize`Saving League QR Code`))
          .subscribe({
            next: (result) => {
              this.toastService.publishSuccessMessage('Qr Code saved successfully', '');
              this.router.navigate([`/admin/events/${result.eventId}`], { relativeTo: this.activatedRoute });
            },
            error: (error) => {
              this.toastService.publishError(error);
              console.error('Save Qr code error:',error );
            }
        });
  }

  checkAddToUrl(value: boolean, index: number) {
    this.entries[index].addToUrl = value;
  }

  addNewEntry() {
    this.entries.push({ information: '', value: '', addToUrl: false });
  }


  copyToClipboard() {
    navigator.clipboard.writeText(this.url).then(() => {
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  downloadQrCode() {
    this.viewModel.downloadQrCode(this.qrCodeId).subscribe((res) => {
      const blobUrl = window.URL.createObjectURL(res);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = `${this.title}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    });
  }

  submitButtonIsDisabled() {
    return this.title === '';
  }

}
