import {SafeResourceUrl} from '@angular/platform-browser';
import {Program} from './program';
import {DateUtils} from '../../utils/date-utils';
import {HydratedProgram} from './hydrated-program';
import * as moment from 'moment-timezone';
import {environment} from '../../../environments/environment';

export class ProgramFormObject {
  program: Program;
  imageToUpload: string | SafeResourceUrl;
  imageToUploadFormat: string;
  existingImageId: string;
  deleteImageId: string;
  sportId: number = null;
  programDate: string = null;
  programTime: string = null;
  programTimeZone: string = null;
  leagueId: number = null;
  venueId: number = null;
  homeTeam: string;
  homeTeamId: number;
  visitingTeam: string;
  visitingTeamId: number;
  startDateUtc: Date;
  durationInMinutes: number;
  productionTypeId: number;
  eventId: number = null;
  freeToWatch: boolean = false;
  index: number;
  static initWithProgram(program: HydratedProgram): ProgramFormObject {
    const formObject = new ProgramFormObject();
    formObject.program = program;
    formObject.programTimeZone = moment.tz.guess();
    formObject.freeToWatch = program.freeToWatch;

    if (program.startDateUtc) {
      formObject.programDate = DateUtils.formatTimeDateInputString(program.startDateUtc);
      formObject.programTime = DateUtils.formatTimeTo24HourString(program.startDateUtc);
    }

    if (program.images?.length > 0) {
      formObject.existingImageId = program.images[0]?.id;
    }

    return formObject;
  }
}
