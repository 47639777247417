import { BaseModel } from '../../base/base-model';



export class CreateQrCodeRequest extends BaseModel {
    id: string;
    public title: string = '';
    public expiryDate: string = '';
    public isActive: boolean = true;
    public qrCodeMetadata: {information: string; value: string; addToUrl: boolean}[] = [{information: '', value: '', addToUrl: false}];

    static initWithQrCode(qrCode: CreateQrCodeRequest): CreateQrCodeRequest {
        const formObject = new CreateQrCodeRequest();
        formObject.id = qrCode.id;
        formObject.title = qrCode.title;
        formObject.expiryDate = qrCode.expiryDate;
        formObject.isActive = qrCode.isActive;
        formObject.qrCodeMetadata = qrCode.qrCodeMetadata;
        return formObject;
    }

    public onDeserialize() {
        super.onDeserialize();
    }
}
