import { Component, OnInit } from '@angular/core';
import { CreateContributorRequest } from 'src/app/models/account/requests/create-contributor-request';
import { contributor } from 'src/app/models/resources/contributor';
import { FormGroupStyling } from 'src/app/models/shared/stylesheet/form-group-styling';
import { FormInputItem, FormInputType, FormItemType } from 'src/app/models/shared/stylesheet/form-input-item';
import { FormOptions } from 'src/app/models/shared/stylesheet/form-options';
import { LeagueDetailsViewModel } from '../../../leagues/league-details/league-details-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast-service';
import { BaseComponent } from 'src/app/models/base/base-component';
import { map } from 'rxjs/internal/operators/map';
import { EventsViewModel } from '../../events-view-model';
import { EventDetailsViewModel } from '../event-details-view-model';
import { Checkbox } from 'src/app/models/shared/stylesheet/checkbox';
import { indicateOnNext } from 'src/app/utils/observable.extensions';

@Component({
  selector: 'app-events-contributors-details',
  templateUrl: './events-contributors-details.component.html',
  styleUrls: ['./events-contributors-details.component.scss'],
  providers:[EventDetailsViewModel]
})
export class EventsContributorsDetailsComponent extends BaseComponent  {

  contributor: contributor;
  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formObject: CreateContributorRequest = new CreateContributorRequest();
  public formOptions = new FormOptions();

  constructor(
    public viewModel: EventDetailsViewModel,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
      super();
   }

   setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    this.formStyling.includePadding = false;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'd-flex flex-row-reverse justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.submitButtonText = $localize`Save Contributor`;
  }

  setupBindings() {
    this.activatedRoute.params.pipe(map(params => params.contributorId))
      .subscribe(id=>{
        if(id>0){
          this.viewModel.getContributorById(id).notNull()
            .subscribe((cont: CreateContributorRequest)=>{
              setTimeout(() => {
                this.formObject = CreateContributorRequest.intiWithContributor(cont);
                this.formObject.itemChanged = true;
                this.setupFormItems(true);
              });
            });
        }
        else{
          this.formObject=new CreateContributorRequest();
          this.formObject.itemChanged = false;
          this.setupFormItems();
        }
      });

  }
  setupFormItems(forUpdate?: boolean) {
    const items: FormInputItem[] = [];

    const firstName = new FormInputItem();
    firstName.itemType = FormItemType.Input;
    firstName.label = $localize`First Name`;
    firstName.placeholder = $localize`First Name`;
    firstName.inputName = 'firstName';
    firstName.required = true;
    firstName.bindingProperty = 'firstName';
    firstName.overrideFullWidth = false;
    firstName.enabled=this.formObject.itemChanged ? false : true;
    items.push(firstName);

    const lastName = new FormInputItem();
    lastName.inputName = 'lastName';
    lastName.inputType = FormInputType.Text;
    lastName.label = $localize`Last Name`;
    lastName.placeholder = $localize`Last Name`;
    lastName.bindingProperty = 'lastName';
    lastName.required = true;
    lastName.overrideFullWidth = false;
    lastName.enabled=this.formObject.itemChanged ? false : true;
    items.push(lastName);

    const email = new FormInputItem();
    email.inputName = 'email';
    email.inputType = FormInputType.Email;
    email.label = $localize`Email`;
    email.placeholder = $localize`Email`;
    email.bindingProperty = 'email';
    email.overrideFullWidth = false;
    email.required = true;
    email.enabled=this.formObject.itemChanged ? false : true;

    items.push(email);

    const contributorCategoryCheckboxGroup = new FormInputItem();
    contributorCategoryCheckboxGroup.inputName = 'contributorCategoryIds';
    contributorCategoryCheckboxGroup.itemType = FormItemType.CheckboxGroup;
    contributorCategoryCheckboxGroup.label = $localize`Contributor Categories`;
    contributorCategoryCheckboxGroup.bindingProperty = 'contributorCategoryIds';
    contributorCategoryCheckboxGroup.overrideFullWidth = true;
    const contributorCategories = [
      { id: 1, label: 'Camera Setup' },
      { id: 2, label: 'Time Keeper' },
      { id: 3, label: 'Score Keeper' }
    ];

    contributorCategoryCheckboxGroup.groupCheckboxes = contributorCategories.map(category => {
      if(forUpdate && this.formObject.contributorCategoryIds.contains(category.id)){
        return new Checkbox(category.label, true, false, '',category.id);
      }
      else{
        return new Checkbox(category.label, false, false, '',category.id);
      }


    });

    items.push(contributorCategoryCheckboxGroup);



    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`Active`;
    active.bindingProperty = 'active';
    active.customClass = 'mb-4 mt-0';
     items.push(active);
    this.formItems = items;

  }
  cancel(){
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  formSubmitted(contributerFormObject: CreateContributorRequest) {
    if (this.formObject.id) {
      this.viewModel.updateContributor(contributerFormObject)
      .pipe(indicateOnNext(this.viewModel.loadingOpts, $localize`Saving Event Contributor`)).subscribe({
        next: (result) => {
          this.toastService.publishSuccessMessage('Contributor updated successfully', '');
          this.router.navigate(['../..'], { relativeTo: this.activatedRoute });

        },
        error: (error) => {
          this.toastService.publishError(error);
          console.error('Update contributor error:', error);
        }
      });
    } else {
      this.viewModel.saveContributor(contributerFormObject)
       .pipe(indicateOnNext(this.viewModel.loadingOpts, $localize`Saving Event Contributor`)).subscribe({
        next: (result) => {
          this.toastService.publishSuccessMessage('Contributor saved successfully', '');
          this.router.navigate([`/admin/events/${result.eventId}`], { relativeTo: this.activatedRoute });
        },
        error: (error) => {
          this.toastService.publishError(error);
          console.error('Save contributor error:',error );
        }
      });
    }

  }


}
