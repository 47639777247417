<form (keydown.enter)="$event.preventDefault()"
      [formGroup]="form"
      [ngClass]="{'custom-textfield-form': styling.includePadding}">
  <div class="custom-form-container" [class.two-column]="styling.numberColumns === 2">

    <ng-container *ngFor="let item of formItems; let itemIndex = index;">

      <!--   Hidden Item - used to maintain odd/even count when using overrideFullWidth   -->
      <div *ngIf="item.itemType === itemTypes.Hidden"
           class="form-input-container">
      </div>

      <div *ngIf="item.itemType === itemTypes.ProjectedContent"
           class="form-input-container w-100">
        <ng-content></ng-content>
      </div>

      <div *ngIf="item.itemType === itemTypes.AlertBanner"
           class="form-input-container d-block w-100">
        <div class="custom-alert mb-4"
             [id]="item.alertBannerId"
             [class.custom-alert-danger]="item.alertBannerStyle === 'error'"
             [class.custom-alert-success]="item.alertBannerStyle === 'success'"
             *ngIf="item.alertBannerMessage">
          <img class="custom-alert-icon"
               [src]="item.alertBannerStyle === 'success' ?
               'assets/icons/light/solid/check-circle.svg' :
               'assets/icons/light/solid/x-circle.svg'" alt="">
          {{ item.alertBannerMessage }}
        </div>
      </div>

      <!--   Divider Item   -->
      <div *ngIf="item.itemType === itemTypes.Divider"
           class="form-input-container d-block w-100">
        <hr [ngClass]="item.customClass" class="mb-4 mt-1">
      </div>

      <!--   Title Item   -->
      <div *ngIf="item.itemType === itemTypes.Title"
           class="form-input-container w-100 d-flex align-items-baseline">
        <div class="form-input-title" [ngClass]="item.customClass">
          {{ item.label }}
        </div>
        <div *ngIf="item.tooltipText && item.tooltipText !== ''"
             class="tooltip-container">
          <img [ngbTooltip]="item.tooltipText"
               [placement]="getTooltipPlacement(itemIndex)"
               [src]="'assets/icons/dark/outline/information-circle.svg'" alt="">
        </div>
        <div class="title-button {{item.titleButtonClass}}"
             [class.disabled]="!item.enabled"
             (click)="item.titleButtonClicked.emit()"
             *ngIf="item.titleButtonText?.length > 0">
          {{ item.titleButtonText }}
        </div>
      </div>

      <!--   List Item   -->
      <div *ngIf="item.itemType === itemTypes.List"
           class="form-list-container w-100 mb-4">
        <ng-container *ngFor="let listItem of getListItems(item)">
          <div class="form-list-item" [class.form-list-item-disabled]="!listItem.getEnabledStatus()">
            <div class="form-list-item-title-container">
              <div class="form-list-item-title">
                {{ listItem.getListItemTitle() }}
              </div>
              <div class="form-list-item-subtitle">{{ listItem.getListItemSubtitle() }}</div>
            </div>
            <div class="form-list-item-button" [ngClass]="item.listItemButtonClass"
                 (click)="item.listItemClicked.emit(listItem)">
              {{ item.listItemButtonText }}
            </div>
          </div>
          <hr>
        </ng-container>
        <hr *ngIf="getListItems(item)?.length === 0">
      </div>

      <div class="custom-form-group {{ item.inputHasButton ? null : item.customClass }}"
           *ngIf="!settingUpForm && item.hasFormEditableValue()"
           [ngClass]="{
              'has-error': item.hasErrors(),
              'two-column' : styling.numberColumns === 2 && !item.overrideFullWidth,
              'two-column-expand-last' : styling.numberColumns === 69,
              'single-line-input': item.itemType === itemTypes.Switch
           }">

        <div [ngClass]="{ 'inline' : item.inlineLabel }"
             class="custom-form-group-item">
          <label
            [hidden]="item.hideLabel || item.itemType === itemTypes.Switch || item.itemType === itemTypes.CheckboxItem"
            [ngClass]="{ 'inline-label' : item.inlineLabel }"
            class="custom-textfield-label"
            [ngStyle]="{ 'width' : item.inlineLabelWidthPercent*2+'%' }"
            for="{{ item.inputName }}">
            {{ item.label }}
            <span *ngIf="item.required && item.showRequiredAsterisk"
                  class="required-star"> *</span>
            <span *ngIf="item.tooltipText && item.tooltipText !== ''"
                  class="tooltip-container">
              <img [ngbTooltip]="item.tooltipText"
                   [placement]="getTooltipPlacement(itemIndex)"
                   [src]="'assets/icons/dark/outline/information-circle.svg'"
                   alt="">
            </span>
            <span>
              <button *ngIf="item.hasInformation" mat-icon-button [matTooltip]="item.informationText">
                <mat-icon>info</mat-icon>
              </button> 
            </span>  
          </label>
          <div class="custom-textfield-label-subtitle"
               *ngIf="item.hint || (item.hasErrors() && !item.hideErrorMessage)">
            {{ (item.hasErrors() && !item.hideErrorMessage) ? item.getErrorMessage() : item.hint }}
          </div>
          <div [ngClass]="{ 'flex' : item.inputHasButton }"
               class="input-container">
            <!--   Input Item   -->
            <div *ngIf="item.itemType === itemTypes.Input"
                 [ngClass]="{ 'inline-textfield-container': item.inlineLabel,
               'inline-button-container': item.inputHasButton}"
                 class="form-input-container">
              <div *ngIf="item.chipInput && item.chipInputValues?.length > 0"
                   [class.has-error]="item.hasErrors()"
                   class="form-input-chip-container">
                <div class="form-input-chip"
                     *ngFor="let chipValue of item.chipInputValues"
                     (click)="removeChipValue(item, chipValue)">
                  {{ chipValue }}
                  <img [src]="'/assets/icons/dark/solid/remove.svg'" alt="remove">
                </div>
              </div>
              <div class="input-container-block">
                <input (change)="!settingUpForm ? item.handleValueChanged() : null"
                       (keyup)="handleInputKeyUp($event, item)"
                       (blur)="addChipInputValue(item)"
                       [attr.disabled]="!item.enabled ? true : null"
                       [attr.list]="item.inputName.concat('List')"
                       [ngClass]="{
                      'has-error': item.hasErrors(),
                      'search-textfield' : item.inputType === inputTypes.Search,
                      'chip-input' : item.chipInput && item.chipInputValues?.length > 0,
                      'inline-textfield': item.inlineLabel }"
                       [ngStyle]="{
                      'background' : (item.inputType === 'color') ? form.get(item.inputName).value || ' #F2F2F2' : '#F2F2F2' }"
                       [readOnly]="!item.editable"
                       [type]="item.inputType === inputTypes.Search || item.inputType === inputTypes.AddressAutoComplete ? 'text' : item.inputType"
                       class="form-control custom-textfield {{ item.inputHasButton ? null : item.customClass }}"
                       formControlName="{{ item.inputName }}"
                       [imask]="item.inputMask"
                       [unmask]="'typed'"
                       [id]="item.inputName"
                       name="{{ item.inputName }}"
                       [step]="item.inputStep"
                       [hidden]="item.hideInput"
                       [autocapitalize]="item.autoCapitalize"
                       [autocomplete]="item.autoComplete ? 'on' : 'off'"
                       (focus)="(item.inputType === inputTypes.AddressAutoComplete || item.forceDisableAutoFill) ? $any($event.target).autocomplete='new-password' : null;"
                       placeholder="{{ item.placeholder }}">
                <div class="search-results-container"
                     *ngIf="item.inputType === inputTypes.Search && item.filteredSearchableItems()?.length > 0"
                     [id]="item.inputName.concat('List')">
                  <div *ngFor="let sItem of item.filteredSearchableItems()"
                       (click)="item.handleSearchableItemSelected(sItem)"
                       class="search-results-item">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        {{ sItem.title || sItem.lookupKey }}
                        <div class="search-results-item-subtitle" *ngIf="!!sItem.subtitle">
                          {{ sItem.subtitle }}
                        </div>
                      </div>
                      <img class="search-results-item-icon" *ngIf="!!sItem.iconSrc" [src]="sItem.iconSrc" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--    Dropdown Item    -->
            <div *ngIf="item.itemType === itemTypes.Dropdown"
                 [ngClass]="{ 'inline-textfield-container': item.inlineLabel,
                'inline-button-container': item.inputHasButton}"
                 class="form-input-container">
              <div class="input-container-block">         
                <select
                  [class.has-error]="item.hasErrors()"
                  (ngModelChange)="!settingUpForm ? item.handleValueChanged() : null"
                  [attr.disabled]="(!item.enabled) ? true : null"
                  class="form-control custom-dropdown"
                  formControlName="{{ item.inputName }}"
                  [id]="item.inputName"
                  name="{{ item.inputName }}">
                  <option *ngIf="item.placeholder" disabled selected [ngValue]="item.dropdownIsObject ? null : ''">
                    {{ item.placeholder }}
                  </option>
                  <option *ngFor="let drop of item.dropdownOptions" [ngValue]="drop.getSelectionValue()">
                    {{ drop.getSelectionTitle() }}
                  </option>
                </select>
              </div>
              </div>


            <!--    Switch Item    -->
            <div *ngIf="item.itemType === itemTypes.Switch"
                 [ngClass]="{ 'inline-textfield-container': item.inlineLabel }"
                 class="form-input-container form-input-switch">
              <div class="input-container-block">
                <div class="custom-switch-container">
                  <label class="custom-switch">
                    <input (change)="!settingUpForm ? item.handleValueChanged() : null"
                           [value]="item.getValue()"
                           [checked]="item.getValue()"
                           [attr.disabled]="(!item.enabled) ? true : null"
                           class="form-control custom-switch-input"
                           formControlName="{{ item.inputName }}"
                           [id]="item.inputName"
                           name="{{ item.inputName }}"
                           type="checkbox">
                    <span [ngClass]="{ 'disabled' : !item.enabled }"
                          class="custom-slider"></span>
                  </label>

                  <div class="switch-label-container ml-2" [ngClass]="{ 'disabled' : !item.enabled }">
                    {{ item.label }}
                    <div *ngIf="item.tooltipText && item.tooltipText !== ''"
                         class="tooltip-container">
                      <img [ngbTooltip]="item.tooltipText"
                           [placement]="getTooltipPlacement(itemIndex)"
                           [src]="'assets/icons/dark/outline/information-circle.svg'" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--    Textarea Item    -->
            <div *ngIf="item.itemType === itemTypes.Textarea"
                 [ngClass]="{ 'inline-textfield-container': item.inlineLabel}"
                 class="form-input-container">
        <textarea class="form-control custom-text-area {{item.customClass}}"
                  cdkTextareaAutosize="{{item.autoGrowTextArea}}"
                  cdkAutosizeMinRows="{{item.autoGrowMinRows}}"
                  cdkAutosizeMaxRows="{{item.autoGrowMaxRows}}"
                  [ngClass]="{ 'disabled' : !item.enabled }"
                  [class.has-error]="item.hasErrors()"
                  [ngStyle]="{'border-bottom': item.hasErrors() ? '1px solid #FA4545' : ''}"
                  (keyup)="handleInputKeyUp($event,item)"
                  (change)="!settingUpForm ? item.handleValueChanged() : null"
                  formControlName="{{ item.inputName }}"
                  name="{{ item.inputName }}"
                  [id]="item.inputName"
                  placeholder="{{ item.placeholder }}"
                  rows="1"
                  [attr.disabled]="!item.enabled ? true : null"
                  [readOnly]="!item.editable"></textarea>
            </div>

            <!--    Checkbox Group Item    -->
            <div *ngIf="item.itemType === itemTypes.CheckboxGroup"
                 [ngClass]="{ 'inline-textfield-container': item.inlineLabel}"
                 class="form-input-container">
              <div class="grouped-checkbox-container">
                <app-checkbox-container
                  [ngClass]="item.groupCheckboxesContainerClass"
                  (changed)="groupedCheckboxesChanged(item, $event)"
                  [checkBoxContainerOptions]="item.groupCheckboxOptions"
                  [checkBoxes]="item.groupCheckboxes">
                </app-checkbox-container>
              </div>
            </div>

            <!--    Radio Group Item    -->
            <div *ngIf="item.itemType === itemTypes.RadioGroup"
                 [ngClass]="{ 'inline-textfield-container': item.inlineLabel}"
                 class="form-input-container">
              <div class="grouped-radio-container">
                <app-radiobutton-container
                  [ngClass]="[item.groupRadiosContainerClass, 'radio-group-horizontal']"
                  (change)="item.handleRadiobuttonChanged()"
                  [radioContainerOptions]="item.groupRadioOptions"
                  [radiobuttons]="item.groupRadios"
                >
                </app-radiobutton-container>
              </div>
            </div>

            <!--    Button Item    -->
            <ng-container *ngIf="item.itemType === itemTypes.Button">
              <button
                (click)="item.titleButtonClicked.emit()"
                [ngClass]="item.customClass"
                [disabled]="item.buttonDisabled">
                {{ item.titleButtonText }}
              </button>
            </ng-container>

            <!--    Single Checkbox Item    -->
            <div *ngIf="item.itemType === itemTypes.CheckboxItem"
                 class="form-input-container">
              <app-checkbox
                (clickEvent)="checkboxItemClicked($event, item)"
                [checked]="item.getValue()"
                [disabled]="!item.enabled"
                [label]="item.label">
              </app-checkbox>
            </div>


            <!--    Form input button - Available for input or dropdown   -->
            <div [hidden]="!item.inputHasButton"
                 class="form-input-button-container">
              <button (click)="item.inputButtonClicked.emit($event)"
                      type="button"
                      class="custom-button {{ item.inputHasButton ? item.customClass : null }}">
                {{ item.inputButtonText }}
              </button>
            </div>
          </div>

        </div>
      </div>
    </ng-container>

    <!--   End Form Checkbox     -->
    <div *ngIf="options.includeEndFormCheckbox"
         [ngClass]="styling.checkboxClass"
         class="form-input-container form-end-checkbox-container">
      <app-checkbox
        (clickEvent)="checkboxClicked($event)"
        [checked]="checkboxValue"
        [label]="styling.checkboxLabel">
      </app-checkbox>
      <div *ngIf="styling.checkboxRowButtonLabel?.length > 0" class="text-link-helper text-link"
           (click)="checkboxRowButtonClicked()">
        {{ styling.checkboxRowButtonLabel }}
      </div>
    </div>

    <div *ngIf="
      styling.resetButtonText !== '' ||
      styling.cancelButtonText !== '' ||
      styling.submitButtonText !== '' ||
      styling.secondaryButtonText !== '' ||
      styling.tertiaryButtonText !== ''"
         class="form-group-buttons-container">
      <div class="form-group primary-button-container"
           [ngStyle]="{'float':styling.primaryButtonFloat}"
           [ngClass]="styling.primaryButtonContainerClass">
        <button (click)="resetForm()" [hidden]="styling.resetButtonText === ''"
                class="custom-button neutral-button"
                [ngClass]="styling.primaryButtonClass"
                type="reset">
          {{ styling.resetButtonText }}
        </button>
        <button (click)="cancelForm()"
                [hidden]="styling.cancelButtonText === ''"
                class="custom-button neutral-button"
                [ngClass]="styling.primaryButtonClass"
                type="button">
          {{ styling.cancelButtonText }}
        </button>
        <button [disabled]="!canSubmitForm()"
                [hidden]="styling.submitButtonText === ''"
                class="custom-button preferred-button"
                [ngClass]="!canSubmitForm() ? styling.submitButtonDisabledClass : styling.submitButtonClass"
                type="submit"
                (click)="submitForm()">
          {{ styling.submitButtonText }}
        </button>

      </div>
      <div class="form-group secondary-button-container"
           [ngStyle]="{'float':styling.secondaryButtonFloat}"
           [ngClass]="styling.primaryButtonContainerClass">
        <button (click)="secondaryButtonPressed.emit()"
                [hidden]="styling.secondaryButtonText === ''"
                [ngStyle]="{ 'margin' : styling.getButtonMargin(true) }"
                class="custom-button {{ styling.secondaryButtonClass }}"
                type="button">
          {{ styling.secondaryButtonText }}
        </button>
      </div>
      <div class="form-group tertiary-button-container"
           [ngStyle]="{'float':styling.tertiaryButtonFloat}">
        <button *ngIf="showTertiaryButton" (click)="tertiaryButtonPressed.emit()"
                [hidden]="styling.tertiaryButtonText === ''"
                [ngStyle]="{ 'margin' : styling.getButtonMargin(true) }"
                class="custom-button preferred-button {{ styling.tertiaryButtonClass }}"
                type="button">
          {{ styling.tertiaryButtonText }}
        </button>
      </div>
    </div>
  </div>
</form>
