<div class="score-bug N27-regular">
  <div class="team-name" [matTooltip]="homeTeamName">
    {{ truncatedHomeTeamName }}
  </div>
  <div class="score-container">
    <span class="score">{{ homeTeamScore$ | async }}</span>
    <span class="separator">-</span>
    <span class="score">{{ visitingTeamScore$ | async }}</span>
  </div>
  <div class="team-name" [matTooltip]="visitingTeamName">
    {{ truncatedVisitingTeamName }}
  </div>    
</div>
