import {Component, EventEmitter, ViewChild} from '@angular/core';
import {AuthModalViewModel, LoginType} from './auth-modal-view-model';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthFlow, EditSubscriptionFlow, SubscriberSignUpFlow} from '../../../../models/account/enum/auth-flow.enum';
import {SubscriberSubscription} from '../../../../models/account/dto/subscriber-subscription';
import {SignUpFormComponent} from './sign-up-form/sign-up-form.component';
import {GoogleAnalyticsService} from '../../../../services/google-analytics-service';
import {OpenAuthModalOptions} from '../../../../models/account/open-auth-modal-options';
import {SubscriptionPricingOption} from '../../../../models/account/dto/subscription-plan';

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
  providers: [AuthModalViewModel],
})
export class AuthModalComponent extends BaseModal {

  @ViewChild('signUpFormComponent') signUpFormComponent: SignUpFormComponent;

  authFlowType = AuthFlow;
  subSignUpFlowType = SubscriberSignUpFlow;
  primaryButtonClicked = new EventEmitter<void>();
  secondaryButtonClicked = new EventEmitter<void>();
  tertiaryButtonClicked = new EventEmitter<void>();
  editingPlans = false;

  constructor(
    public viewModel: AuthModalViewModel,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  initWithOptions(options: OpenAuthModalOptions) {
    this.viewModel.setLoginType(options.loginType);
    this.viewModel.authFlow = options.authFlow;
    if (!!options.subAuthFlow) {
    this.viewModel.subSignUpFlow = options.subAuthFlow;
    }
    this.viewModel.overrideSignInTitle = options.signInTitle;
    this.viewModel.overrideSignUpTitle = options.signUpTitle;
    this.viewModel.filterSubscriptionPlanId = options.subscriptionPlanId;
    this.viewModel.filterLeagueId = options.preSelectedLeagueId;

    if (!!options.completionRoute) {
      this.viewModel.completionRoute = options.completionRoute;
    }
    if (options.loginType === LoginType.Subscriber) {
      this.googleAnalyticsService.trackEvent('account_modal', 'account_modal_appeared', 'Sign In/Up');
    }
  }

  initForEditPlans(
    existingSubscriberSubscriptions: SubscriberSubscription[], selectPlanId?: string,
    selectedLeagueId?: number,fromAccountPage: boolean=false
  ) {
    this.editingPlans = true;
    this.viewModel.setLoginType(LoginType.Subscriber);
    this.viewModel.authFlow = AuthFlow.SignUp;
    this.viewModel.subSignUpFlow = SubscriberSignUpFlow.PickPlan;
    this.viewModel.editSubscriptionFlow = EditSubscriptionFlow.EditPlans;
    this.viewModel.existingSubscriberSubscriptions = existingSubscriberSubscriptions;
    this.viewModel.preselectPlanId = selectPlanId;
    this.viewModel.preSelectedLeagueId = selectedLeagueId;
    this.viewModel.isFromAccount=fromAccountPage ? true : false;
    this.googleAnalyticsService.trackEvent('account_modal', 'account_modal_appeared', 'Edit Plans');
    if (!!selectPlanId) {
      fbq('track', 'Subscribe', {currency: 'CAD'});
    }
  }

  initForEditDiscounts(existingSubscriberSubscriptions: SubscriberSubscription[], selectedSubscriberSubscription: SubscriberSubscription) {
    this.editingPlans = true;
    this.viewModel.isEditingDiscount = true;
    this.viewModel.setLoginType(LoginType.Subscriber);
    this.viewModel.authFlow = AuthFlow.SignUp;
    this.viewModel.subSignUpFlow = SubscriberSignUpFlow.Review;
    this.viewModel.editSubscriptionFlow = EditSubscriptionFlow.AddDiscount;
    this.viewModel.existingSubscriberSubscriptions = existingSubscriberSubscriptions;
    const pricingOption = new SubscriptionPricingOption();
    pricingOption.id = selectedSubscriberSubscription.planPriceId;
    pricingOption.name = selectedSubscriberSubscription.plan;
    pricingOption.periodUnit = selectedSubscriberSubscription.billingPeriodUnit;
    pricingOption.status = selectedSubscriberSubscription.status;
    pricingOption.price = selectedSubscriberSubscription.planPrice;
    this.viewModel.subscriptionPlanToAddDiscount = selectedSubscriberSubscription;
    this.viewModel.selectedPlanPricingOptions$.next([pricingOption]);
    this.googleAnalyticsService.trackEvent('account_modal', 'account_modal_appeared', 'Edit Discounts');
  }

  cancel() {
    this.activeModal.dismiss();
  }

  backClicked() {
    switch (this.viewModel.authFlow) {
      case AuthFlow.SignUp:
        this.signUpFormComponent.backClicked();
        break;
      default:
        this.secondaryButtonClicked.emit();
        break;
    }
  }
}
