/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, of, pipe} from 'rxjs';
import {SessionService} from '../services/session-service';
import {delay, map, shareReplay, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {BaseDomainModel} from '../models/base/base-domain-model';
import '../utils/observable.extensions';
import {LeagueApi} from '../api/league-api';
import {EventApi} from '../api/event-api';
import {ImageApi} from '../api/image-api';
import {ImageSize} from '../models/enum/dto/image-size.enum';
import {VenueApi} from '../api/venue-api';
import {Venue} from '../models/resources/venue';
import {HydratedLeague} from '../models/resources/hydrated-league';
import {HydratedEvent} from '../models/resources/hydrated-event';
import {HydratedTeam} from '../models/resources/hydrated-team';
import {TeamApi} from '../api/team-api';
import {GenerateUploadUrlRequest} from '../models/image/requests/generate-upload-url-request';
import {MediaType} from '../models/enum/dto/media-type.enum';
import {TeamFormObject} from '../models/resources/team-form-object';
import {Team} from '../models/resources/team';
import {LeagueFormObject} from '../models/resources/league-form-object';
import {EventFormObject} from '../models/resources/event-form-object';
import {League} from '../models/resources/league';
import {Event} from '../models/resources/event';
import {TeamId} from '../models/resources/teamId';
import {VenueId} from '../models/resources/venue-id';
import {HydratedVenue} from '../models/resources/hydrated-venue';
import {VenueStream} from '../models/resources/venue-stream';
import {VenueFormObject} from '../models/resources/venue-form-object';
import {BannerAdvertisementFormObject} from '../models/resources/banner-ad-form-object';
import {BannerAdvertisement} from '../models/resources/banner-advertisement';
import {CardImageFormObject} from '../models/resources/card-image-form-object';
import {SafeResourceUrl} from '@angular/platform-browser';
import {SeasonApi} from '../api/season-api';
import {Season} from '../models/resources/season';
import {SeasonFormObject} from '../models/resources/season-form-object';
import {PlanApi} from '../api/plan-api';
import {Plan} from '../models/resources/plan';
import {PlanFormObject} from '../models/resources/plan-form-object';
import {PixellotVenues} from '../models/resources/pixellot-venues';
import {PixellotVenuesApi} from '../api/pixellot-venues-api';
import {CameraSystemApi} from '../api/camera-system-api';
import {CameraSystem} from '../models/resources/camera-system';
import {AccountDomainModel} from './account-domain-model';
import { CameraStream } from '../models/resources/camera-stream';
import { LeagueStream } from '../models/resources/league-stream';
import { EventStream } from '../models/resources/event-stream';
import { contributor } from '../models/resources/contributor';
import { CreateContributorRequest } from '../models/account/requests/create-contributor-request';
import { qrCode } from '../models/resources/qrCode';
import { CreateQrCodeRequest } from '../models/account/requests/create-qrCode-request';




@Injectable({
  providedIn: 'root'
})
export class ResourceDomainModel extends BaseDomainModel {


  refreshHydratedLeaguesSubject = new BehaviorSubject<void>(null);
  activeHydratedLeagues$ = this.refreshHydratedLeaguesSubject.pipe(
    switchMap(() => this.getHydratedLeagues(true)),
    shareReplay(1));
  allHydratedLeagues$ = this.refreshHydratedLeaguesSubject.pipe(
    switchMap(() => this.getHydratedLeagues(false)),
    shareReplay(1));
  allHydratedLeaguesForAdminModules$ = this.refreshHydratedLeaguesSubject.pipe(
      switchMap(() => this.getHydratedLeaguesForAdminModule(false)),
      shareReplay(1));


  refreshHydratedEventsSubject = new BehaviorSubject<void>(null);
  activeHydratedEvents$ = this.refreshHydratedEventsSubject.pipe(
    switchMap(() => this.getHydratedEvents(true)),
    shareReplay(1));
  allHydratedEvents$ = this.refreshHydratedEventsSubject.pipe(
    switchMap(() => this.getHydratedEvents(false)),
    shareReplay(1));


  refreshHydratedTeamsSubject = new BehaviorSubject<void>(null);
  activeHydratedTeams$ = this.refreshHydratedTeamsSubject.pipe(
    switchMap(() => this.getHydratedTeams(true)),
    shareReplay(1));
  allHydratedTeams$ = this.refreshHydratedTeamsSubject.pipe(
    switchMap(() =>this.getHydratedTeams(false)),
    shareReplay(1));
  activeHydratedTeamsForCards$ = this.refreshHydratedTeamsSubject.pipe(
      switchMap(() => this.getHydratedTeams(true,true)),
      shareReplay(1));

  refreshVenuesSubject = new BehaviorSubject<void>(null);
  venues$ = this.refreshVenuesSubject.pipe(
    switchMap(() => this.getHydratedVenues()),
    shareReplay(1));
  activeHydratedVenues$ = this.venues$.pipe(
    map(venues => venues.filter(v => v.active))
  );


  constructor(
    private sessionService: SessionService,
    private leagueApi: LeagueApi,
    private eventApi: EventApi,
    private venueApi: VenueApi,
    private teamApi: TeamApi,
    private imageApi: ImageApi,
    private seasonApi: SeasonApi,
    private planApi: PlanApi,
    private pixellotVenuesApi: PixellotVenuesApi,
    private cameraSystemApi: CameraSystemApi,
    private accountDomainModel: AccountDomainModel,

  ) {
    super();
    this.init();
  }

  public init() {
  }

  // Venues

  getHydratedVenues(): Observable<HydratedVenue[]> {
    return this.venueApi.getHydratedVenues()
      .pipe(tap(venues => venues.forEach(v => {
        v.imgSrc$ = this.imageApi.getHydratedVenueImage(v, ImageSize.Medium);
      })));
  }

  getHydratedVenuesByIds(venueIds: number[]): Observable<HydratedVenue[]> {
    if (venueIds?.length > 0) {
      return forkJoin(venueIds.map(vId => this.getHydratedVenue(vId)));
    }
    return of([]);
  }

  getHydratedVenue(venueId: number): Observable<HydratedVenue> {
    return this.venueApi.getHydratedVenue(venueId).pipe(tap(v => {
      v.imgSrc$ = this.imageApi.getHydratedVenueImage(v, ImageSize.Medium);
      v.associatedTeams?.forEach(t => this.loadTeamImages(t));
    }));
  }

  getPixellotVenues(): Observable<PixellotVenues[]> {
    return this.pixellotVenuesApi.getPixellotVenues();
  }

  saveVenue(venueFormObject: VenueFormObject): Observable<any> {
    const venue = Object.assign(new Venue(), venueFormObject.venue);
    const saveReq = !venue.id ?
      this.venueApi.createVenue(venue) :
      this.venueApi.updateVenue(venue);
    return saveReq.pipe(
      switchMap(updatedVenue =>
        forkJoin([
          this.removeVenueImageRequest(venueFormObject, updatedVenue),
          this.createVenueImageRequest(venueFormObject, updatedVenue)
        ])
      ),
      tap(() => this.refreshVenuesSubject.next()),
    );
  }

  saveVenueStream(venueStream: VenueStream, updatedVenueId: number): Observable<VenueStream> {
    if (venueStream.itemCreated) {
      return this.venueApi.createVenueStream(updatedVenueId, venueStream);
    } else if (venueStream.itemDeleted) {
      return this.venueApi.deleteVenueStream(updatedVenueId, venueStream.id);
    } else if (venueStream.itemChanged) {
      return this.venueApi.updateVenueStream(updatedVenueId, venueStream);
    }
    return of(null);
  }

  createVenueImageRequest(venueFormObject: VenueFormObject, updatedVenue: Venue): Observable<any> {
    if (!venueFormObject.imageToUpload) {
      return of(null);
    }

    const uploadLogoReq = new GenerateUploadUrlRequest();
    uploadLogoReq.mediaType = MediaType.PNG;
    uploadLogoReq.fileName = new Date().getTime() + '.png';
    return this.imageApi.createVenueImage(updatedVenue.id, uploadLogoReq).pipe(switchMap(uploadLogoAsset => {
      const uploadUrl = uploadLogoAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, venueFormObject.imageToUpload.toString(), uploadLogoReq.fileName)
        .pipe(tap(() => this.imageApi.imageUploadSuccessful('venue', updatedVenue.id.toString(), venueFormObject.imageToUpload)));
    }));
  }

  removeVenueImageRequest(venueFormObject: VenueFormObject, updatedVenue: Venue): Observable<any> {
    if (!venueFormObject.deleteImageId) {
      return of(null);
    }
    return this.venueApi.deleteVenueImage(updatedVenue.id, venueFormObject.deleteImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('venue', updatedVenue.id.toString());
      }));
  }

  // Teams

  getTeamsWithSportId(sportId: number): Observable<Team[]> {
    return this.teamApi.getTeamsWithSportId(sportId);
  }

  getHydratedTeams(activeOnly: boolean,isForCards: boolean=false): Observable<HydratedTeam[]> {
    return this.teamApi.getHydratedTeams(activeOnly).pipe(tap(teams => teams.forEach(t => this.loadTeamImages(t,isForCards))));
  }

  getHydratedTeam(teamId: number): Observable<HydratedTeam> {
    return this.teamApi.getHydratedTeam(teamId).pipe(tap(t => this.loadTeamImages(t)));
  }

  saveLeagueVenueAssociation(associatedVenue: Venue, leagueId: number): Observable<VenueId> {
    if (associatedVenue.itemCreated && !associatedVenue.itemDeleted) {
      return this.leagueApi.addLeagueVenueAssociation(leagueId, associatedVenue.id);
    } else if (associatedVenue.itemDeleted) {
      return this.leagueApi.deleteLeagueVenueAssociation(leagueId, associatedVenue.id).pipe(map(() => null));
    }
    return of(null);
  }

  saveEventVenueAssociation(associatedVenue: Venue, eventId: number): Observable<VenueId> {
    if (associatedVenue.itemCreated && !associatedVenue.itemDeleted) {
      return this.eventApi.addEventVenueAssociation(eventId, associatedVenue.id);
    } else if (associatedVenue.itemDeleted) {
      return this.eventApi.deleteEventVenueAssociation(eventId, associatedVenue.id).pipe(map(() => null));
    }
    return of(null);
  }

  saveVenueTeamAssociation(associatedTeam: Team, venueId: number): Observable<TeamId> {
    if (associatedTeam.itemCreated && !associatedTeam.itemDeleted) {
      return this.venueApi.addVenueTeamAssociation(venueId, associatedTeam.id);
    } else if (associatedTeam.itemDeleted) {
      return this.venueApi.deleteVenueTeamAssociation(venueId, associatedTeam.id).pipe(map(() => null));
    }
    return of(null);
  }

  saveLeagueTeamAssociation(associatedTeam: Team, leagueId: number): Observable<TeamId> {
    if (associatedTeam.itemCreated && !associatedTeam.itemDeleted) {
      return this.leagueApi.addLeagueTeamAssociation(leagueId, associatedTeam.id);
    } else if (associatedTeam.itemDeleted) {
      return this.leagueApi.deleteLeagueTeamAssociation(leagueId, associatedTeam.id).pipe(map(() => null));
    }
    return of(null);
  }

  saveEventTeamAssociation(associatedTeam: Team, eventId: number): Observable<TeamId> {
    if (associatedTeam.itemCreated && !associatedTeam.itemDeleted) {
      return this.eventApi.addEventTeamAssociation(eventId, associatedTeam.id);
    } else if (associatedTeam.itemDeleted) {
      return this.eventApi.deleteEventTeamAssociation(eventId, associatedTeam.id).pipe(map(() => null));
    }
    return of(null);
  }

  saveLeagueTeam(teamFormObject: TeamFormObject, leagueId: number): Observable<any> {
    return this.saveTeam(teamFormObject).pipe(switchMap(t => {
      t.itemCreated = teamFormObject.itemCreated;
      return this.saveLeagueTeamAssociation(t, leagueId);
    }));
  }

  saveEventTeam(teamFormObject: TeamFormObject, eventId: number): Observable<any> {
    return this.saveTeam(teamFormObject).pipe(switchMap(t => {
      t.itemCreated = teamFormObject.itemCreated;
      return this.saveEventTeamAssociation(t, eventId);
    }));
  }

  saveVenueTeam(teamFormObject: TeamFormObject, venueId: number): Observable<any> {
    return this.saveTeam(teamFormObject).pipe(switchMap(t => {
      t.itemCreated = teamFormObject.itemCreated;
      return this.saveVenueTeamAssociation(t, venueId);
    }));
  }

  saveTeam(teamFormObject: TeamFormObject): Observable<Team> {
    const team = this.getTeamFromTeamFormObject(teamFormObject);

    if (teamFormObject.itemDeleted) {
      return this.teamApi.deleteTeam(team.id);
    }

    let saveReq = of(team);
    if (teamFormObject.itemCreated) {
      saveReq = this.teamApi.createTeam(team);
    } else if (teamFormObject.itemChanged) {
      saveReq = this.teamApi.updateTeam(team);
    }
    return saveReq.pipe(
      switchMap(updatedTeam =>
        forkJoin([
          this.removeTeamImageRequest(teamFormObject, updatedTeam),
          this.createTeamImageRequest(teamFormObject, updatedTeam),
          this.removeTeamBannerImageRequest(teamFormObject, updatedTeam),
          this.createTeamBannerImageRequest(teamFormObject, updatedTeam),
          this.saveTeamCardImages(teamFormObject, updatedTeam),
        ]).pipe(map(() => updatedTeam)),
      ),
      tap(() => this.refreshHydratedTeamsSubject.next()),
    );
  }

  saveTeamCardImages(teamFormObject: TeamFormObject, updatedTeam: Team): Observable<any> {
    const obs: Observable<any>[] = [];
    teamFormObject.cardImageFormObjects?.forEach(cardImageFormObject => {
      if (!!cardImageFormObject.deleteImageId) {
        obs.push(this.removeTeamCardImageRequest(cardImageFormObject, updatedTeam));
      } else if (!!cardImageFormObject?.imageToUpload) {
        obs.push(this.createTeamCardImageRequest(cardImageFormObject, updatedTeam));
      }
    });
    return obs?.length > 0 ? forkJoin(obs) : of(null);
  }

  createTeamImageRequest(teamFormObject: TeamFormObject, updatedTeam: Team): Observable<any> {
    if (!teamFormObject.imageToUpload) {
      return of(null);
    }

    const uploadLogoReq = new GenerateUploadUrlRequest();
    uploadLogoReq.mediaType = MediaType.PNG;
    uploadLogoReq.fileName = new Date().getTime() + '.png';
    return this.imageApi.createTeamImage(updatedTeam.id, uploadLogoReq).pipe(switchMap(uploadLogoAsset => {
      const uploadUrl = uploadLogoAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, teamFormObject.imageToUpload.toString(), uploadLogoReq.fileName)
        .pipe(tap(() => this.imageApi.imageUploadSuccessful('team', updatedTeam.id.toString(), teamFormObject.imageToUpload)));
    }));
  }

  removeTeamImageRequest(teamFormObject: TeamFormObject, updatedTeam: Team): Observable<any> {
    if (!teamFormObject.deleteImageId) {
      return of(null);
    }
    return this.teamApi.deleteTeamImage(updatedTeam.id, teamFormObject.deleteImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('team', updatedTeam.id.toString());
      }));
  }

  createTeamCardImageRequest(cardImageFormObject: CardImageFormObject, updatedTeam: Team): Observable<any> {
    if (!cardImageFormObject.imageToUpload) {
      return of(null);
    }

    const uploadLogoReq = new GenerateUploadUrlRequest();
    uploadLogoReq.mediaType = cardImageFormObject.imageToUploadFormat as MediaType;
    uploadLogoReq.fileName = new Date().getTime() + cardImageFormObject.imageToUploadFormat.replace('image/', '.');
    return this.imageApi.createTeamCardImage(updatedTeam.id, uploadLogoReq).pipe(switchMap(uploadLogoAsset => {
      const uploadUrl = uploadLogoAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, cardImageFormObject.imageToUpload.toString(), uploadLogoReq.fileName)
        .pipe(tap(() => this.imageApi.imageUploadSuccessful('team-card-image', uploadLogoAsset.id, cardImageFormObject.imageToUpload)));
    }));
  }

  removeTeamCardImageRequest(cardImageFormObject: CardImageFormObject, updatedTeam: Team): Observable<any> {
    if (!cardImageFormObject.deleteImageId) {
      return of(null);
    }
    return this.teamApi.deleteTeamCardImage(updatedTeam.id, cardImageFormObject.deleteImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('team-card-image', cardImageFormObject.deleteImageId);
      }));
  }

  createTeamBannerImageRequest(teamFormObject: TeamFormObject, updatedTeam: Team): Observable<any> {
    if (!teamFormObject.bannerImageToUpload) {
      return of(null);
    }

    const uploadReq = new GenerateUploadUrlRequest();
    uploadReq.mediaType = teamFormObject.bannerImageToUploadFormat as MediaType;
    uploadReq.fileName = new Date().getTime() + teamFormObject.bannerImageToUploadFormat.replace('image/', '.');
    return this.imageApi.createTeamBannerImage(updatedTeam.id, uploadReq).pipe(switchMap(uploadLogoAsset => {
      const uploadUrl = uploadLogoAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, teamFormObject.bannerImageToUpload.toString(), uploadReq.fileName)
        .pipe(tap(() => this.imageApi.imageUploadSuccessful('team-banner', updatedTeam.id.toString(), teamFormObject.bannerImageToUpload)));
    }));
  }

  removeTeamBannerImageRequest(teamFormObject: TeamFormObject, updatedTeam: Team): Observable<any> {
    if (!teamFormObject.deleteBannerImageId) {
      return of(null);
    }
    return this.teamApi.deleteTeamBannerImage(updatedTeam.id, teamFormObject.deleteBannerImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('team-banner', updatedTeam.id.toString());
      }));
  }

  getTeamFromTeamFormObject(teamFormObject: TeamFormObject): Team {
    const team = Object.assign(new Team(), teamFormObject.team);
    team.imgSrc$ = undefined;
    return team;
  }

  // Events

  private getHydratedEvents(activeOnly: boolean): Observable<HydratedEvent[]> {
    return this.eventApi.getHydratedEvents(activeOnly)
      .pipe(tap(events => events.forEach(l => {
        l.imgSrc$ = this.imageApi.getHydratedEventImage(l, ImageSize.Medium);
        l.bannerSrc$ = this.imageApi.getHydratedEventBannerImage(l, ImageSize.Medium);
      })));
  }

  getHydratedEvent(eventId: number): Observable<HydratedEvent> {
    return this.eventApi.getHydratedEvent(eventId).pipe(tap(l => {
      l.imgSrc$ = this.imageApi.getHydratedEventImage(l, ImageSize.Medium);
      l.associatedTeams?.forEach(t => this.loadTeamImages(t));
      l.bannerSrc$ = this.imageApi.getHydratedEventBannerImage(l, ImageSize.Medium);
      l.advertisementBanners?.forEach(b => {
        b.imgSrc$ = this.imageApi.getHydratedEventBannerAdvertisementImage(b, ImageSize.Medium);
      });
    }));
  }

  saveEvent(eventFormObject: EventFormObject): Observable<any> {
    const event = this.getEventFromEventFormObject(eventFormObject);
    const saveReq = !eventFormObject.event.id ?
      this.eventApi.createEvent(event) :
      this.eventApi.updateEvent(event);
    return saveReq.pipe(
      switchMap(updatedEvent =>
        forkJoin([
          this.removeEventImageRequest(eventFormObject, updatedEvent),
          this.removeEventBannerImageRequest(eventFormObject, updatedEvent),
          this.createEventImageRequest(eventFormObject, updatedEvent),
          this.createEventBannerImage(eventFormObject, updatedEvent),
        ])
      ),
      tap(() => {
        this.refreshHydratedEventsSubject.next();
      })
    );
  }

  saveEventStream(eventStream: EventStream): Observable<EventStream> {
   let saveReq;
   if(eventStream.id>0)
   {
       saveReq = this.eventApi.updateEventStream(eventStream);
   }
   else{

        saveReq = this.eventApi.createEventStream(eventStream);
   }
   return saveReq;
  }

  createEventImageRequest(eventFormObject: EventFormObject, updatedEvent: Event): Observable<any> {
    if (!eventFormObject.imageToUpload) {
      return of(null);
    }
    const uploadLogoReq = new GenerateUploadUrlRequest();
    uploadLogoReq.mediaType = MediaType.PNG;
    uploadLogoReq.fileName = new Date().getTime() + '.png';
    return this.imageApi.createEventImage(updatedEvent.id, uploadLogoReq,1).pipe(switchMap(uploadLogoAsset => {
      const uploadUrl = uploadLogoAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, eventFormObject.imageToUpload.toString(), uploadLogoReq.fileName)
        .pipe(tap(() => this.imageApi.imageUploadSuccessful('event', updatedEvent.id.toString(), eventFormObject.imageToUpload)));
    }));
  }

  createEventBannerImage(eventFormObject: EventFormObject, updatedEvent: Event): Observable<any> {
    if (!eventFormObject.bannerImageToUpload) {
      return of(null);
    }
    const uploadBannerReq = new GenerateUploadUrlRequest();
    uploadBannerReq.mediaType = MediaType.JPG;
    uploadBannerReq.fileName = new Date().getTime() + '.jpg';
    return this.imageApi.createEventImage(updatedEvent.id, uploadBannerReq,2).pipe(switchMap(uploadLogoAsset => {
      const uploadUrl = uploadLogoAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, eventFormObject.bannerImageToUpload.toString(), uploadBannerReq.fileName)
        .pipe(tap(() => this.imageApi.imageUploadSuccessful('event-banner-image', updatedEvent.id.toString(), eventFormObject.bannerImageToUpload)));
    }));
  }

  removeEventImageRequest(eventFormObject: EventFormObject, updatedEvent: Event): Observable<any> {
    if (!eventFormObject.deleteImageId) {
      return of(null);
    }
    return this.eventApi.deleteEventImage(updatedEvent.id, eventFormObject.deleteImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('event', updatedEvent.id.toString());
      }));
  }

  removeEventBannerImageRequest(eventFormObject: EventFormObject, updatedEvent: Event): Observable<any> {
    if (!eventFormObject.deleteBannerImageId) {
      return of(null);
    }
    return this.eventApi.deleteEventImage(updatedEvent.id, eventFormObject.deleteBannerImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('event-banner-image', updatedEvent.id.toString());
      }));
  }

  getEventFromEventFormObject(eventFormObject: EventFormObject): Event {
    const event = Object.assign(new Event(), eventFormObject.event);
    event.imgSrc$ = undefined;
    return event;
  }

  getEventStreamFromEventFormObject(eventFormObject: EventFormObject): EventStream {
    const eventStream = Object.assign(new EventStream(), eventFormObject.eventStream);
    return eventStream;
  }

  saveEventBannerAdvertisement(eventId: number, bannerAdvertisementFormObject: BannerAdvertisementFormObject): Observable<any> {
    if (!bannerAdvertisementFormObject.itemDeleted && bannerAdvertisementFormObject.itemCreated) {
      return this.createEventBannerAdvertisementImageRequest(eventId, bannerAdvertisementFormObject);
    } else if (bannerAdvertisementFormObject.itemDeleted) {
      return this.removeEventBannerAdvertisementImageRequest(eventId, bannerAdvertisementFormObject);
    }
    return of(null);
  }

  createEventBannerAdvertisementImageRequest(eventId: number, bannerAdvertisementFormObject: BannerAdvertisementFormObject): Observable<any> {
    if (!bannerAdvertisementFormObject.imageToUpload) {
      return of(null);
    }

    const uploadReq = bannerAdvertisementFormObject.bannerAd;
    uploadReq.mediaType = bannerAdvertisementFormObject.imageToUploadFormat as MediaType;
    uploadReq.fileName = new Date().getTime() + bannerAdvertisementFormObject.imageToUploadFormat.replace('image/', '.');
    return this.imageApi.createEventBannerImage(eventId, uploadReq).pipe(switchMap(uploadedAsset => {
      const uploadUrl = uploadedAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, bannerAdvertisementFormObject.imageToUpload.toString(), uploadReq.fileName)
        .pipe(tap(() => this.imageApi.imageUploadSuccessful('event-banner-ad', uploadedAsset.id.toString(), bannerAdvertisementFormObject.imageToUpload)));
    }));
  }

  removeEventBannerAdvertisementImageRequest(eventId: number, bannerAdvertisementFormObject: BannerAdvertisementFormObject): Observable<any> {
    if (!bannerAdvertisementFormObject.deleteImageId) {
      return of(null);
    }
    return this.eventApi.deleteEventBannerAdvertisement(eventId, bannerAdvertisementFormObject.deleteImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('event-banner-ad', bannerAdvertisementFormObject.deleteImageId.toString());
      }));
  }

  getEventStream(eventId: number): Observable<EventStream> {
    return this.eventApi.getEventStream(eventId);
  }

  updateEventStream(stream: EventStream) {
    return this.eventApi.updateEventStream(stream);
  }

  createEventStream(stream: EventStream): Observable<EventStream> {
    return this.eventApi.createEventStream(stream);
  }

  // Leagues
private getHydratedLeaguesForAdminModule(activeOnly: boolean): Observable<HydratedLeague[]>{
  return this.leagueApi.getHydratedLeagues(activeOnly)
          .pipe(tap(leagues => leagues.forEach(l => {
           l.imgSrc$ = this.imageApi.getHydratedLeagueImage(l, ImageSize.Medium);
           l.bannerSrc$ = this.imageApi.getHydratedLeagueBannerImage(l, ImageSize.Medium);
            })));
}
  private getHydratedLeagues(activeOnly: boolean): Observable<HydratedLeague[]> {
    const isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));
    return isSignedIn$.pipe(
      switchMap(isSignedIn => {
        if (isSignedIn) {
          return this.accountDomainModel.subscriberSubscriptions$.pipe(
            switchMap(subscriptionPlans => {
              const privatePlansExist = subscriptionPlans.some(plan =>
                plan.planId.startsWith('Private-'));

              if (privatePlansExist) {
                return this.leagueApi.getHydratedLeagues(activeOnly)
                .pipe(tap(leagues => leagues.forEach(l => {
                  l.imgSrc$ = this.imageApi.getHydratedLeagueImage(l, ImageSize.Medium);
                }))
                );
              } else {
                return this.leagueApi.getHydratedLeagues(activeOnly)
                  .pipe(tap(leagues => leagues.forEach(l => {
                    l.imgSrc$ = this.imageApi.getHydratedLeagueImage(l, ImageSize.Medium);
                  })),map(leagues=>leagues.filter(l=>!l.privateAccess)));
              }
            })
          );
        } else {
                return this.leagueApi.getHydratedLeagues(activeOnly)
                    .pipe(tap(leagues => leagues.forEach(l => {
                      l.imgSrc$ = this.imageApi.getHydratedLeagueImage(l, ImageSize.Medium);
                    })),map(leagues=>leagues.filter(l=>!l.privateAccess)));
        }
      })
    );
  }

  getHydratedLeague(leagueId: number): Observable<HydratedLeague> {
    return this.leagueApi.getHydratedLeague(leagueId).pipe(tap(l => {
      l.imgSrc$ = this.imageApi.getHydratedLeagueImage(l, ImageSize.Medium);
      l.bannerSrc$ = this.imageApi.getHydratedLeagueBannerImage(l, ImageSize.Medium);
      l.associatedTeams?.forEach(t => this.loadTeamImages(t));
      l.advertisementBanners?.forEach(b => {
        b.imgSrc$ = this.imageApi.getHydratedLeagueBannerAdvertisementImage(b, ImageSize.Medium);
      });
    }));
  }



  getLeagues(activeOnly: boolean): Observable<League[]> {
    return this.leagueApi.getLeagues(activeOnly);
  }

  saveLeague(leagueFormObject: LeagueFormObject): Observable<any> {
    const league = this.getLeagueFromLeagueFormObject(leagueFormObject);
    const saveReq = !leagueFormObject.league.id ?
      this.leagueApi.createLeague(league) :
      this.leagueApi.updateLeague(league);
    return saveReq.pipe(
      switchMap(updatedLeague =>
        forkJoin([
          this.removeLeagueImageRequest(leagueFormObject, updatedLeague),
          this.removeLeagueBannerImageRequest(leagueFormObject, updatedLeague),
          this.createLeagueImageRequest(leagueFormObject, updatedLeague),
          this.createLeagueBannerImage(leagueFormObject,updatedLeague),
        ])
      ),
      tap(() => {
        this.refreshHydratedLeaguesSubject.next();
      })
    );
  }
  removeLeagueBannerImageRequest(leagueFormObject: LeagueFormObject, updatedLeague: League): any {
    if (!leagueFormObject.deleteBannerImageId) {
      return of(null);
    }
    return this.leagueApi.deleteLeagueImage(updatedLeague.id, leagueFormObject.deleteBannerImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('league-banner-image', updatedLeague.id.toString());
      }));
  }

  saveLeagueStream(leagueStream: LeagueStream): Observable<LeagueStream> {
    let saveReq ;
    if(leagueStream.id>0)
    {
      saveReq = this.leagueApi.updateLeagueStream(leagueStream);
    }
    else{
      saveReq = this.leagueApi.createLeagueStream(leagueStream);
    }
    return saveReq;
  }

  createLeagueBannerImage(leagueFormObject: LeagueFormObject, updatedLeague: League): Observable<any> {
    if (!leagueFormObject.bannerImageToUpload) {
      return of(null);
    }

    const uploadBannerReq = new GenerateUploadUrlRequest();
    uploadBannerReq.mediaType = MediaType.JPG;
    uploadBannerReq.fileName = new Date().getTime() + '.jpg';
    return this.imageApi.createLeagueImage(updatedLeague.id, uploadBannerReq,2).pipe(switchMap(uploadLogoAsset => {
      const uploadUrl = uploadLogoAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, leagueFormObject.bannerImageToUpload.toString(), uploadBannerReq.fileName)
        .pipe(tap(() => this.imageApi.imageUploadSuccessful('league-banner-image', updatedLeague.id.toString(), leagueFormObject.bannerImageToUpload)));
    }));
  }


  createLeagueImageRequest(leagueFormObject: LeagueFormObject, updatedLeague: League): Observable<any> {
    if (!leagueFormObject.imageToUpload) {
      return of(null);
    }

    const uploadLogoReq = new GenerateUploadUrlRequest();
    uploadLogoReq.mediaType = MediaType.PNG;
    uploadLogoReq.fileName = new Date().getTime() + '.png';
    return this.imageApi.createLeagueImage(updatedLeague.id, uploadLogoReq,1).pipe(switchMap(uploadLogoAsset => {
      const uploadUrl = uploadLogoAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, leagueFormObject.imageToUpload.toString(), uploadLogoReq.fileName)
        .pipe(tap(() => this.imageApi.imageUploadSuccessful('league', updatedLeague.id.toString(), leagueFormObject.imageToUpload)));
    }));
  }

  removeLeagueImageRequest(leagueFormObject: LeagueFormObject, updatedLeague: League): Observable<any> {
    if (!leagueFormObject.deleteImageId) {
      return of(null);
    }
    return this.leagueApi.deleteLeagueImage(updatedLeague.id, leagueFormObject.deleteImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('league', updatedLeague.id.toString());
      }));
  }

  getLeagueFromLeagueFormObject(leagueFormObject: LeagueFormObject): League {
    const league = Object.assign(new League(), leagueFormObject.league);
    league.imgSrc$ = undefined;
    return league;
  }

  getLeagueStreamFromLeagueFormObject(leagueFormObject: LeagueFormObject): LeagueStream {
    const leagueStream = Object.assign(new LeagueStream(), leagueFormObject.leagueStream);
    return leagueStream;
  }

  saveLeagueBannerAdvertisement(leagueId: number, bannerAdvertisementFormObject: BannerAdvertisementFormObject): Observable<any> {
    if (!bannerAdvertisementFormObject.itemDeleted && bannerAdvertisementFormObject.itemCreated) {
      return this.createLeagueBannerAdvertisementImageRequest(leagueId, bannerAdvertisementFormObject);
    } else if (bannerAdvertisementFormObject.itemDeleted) {
      return this.removeLeagueBannerAdvertisementImageRequest(leagueId, bannerAdvertisementFormObject);
    }
    return of(null);
  }

  getBannerAdFromBannerAdFormObject(bannerAdvertisementFormObject: BannerAdvertisementFormObject): BannerAdvertisement {
    const bannerAd = Object.assign(new BannerAdvertisement(), bannerAdvertisementFormObject.bannerAd);
    bannerAd.imgSrc$ = undefined;
    return bannerAd;
  }

  createLeagueBannerAdvertisementImageRequest(leagueId: number, bannerAdvertisementFormObject: BannerAdvertisementFormObject): Observable<any> {
    if (!bannerAdvertisementFormObject.imageToUpload) {
      return of(null);
    }

    const uploadReq = bannerAdvertisementFormObject.bannerAd;
    uploadReq.mediaType = bannerAdvertisementFormObject.imageToUploadFormat as MediaType;
    uploadReq.fileName = new Date().getTime() + bannerAdvertisementFormObject.imageToUploadFormat.replace('image/', '.');
    return this.imageApi.createLeagueBannerImage(leagueId, uploadReq).pipe(switchMap(uploadedAsset => {
      const uploadUrl = uploadedAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, bannerAdvertisementFormObject.imageToUpload.toString(), uploadReq.fileName)
        .pipe(tap(() => this.imageApi.imageUploadSuccessful('league-banner-ad', uploadedAsset.id.toString(), bannerAdvertisementFormObject.imageToUpload)));
    }));
  }

  removeLeagueBannerAdvertisementImageRequest(leagueId: number, bannerAdvertisementFormObject: BannerAdvertisementFormObject): Observable<any> {
    if (!bannerAdvertisementFormObject.deleteImageId) {
      return of(null);
    }
    return this.leagueApi.deleteLeagueBannerAdvertisement(leagueId, bannerAdvertisementFormObject.deleteImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('league-banner-ad', bannerAdvertisementFormObject.deleteImageId.toString());
      }));
  }

  loadTeamImages(t: HydratedTeam,isForCards: boolean=false) {
    if(isForCards){
      t.cardImgSrcMap = new Map<string, Observable<string | SafeResourceUrl>>();
      t.cardImages?.forEach(i => {
        t.cardImgSrcMap.set(i.id, this.imageApi.getHydratedTeamCardImage(i, ImageSize.Small));
      });
    }
    else{
      t.imgSrc$ = this.imageApi.getHydratedTeamImage(t, ImageSize.Medium);
      t.bannerImgSrc$ = this.imageApi.getHydratedTeamBannerImage(t, ImageSize.Large);
      t.cardImgSrcMap = new Map<string, Observable<string | SafeResourceUrl>>();
      t.cardImages?.forEach(i => {
        t.cardImgSrcMap.set(i.id, this.imageApi.getHydratedTeamCardImage(i, ImageSize.Small));
      });
    }
  }

  getLeagueStream(leagueId: number) {
    return this.leagueApi.getLeagueStream(leagueId);
  }

  updateLeagueStream(stream: LeagueStream) {
    return this.leagueApi.updateLeagueStream(stream);
  }

  createLeagueStream(stream: LeagueStream) {
    return this.leagueApi.createLeagueStream(stream);
  }

  // Plans
  getPlans(activeOnly: boolean): Observable<any> {
    return this.planApi.getPlans(activeOnly);
  }

  getPlanById(planId: number): Observable<Plan> {
    return this.planApi.getPlanById(planId);
  }

  savePlan(planFormObject: PlanFormObject): Observable<any> {
    const plan = planFormObject.getPlanCopy();
    const saveReq = !planFormObject.plan?.id ?
      this.planApi.createPlan(plan) :
      this.planApi.updatePlan(planFormObject.planId, plan);
    return saveReq;
  }

  // Seasons
  saveLeagueSeason(seasonFormObject: SeasonFormObject): Observable<any> {
    const season = seasonFormObject.getSeason();
    if(seasonFormObject.season?.id ){
      season.timeStampVal=seasonFormObject.timeStampVal;
    }
    const saveReq = !seasonFormObject.season?.id ?
      this.seasonApi.createLeagueSeasons(seasonFormObject.leagueId, season) :
      this.seasonApi.updateLeagueSeasons(seasonFormObject.leagueId, seasonFormObject.seasonId, season);
    return saveReq.pipe(
      tap(() => {
        this.refreshHydratedLeaguesSubject.next();
      })
    );
  }

  deleteLeagueSeason(seasonFormObject: SeasonFormObject, leagueId): Observable<any> {
    const season = seasonFormObject.getSeason();
    const deleteReq = this.seasonApi.deleteLeagueSeasons(leagueId, seasonFormObject.seasonId);
    return deleteReq.pipe(
      tap(() => {
        this.refreshHydratedLeaguesSubject.next();
      })
    );
  }

  // Camera Systems
  saveCameraSystem(cameraSystemFormObject: CameraSystem): Observable<any> {
    const saveReq = !cameraSystemFormObject?.id ?
      this.cameraSystemApi.createCameraSystem(cameraSystemFormObject) :
      this.cameraSystemApi.updateCameraSystem(cameraSystemFormObject.id, cameraSystemFormObject);
    return saveReq;
  }

  updateCameraSystemTypeConfigurations(cameraSystemTypeId: string, cameraSystemConfig: any): Observable<any> {
    return this.cameraSystemApi.updateCameraSystemTypeConfigurations(cameraSystemTypeId, cameraSystemConfig);
  }

  getCameraSystems(): Observable<CameraSystem[]> {
    return this.cameraSystemApi.getCameraSystem();
  }

  getCameraSystemById(cameraSystemId: string): Observable<CameraSystem> {
    return this.cameraSystemApi.getCameraSystemById(cameraSystemId);
  }

  getCameraSystemTypesConfigurations(cameraSystemTypeId: string): Observable<CameraSystem> {
    return this.cameraSystemApi.getCameraSystemTypeConfigurationsById(cameraSystemTypeId);
  }

  getCameraStreamDetails(isLive: boolean): Observable<CameraStream[]>{
    return this.cameraSystemApi.getCameraStreams(isLive);
  }
  getLeagueContributors(leagueId: number): Observable<contributor[]>{
    return this.leagueApi.getLeagueContributorsForLeague(leagueId);
  }

  getEventContributors(eventId: number): Observable<contributor[]>{
    return this.eventApi.getEventContributors(eventId);
  }

  getLeagueContributorsById(leagueId: number,contributerId: number): Observable<CreateContributorRequest>{
    return this.leagueApi.getLeagueContributorsById(leagueId,contributerId);
  }

  saveContributor(leagueId: number, req: CreateContributorRequest): Observable<contributor> {
    return this.leagueApi.createLeagueContributor(leagueId,req);
  }

  updateContributor(leagueId: number, req: CreateContributorRequest): Observable<contributor> {
    return this.leagueApi.updateLeagueContributor(leagueId,req);
  }

  getEventContributorsById(eventId: number,contributerId: number): Observable<CreateContributorRequest>{
    return this.eventApi.getEventContributorsById(eventId,contributerId);
  }

  saveEventContributor(eventId: number, req: CreateContributorRequest): Observable<contributor> {
    return this.eventApi.createEventContributor(eventId,req);
  }

  updateEventContributor(eventId: number, req: CreateContributorRequest): Observable<contributor> {
    return this.eventApi.updateEventContributor(eventId,req);
  }

  saveLeagueQrCode(leagueId: number, req: CreateQrCodeRequest): Observable<qrCode> {
    return this.leagueApi.createLeagueQrCode(leagueId,req);
  }

  saveEventQrCode(eventId: number, req: CreateQrCodeRequest): Observable<qrCode> {
    return this.eventApi.createEventQrCode(eventId,req);
  }

  getQrCodeById(id: string): Observable<qrCode> {
    return this.leagueApi.getQrCodeById(id);
  }

  downloadQrCode(id: string): Observable<any> {
    return this.imageApi.downloadQrCode(id);
  }

  getLeaguesQrCodes(leagueId: number): Observable<qrCode[]> {
    return this.leagueApi.getLeagueQrCodes(leagueId);
  }

  getEventsQrCodes(eventId: number): Observable<qrCode[]> {
    return this.eventApi.getEventQrCodes(eventId);
  }

}
