import { Component, EventEmitter, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/models/base/base-component';
import { Tabbable } from 'src/app/models/protocols/tabbable';
import { EventDetailsViewModel } from '../event-details-view-model';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { BaseDatatableDataProvider } from 'src/app/models/base/base-datatable-data-provider';
import { DatatableFilter } from 'src/app/models/shared/datatable-filter';
import { DatatableOptions } from 'src/app/models/shared/stylesheet/datatable-options';
import { contributor } from 'src/app/models/resources/contributor';
import { DatatableColumn, DatatableColumnType } from 'src/app/models/shared/stylesheet/datatable-column';
import { DatatableColumnFilter, DatatableColumnFilterListItem } from 'src/app/models/shared/stylesheet/datatable-column-filter';
import { DatatableData } from 'src/app/models/protocols/datatable-data';

@Component({
  selector: 'app-events-contributors',
  templateUrl: './events-contributors.component.html',
  styleUrls: ['./events-contributors.component.scss']
})
export class EventsContributorsComponent extends BaseComponent implements Tabbable{


  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public userDataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();

  constructor( public viewModel: EventDetailsViewModel,
    public router: Router,
    public activatedRoute: ActivatedRoute,) {
    super();
  }

  setupBindings() {
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationStart) {
        this.viewModel.refreshEventContributor$.next();
      }
    });
    this.viewModel.eventContributor$.notNull().subscribe(s => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = s;
        this.updateTableData.next(dataProvider);
      });
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupDatatable();
  }

  setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.datatableOptions.ctaButtonText = $localize`Add New Contributor`;
    this.datatableOptions.ctaButtonClass ='preferred-button';
    this.setDatatableFilter();
  }

  getDatatableColumns(){
    const columns: DatatableColumn[] = [];

    const name=new DatatableColumn(
      'firstName',
      $localize`FirstName`,
      DatatableColumnType.Text,
      (c: contributor): string=>{
        return c.firstName;
      }
    );
    columns.push(name);
    const lastName=new DatatableColumn(
      'lastName',
      $localize`LastName`,
      DatatableColumnType.Text,
      (c: contributor): string=>{
        return c.lastName;
      }
    );
    columns.push(lastName);

    const email=new DatatableColumn(
      'email',
      $localize`Email`,
      DatatableColumnType.Text,
      (c: contributor): string=>{
        return c.email;
      }
    );
    columns.push(email);

    const category=new DatatableColumn(
      'category',
      $localize`Category`,
      DatatableColumnType.Text,
      (c: contributor): string=>{
        return this.findCategory(c);
      }
    );
    columns.push(category);

    const activeContributor=new DatatableColumn(
      'activeContributor',
      $localize`Status`,
      DatatableColumnType.Text,
      (c: contributor): string=>{
        return c.active ? $localize`Active` : $localize`Inactive`;
      }
    );

    activeContributor.className = 'custom-datatable-header text-right mr-2';
    activeContributor.headerClassName = 'custom-datatable-header text-right mr-2';
    activeContributor.columnFilter = this.getStatusColumnFilter();
    activeContributor.isDefaultSortColumn=true;
    columns.push(activeContributor);

    return columns;

  }
  findCategory(c: contributor): string {
    let category='';
    c.contributorCategoryIds.forEach((id)=>{
      if(id===1){
        category+='Camera Setup,';
      }else if(id===2){
        category+='Time Keeper,';
      }else if(id===3){
        category+='Score Keeper,';
      }
    });
    return category.endsWith(',') ? category.slice(0,-1) : category;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchLabelText=$localize` `;
    this.datatableFilter.searchPlaceholderText = $localize`Search by name,email`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }


  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Active`, $localize`Active`),
      new DatatableColumnFilterListItem($localize`Inactive`, $localize`Inactive`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }

  addNewContributor() {
    this.router.navigate([`./addEventContributor`], {relativeTo: this.activatedRoute}).then();
    }

    rowClicked(data: DatatableData) {
      const cont=data as contributor;
      this.router.navigate([`./contributors/${cont.id}`], {relativeTo: this.activatedRoute}).then();
     }

}
