/* eslint-disable max-len */
import {HttpBackend, HttpClient, HttpContext, HttpContextToken, HttpParams} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Deserializable, DeserializeHelper} from '../models/protocols/deserializable';
import {APIRequestType} from '../models/enum/shared/api-request-type.enum';
import {StringifyUtils} from '../utils/stringify-utils';
import {expand, map, reduce, take} from 'rxjs/operators';

export const DEFAULT_PAGINATION_TAKE = 50;
export const IS_MULTI_PART_FORM_DATA = new HttpContextToken<boolean>(() => false);

@Injectable({
  providedIn: 'root'
})
export class ApiClient {
  private backendHttp: HttpClient;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
  ) {
    this.backendHttp = new HttpClient(handler);
  }

  public getStr(url: string, additionalHeaders: any = null): Observable<string> {
    return this.http.get<string>(url, {headers: additionalHeaders});
  }

  public getObj<T extends Deserializable>(respObjectType: new () => T, url: string,
                                          additionalHeaders: any = null): Observable<T> {
    return this.http.get<T>(url, {headers: additionalHeaders}).pipe(map(r => {
      return DeserializeHelper.deserializeToInstance(respObjectType, r);
    }));
  }

  public getArr<T extends Deserializable>(respObjectType: new () => T, url: string,
                                          additionalHeaders: any = null, params: HttpParams = null): Observable<T[]> {
    return this.http.get<T[]>(url, {headers: additionalHeaders, params}).pipe(map(r => {
      return r?.map(rr => DeserializeHelper.deserializeToInstance(respObjectType, rr)) ?? [] as T[];
    }));
  }

  public recursiveGetArr<T extends Deserializable>(
    respObjectType: new () => T,
    route: string,
    additionalHeaders: any = null,
    params: HttpParams = new HttpParams(),
    skip = 0,
    pageTake = DEFAULT_PAGINATION_TAKE
  ): Observable<T[]> {
    let currentSkip = skip;
    let reqParams = params
      .set('skip', skip.toString())
      .set('take', pageTake.toString());

    return this.getArr<T>(respObjectType, route, additionalHeaders, reqParams).pipe(
      take(1),
      expand((res) => {
        if (res && res.length === pageTake) {
          currentSkip += pageTake;
          reqParams = reqParams.set('skip', currentSkip.toString());
          return this.getArr<T>(respObjectType, route, additionalHeaders, reqParams).pipe(take(1));
        } else {
          return EMPTY;
        }
      }),
      reduce((acc, val) => {
        acc.push(...val);
        return acc;
      })
    );
  }

  public getBlob<Blob>(url: string, additionalHeaders: any = null): Observable<Blob> {
    return this.http.get<Blob>(url, {
      headers: additionalHeaders,
      responseType: 'blob' as 'json'
    });
  }

  public putObj<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                          responseType: string = 'json'): Observable<T> {
    return this.http.put<T>(url, JSON.stringify(payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(map(r => {
      return DeserializeHelper.deserializeToInstance(respObjectType, r);
    }));
  }

  public postObj<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                           responseType: string = 'json'): Observable<T> {
    return this.http.post<T>(url, JSON.stringify(payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(map(r => {
      return DeserializeHelper.deserializeToInstance(respObjectType, r);
    }));
  }

  public postVoid(url, payload, additionalHeaders: any = null,
                  responseType: string = 'json'): Observable<void> {
    return this.http.post<void>(url, JSON.stringify(payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    });
  }

  public postFormData<T extends Deserializable>(respObjectType: new () => T, url, payload: FormData, additionalHeaders: any = null,
                                                responseType: string = 'json'): Observable<T> {
    const multiPartContext = new HttpContext().set(IS_MULTI_PART_FORM_DATA, true);
    return this.http.post<T>(url, payload, {
      headers: additionalHeaders,
      responseType: responseType as 'json',
      context: multiPartContext
    }).pipe(map(r => {
      return DeserializeHelper.deserializeToInstance(respObjectType, r);
    }));
  }

  public putFormData<T extends Deserializable>(respObjectType: new () => T, url, payload: FormData, additionalHeaders: any = null,
                                                responseType: string = 'json'): Observable<T> {
    const multiPartContext = new HttpContext().set(IS_MULTI_PART_FORM_DATA, true);
    return this.http.put<T>(url, payload, {
      headers: additionalHeaders,
      responseType: responseType as 'json',
      context: multiPartContext
    }).pipe(map(r => {
      return DeserializeHelper.deserializeToInstance(respObjectType, r);
    }));
  }

  public postArr<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                           responseType: string = 'json'): Observable<T[]> {
    return this.http.post<T[]>(url, JSON.stringify(payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(map(r => {
      return r.map(rr => DeserializeHelper.deserializeToInstance(respObjectType, rr)) as T[];
    }));
  }

  public postMapArr<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                              responseType: string = 'json'): Observable<Map<string, T[]>> {
    return this.http.post<Map<string, T[]>>(url, JSON.stringify(payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(map(r => {
      return DeserializeHelper.deserializeTypedArrayMap(respObjectType, r);
    }));
  }

  public deleteStr(url, payload, additionalHeaders: any = null, responseType: string = 'text'): Observable<string> {
    return this.http.request<string>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    });
  }

  public deleteObj<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                             responseType: string = 'json'): Observable<T> {
    return this.http.request<T>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    }).pipe(map(r => {
      return DeserializeHelper.deserializeToInstance(respObjectType, r);
    }));
  }

  public deleteArr<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                             responseType: string = 'json'): Observable<T[]> {
    return this.http.request<T[]>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    }).pipe(map(r => {
      return r.map(rr => DeserializeHelper.deserializeToInstance(respObjectType, rr)) as T[];
    }));
  }

  public getStringArr(url: string,
                      additionalHeaders: any = null,
                      params: HttpParams = null): Observable<string[]> {
    return this.http.get<string[]>(url, {headers: additionalHeaders, params});
  }

}

