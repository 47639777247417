import {Injectable} from '@angular/core';
import {BaseViewModel} from 'src/app/models/base/base-view-model';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import {ProgramDomainModel} from '../../../../domainModels/program-domain-model';
import {ToastService} from '../../../../services/toast-service';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ContentQuery, ProgramQueryType} from '../../../../models/program/content-query';

@Injectable()
export class WatchViewModel extends BaseViewModel {

  programRowsContentQuery = new ContentQuery(ProgramQueryType.Default);

  isShow$ = this.activatedRoute.data.pipe(map(data => data?.isShow ?? false));
  programId$ = this.activatedRoute.params.pipe(map(params => params?.programId));

  leagueId$ = this.activatedRoute.queryParams.pipe(
    map(params => params?.leagueId ? +params.leagueId : null)
  );

  eventId$ = this.activatedRoute.queryParams.pipe(
    map(params => params?.eventId ? +params.eventId : null)
  );

  isLeague$ = this.leagueId$.pipe(
    map(leagueId => !!leagueId)
  );

  isEvent$ = this.eventId$.pipe(
    map(eventId => !!eventId)
  );

  constructor(
    private programDomainModel: ProgramDomainModel,
    private accountDomainModel: AccountDomainModel,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  setupBindings() {
  }
}
