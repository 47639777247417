<div [class.pre-roll-ad-playing]="viewModel.playingPreRollAd" class="ivs-video-player-container">
  <video
  id="content_video"
  class="video-js vjs-default-skin vjs-big-play-centered"
  controls
  preload="auto"
  width="800"
  height="500"
>
</video>
<div class="score-bug-overlay" *ngIf=" isStartedScoring$ | async">
    <app-score-bug
      [homeTeamName]="homeTeamName || 'Home'"
      [homeTeamScore$]="homeTeamScore$"
      [visitingTeamScore$]="visitingTeamScore$"
      [visitingTeamName]="visitingTeamName || 'Away'">
    </app-score-bug>
</div>
</div>
