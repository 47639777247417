import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy, Output, SecurityContext,
  SimpleChanges
} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {filter} from 'rxjs/operators';
import '../../../../utils/observable.extensions';
import '../../../../utils/subscription.extensions';
import {DomSanitizer} from '@angular/platform-browser';
import videojs from 'video.js';
import {Program} from '../../../../models/program/program';
import {Show} from '../../../../models/program/show';
import {Advertisement} from '../../../../models/resources/advertisement';
import {IvsVideoPlayerViewModel} from './ivs-video-player-view-model';
import { ProgramStatusType } from 'src/app/models/lookup/program-status-type';
declare module 'video.js' {
  export interface VideoJsPlayer {
      ima(options?: any): void;
  }
}
@Component({
  selector: 'app-ivs-video-player',
  templateUrl: './ivs-video-player.component.html',
  styleUrls: ['./ivs-video-player.component.scss'],
  providers: [IvsVideoPlayerViewModel]
})
export class IvsVideoPlayerComponent extends BaseComponent implements OnDestroy, OnChanges {
  @Input() program: Program|Show;
  @Input() advertisement: Advertisement;
  @Input() isLive$ = new BehaviorSubject<boolean>(null);
  @Input() playVideo: EventEmitter<boolean>;
  @Output() feedbackButtonClicked = new EventEmitter<void>();
  player: videojs.Player;

  constructor(
    private sanitizer: DomSanitizer,
    public viewModel: IvsVideoPlayerViewModel,
  ) {
    super();
  }

  ivsPlayer(): any {
    return (this.player as any).getIVSPlayer();
  }

  setupViews() {
    // Register IVS tech and quality plugins
    registerIVSTech(videojs);
    registerIVSQualityPlugin(videojs);

    // Initialize player
    this.player = videojs('video-js-target', {
      fluid: true,
      autoplay: true,
      muted: false,
      liveui: true,
      techOrder: ['AmazonIVS'],
      html5: {
        hls: {
          overrideNative: true
        }
      },
      controlBar: { durationDisplay: true, captionsButton: false, subtitlesButton: false, subsCapsButton: false }
    }, () => {
      (this.player as any).enableIVSQualityPlugin();

      this.player.one('loadedmetadata', () => {
        const qualities = (this.player as any).getIVSPlayer().getQualities();
        const quality720p = qualities.find(q => q.height === 720);
        if (quality720p) {
          (this.player as any).getIVSPlayer().setQuality(quality720p);
        }
      });
      const adTagUrl = `
        https://pubads.g.doubleclick.net/gampad/live/ads
        ?iu=/23199477693/video_test
        &description_url=https%3A%2F%2Fhometeamlive.com%2Fvideo-page
        &tfcd=0
        &npa=0
        &sz=1x1%7C400x300%7C640x480
        &min_ad_duration=5000
        &max_ad_duration=30000
        &gdfp_req=1
        &unviewed_position_start=1
        &output=vast
        &env=vp
        &impl=s
        &correlator=`;


      // Initialize the IMA plugin
      this.player.ima({
        adTagUrl: adTagUrl,
        debug: true
      });

      this.player.ready(() => {
        (this.player as any).ima.initializeAdDisplayContainer();
        (this.player as any).ima.requestAds();
      });

      this.viewModel.playerReady.next(true);
      this.addFeedBackButton();
    });
  }


  setupBindings() {
    combineLatest([
      this.viewModel.playerReady,
      this.viewModel.playerSrcUrl,
      this.viewModel.preRollAdSrcUrl,
      this.viewModel.preRollAdCompleted
      ]).pipe(filter(([ready, playerSrcUrl, preRollAdSrcUrl, _]) => !!ready && (!!playerSrcUrl || !!preRollAdSrcUrl)))
      .subscribe(([ready, playerSrcUrl, preRollAdSrcUrl, preRollAdCompleted]) => {
        if ((preRollAdCompleted || !preRollAdSrcUrl) && !!playerSrcUrl) {
          this.viewModel.playEventTracked = false;
          this.player.src(playerSrcUrl);
          this.viewModel.playingPreRollAd = false;
          setTimeout(() => {
            this.player.pause();
          });
        } else if (!preRollAdCompleted && !!preRollAdSrcUrl) {
          this.player.src(preRollAdSrcUrl);
          this.viewModel.playingPreRollAd = true;
          setTimeout(() => {
            this.player.muted(false);
            this.player.play().then();
          });
        }
      }).addTo(this.subscriptions);

    combineLatest([this.viewModel.playerReady, this.viewModel.playerPosterSrc])
      .pipe(filter(([ready, playerPosterSrc]) => !!ready))
      .subscribe(([ready, playerPosterSrc]) => {
        this.player.poster(playerPosterSrc);
      }).addTo(this.subscriptions);

    const PlayerEvents = (this.player as any).getIVSEvents();
    this.ivsPlayer().addEventListener(PlayerEvents.PlayerEventType.DURATION_CHANGED, () => {
      const duration = this.ivsPlayer().getDuration();
      this.isLive$.next(duration === Infinity);
    });

    this.ivsPlayer().addEventListener(PlayerEvents.PlayerState.PLAYING, () => {
      if (!this.viewModel.playEventTracked && !this.viewModel.playingPreRollAd) {
        if(this.program instanceof Program){
          const streamType= this.program.programStatusId===ProgramStatusType.InProgressId
          ? 'live' : 'past_program';
          this.viewModel.trackVideoEvent(this.program, 'play',streamType);
        }
        else{
          this.viewModel.trackVideoEvent(this.program, 'play','past_program');
        }


      }
    });

    this.ivsPlayer().addEventListener(PlayerEvents.PlayerState.ENDED, () => {
      if (!!this.viewModel.playingPreRollAd) {
        this.viewModel.preRollAdCompleted.next(true);
      }
    });

    this.playVideo?.subscribe(startFromBeginning => {
      if (startFromBeginning) {
        this.ivsPlayer()?.seekTo(0);
      }
      this.player?.play().then();
    }).addTo(this.subscriptions);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.player.dispose();
    this.viewModel.stopTracking();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.advertisement && this.advertisement?.amazonPlaybackStreamUrl) {
      this.viewModel.preRollAdSrcUrl.next(this.advertisement.amazonPlaybackStreamUrl);
      this.viewModel.advertisementId = this.advertisement.id;
    }

    if (changes.program) {
      if (!!this.program?.playbackStreamUrl) {
        this.viewModel.playerSrcUrl.next(this.program.playbackStreamUrl);
      }
      if (!!this.program?.imgSrc$) {
        this.program?.imgSrc$.subscribe(imgSrc => {
          const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, imgSrc);
          this.viewModel.playerPosterSrc.next(sanitizedUrl);
        }).addTo(this.subscriptions);
      }
    }
  }

  addFeedBackButton() {
    const feedbackButton = this.player.controlBar.addChild('button');
    const feedbackButtonDom = feedbackButton.el();
    feedbackButtonDom.innerHTML = '';
    feedbackButton.addClass('feedback-button');
    (feedbackButtonDom as any).title = $localize`Feedback`;
    (feedbackButtonDom as any).onclick = () => {
      if (this.player.isFullscreen()) {
        this.player.exitFullscreen();
      }
      this.feedbackButtonClicked.emit();
    };
  }
   findProgramOrShow(program: Program | Show): boolean {
    const obj=program as Program;
    if(obj.programStatusId){
      return true;
    }
    else{
      return false;
    }
  }
}


