import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {Observable, of, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {HydratedLeague} from '../models/resources/hydrated-league';
import {League} from '../models/resources/league';
import {TeamId} from '../models/resources/teamId';
import {VenueId} from '../models/resources/venue-id';
import {HttpParams} from '@angular/common/http';
import {BannerAdvertisement} from '../models/resources/banner-advertisement';
import {LeagueStream} from '../models/resources/league-stream';
import { contributor } from '../models/resources/contributor';
import { CreateContributorRequest } from '../models/account/requests/create-contributor-request';
import { CreateQrCodeRequest } from '../models/account/requests/create-qrCode-request';
import { qrCode } from '../models/resources/qrCode';

@Injectable({
  providedIn: 'root',
})
export class LeagueApi implements LoggableAPI {
  // Variables
  public serviceName = 'League';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }


  public getHydratedLeagues(activeOnly: boolean): Observable<HydratedLeague[]> {
    let params = new HttpParams();
    if (activeOnly) {
      params = params.set('active', true);
    }
    return this.apiClient.recursiveGetArr(HydratedLeague, Endpoints.getHydratedLeagues(), null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedLeagues', err));
        return throwError(err);
      })
    );
  }

  public getHydratedLeague(leagueId: number): Observable<HydratedLeague> {
    return this.apiClient.getObj(HydratedLeague, Endpoints.getHydratedLeague(leagueId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedLeague', err));
        return throwError(err);
      })
    );
  }

  public deleteLeagueImage(leagueId: number, imageId: string): Observable<string> {
    const url = Endpoints.deleteLeagueImage(leagueId, imageId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteLeagueImage', err));
        return throwError(err);
      })
    );
  }

  public createLeague(req: League): Observable<League> {
    return this.apiClient.postObj<League>(League, Endpoints.createLeague(), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createLeague', err));
        return throwError(err);
      })
    );
  }

  public getLeagues(activeOnly: boolean): Observable<League[]> {
    let params = new HttpParams();
    if (activeOnly) {
      params = params.set('active', true);
    }
    return this.apiClient.recursiveGetArr(League, Endpoints.getLeagues(), null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getLeagues', err));
        return throwError(err);
      })
    );
  }

  public updateLeague(req: League): Observable<League> {
    return this.apiClient.putObj<League>(League, Endpoints.updateLeague(req.id), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateLeague', err));
        return throwError(err);
      })
    );
  }

  addLeagueVenueAssociation(leagueId: number, venueId: number): Observable<VenueId> {
    const venueIdBody = new VenueId();
    venueIdBody.venueId = venueId;
    return this.apiClient.postObj<VenueId>(VenueId, Endpoints.createLeagueVenueAssociation(leagueId), venueIdBody).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'addLeagueVenueAssociation', err));
        return throwError(err);
      })
    );
  }

  deleteLeagueVenueAssociation(leagueId: number, venueAssociationId: number): Observable<string> {
    const url = Endpoints.deleteLeagueVenueAssociation(leagueId, venueAssociationId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteLeagueVenueAssociation', err));
        return throwError(err);
      })
    );
  }

  addLeagueTeamAssociation(leagueId: number, teamId: number): Observable<TeamId> {
    const teamIdBody = new TeamId();
    teamIdBody.teamId = teamId;
    return this.apiClient.postObj<TeamId>(TeamId, Endpoints.createLeagueTeamAssociation(leagueId), teamIdBody).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'addLeagueTeamAssociation', err));
        return throwError(err);
      })
    );
  }

  deleteLeagueTeamAssociation(leagueId: number, teamId: number): Observable<string> {
    const url = Endpoints.deleteLeagueTeamAssociation(leagueId, teamId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteLeagueTeamAssociation', err));
        return throwError(err);
      })
    );
  }

  public createLeagueBannerAdvertisement(leagueId: number, req: BannerAdvertisement): Observable<BannerAdvertisement> {
    return this.apiClient.postObj(BannerAdvertisement, Endpoints.createLeagueBannerAdvertisement(leagueId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createLeagueBannerAdvertisement', err));
        return throwError(err);
      })
    );
  }

  public deleteLeagueBannerAdvertisement(leagueId: number, bannerAdId: string): Observable<string> {
    const url = Endpoints.deleteLeagueBannerAdvertisement(leagueId, bannerAdId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteLeagueBannerAdvertisement', err));
        return throwError(err);
      })
    );
  }

  public getLeagueStream(leagueId: number){
    const url = Endpoints.getLeagueStream(leagueId);
    return this.apiClient.getObj(LeagueStream, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getLeagueStream', err));
        const fallbackStream = new LeagueStream();
        return of(fallbackStream);
      })
    );
  }

  public updateLeagueStream(req: LeagueStream): Observable<LeagueStream> {
    const url = Endpoints.updateLeagueStream(req.id);
    return this.apiClient.putObj<LeagueStream>(LeagueStream, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateLeagueStream', err));
        return throwError(err);
      })
    );
  }
  public getLeagueContributorsForLeague(leagueId: number): Observable<contributor[]>{
    const url=Endpoints.getLeagueContributors(leagueId);
    return this.apiClient.recursiveGetArr(contributor,url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getLeagueContributor', err));
        return throwError(err);
      })
    );
  }

  public createLeagueStream(stream: LeagueStream) {
    const url = Endpoints.createLeagueStream();
    return this.apiClient.postObj<LeagueStream>(LeagueStream, url, stream).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createLeagueStream', err));
        return throwError(err);
      })
    );
  }

  public getLeagueContributorsById(leagueId: number,contributerId: number): Observable<CreateContributorRequest>{
    const url=Endpoints.getLeagueContributorsById(leagueId,contributerId);
    return this.apiClient.getObj(CreateContributorRequest,url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getLeagueContributorsById', err));
        return throwError(err);
      })
    );
  }

  public createLeagueContributor(leagueId: number,req: CreateContributorRequest): Observable<contributor>{
    return this.apiClient.postObj<contributor>(contributor,Endpoints.saveLeagueContributor(leagueId),req).pipe(
      catchError(e=>{
        const err=new CustomError(e,this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName,'createLeagueContributor',err));
        return throwError(err);
      })
    );
  }

  public updateLeagueContributor(leagueId: number,req: CreateContributorRequest): Observable<contributor>{
    return this.apiClient.putObj<contributor>(contributor,Endpoints.updateLeagueContributor(leagueId,req.id),req).pipe(
      catchError(e=>{
        const err=new CustomError(e,this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName,'updateLeagueContributor',err));
        return throwError(err);
      })
    );
  }

  public createLeagueQrCode(leagueId: number, req: CreateQrCodeRequest): Observable<qrCode> {
    return this.apiClient.postObj<qrCode>(qrCode, Endpoints.createLeagueQrCode(leagueId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createLeagueQrCode', err));
        return throwError(err);
      })
    );
  }

  public getQrCodeById(qrCodeId: string): Observable<qrCode> {
    return this.apiClient.getObj<qrCode>(qrCode, Endpoints.getQrCodeById(qrCodeId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getQrCodeById', err));
        return throwError(err);
      })
    );
  }

  public getLeagueQrCodes(leagueId: number): Observable<qrCode[]> {
    return this.apiClient.recursiveGetArr(qrCode, Endpoints.getLeagueQrCodes(leagueId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getLeagueQrCodes', err));
        return throwError(err);
      })
    );
  }

}
